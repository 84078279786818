.bottom-nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 15rem;
  height: 1.64rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem .05rem 0.02rem rgba(0, 0, 0, 0.08);
  font-size: 0.4rem;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  z-index: 10;
}

.bottom-nav-item {
  &__icon {
    width: 0.76rem;
    height: 0.7rem;
  }

  &__title {
    text-decoration: none;
    color: #b8b8b8;

    &--active {
      color: #d71c86;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// .bottom-nav-video {
//   &__icon {
//     width: 1.48rem;
//     height: 1.24rem;
//   }
// }

.bottom-nav-live {
  &__icon {
    width: 1.48rem;
    height: 1.24rem;
  }
}