@import "var";

.inputFloor {
	position: absolute;
	bottom: 60px;
	left: 0;
	right: 0;
	box-sizing: border-box;
	padding-left: 20px;
	z-index: $info-z-index + 10;

	display: flex;
	align-items: center;

	> input {
		width: 560px;
		height: 66px;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 33px;

		box-sizing: border-box;
		padding: 0 20px;

		font-family: PingFang SC;
		font-weight: bold;
		font-size: 26px;
		color: #FFFFFF;
		line-height: 66px;

		&::placeholder {
			font-family: PingFang SC;
			font-weight: bold;
			font-size: 26px;
			color: #FFFFFF;
			line-height: 66px;
		}
	}

	@mixin iconWrapper() {
		width: 66px;
		height: 66px;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 50%;
		margin-left: 9px;
	}

	> .goodsIconWrapper {
		@include iconWrapper;

		&:hover {
			cursor: pointer;
		}

		> .goodsIcon {
			width: 46px;
			height: 36px;
			margin-top: 15px;
			margin-left: 14px;
		}
	}

	> .thumbIconWrapper {
		@include iconWrapper;

		&:hover {
			cursor: pointer;
		}

		> .thumbIcon {
			width: 45px;
			height: 45px;
			margin-left: 12px;
			margin-top: 8px;
		}
	}
}