.glx-merchant-shop-3 {
  min-height: 100vh;
  box-sizing: border-box;
  padding: {
    bottom: 0.4rem;
  }
  display: flex;
  flex-direction: column;

  @mixin tip {
    font-size: 0.4rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(187, 187, 187, 1);
    line-height: 0.72rem;
  }
  > .content {
    border-radius: 0.2rem;

    > .title-floor {
      height: 2.48rem;

      padding: {
        left: 0.4rem;
        top: 0.58rem;
      }

      background-color: rgba(255, 255, 255, 1);

      > .title {
        font-size: 0.64rem;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(56, 56, 56, 1);
        line-height: 1;
      }

      > .floor {
        display: flex;

        > .label.type {
          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(24, 23, 23, 1);
          line-height: 0.72rem;
        }

        > .shop-name {
          margin: {
            left: 1.44rem;
          }
          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(41, 150, 232, 1);
          line-height: 0.72rem;
        }
      }

      > .sub-title {
        margin: {
          top: 0.46rem;
        }
        font-size: 0.44rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(56, 56, 56, 1);
        line-height: 1;
      }
    }

    > .container {
      background: rgba(250, 251, 253, 1);

      > .row {
        display: flex;
        background-color: #fff;
        padding: {
          left: 15px;
        }

        > .shop-name-title {
          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(56, 56, 56, 1);
          line-height: 0.72rem;
        }

        > .label {
          font-size: 0.48rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(24, 23, 23, 1);
          line-height: 0.72rem;
          display: inline-block;
          flex-shrink: 0;
        }

        > .am-radio-wrapper {
          > .am-radio {
            padding: 2.5px;
            border: 1px solid #ccc;
            border-radius: 50%;
            margin-right: 5px;

            > .am-radio-inner {
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }

          &.license {
            &:not(:last-child) {
              margin: {
                right: 1.28rem;
              }
            }
          }
        }

        > .tip {
          @include tip();
        }
      }
    }
  }
  > .bottom-btn {
    margin: {
      top: auto;
    }
  }
}
