@import 'styles/common/flex.scss';

.orders-header {
  @include flex-vertical-center;
  background-color: white;
  box-shadow: 1px 2px 5px rgba(200, 200, 200, 0.65);
}

.orders-title {
  height: 2.5rem;
  @include flex-horizontal-center;
  &__back-icon {
    width: 0.38rem;
    height: 0.68rem;
    position: absolute;
    left: 0.48rem;
    top: 0.7rem;
  }
  &__title {
    font-size: 0.68rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(37, 37, 37, 1);
  }
}

.orders-tabs {
  @include flex-horizontal-between;
  width: calc(100% - 0.6rem);
  height: 1.4rem;
  box-sizing: border-box;
  padding: 0 0.6rem;
  font-size: 0.48rem;
  font-weight: bold;
  color: rgba(40, 40, 40, 1);
  & > a:hover {
    color: rgba(40, 40, 40, 1);
  }
  &__tab {
    min-width: 1.46rem;
    height: 100%;
    @include flex-vertical-start;
    span {
      margin-bottom: 0.58rem;
    }
    &--active {
      &::after {
        content: '';
        display: block;
        width: 1.4rem;
        height: 0.12rem;
        background: linear-gradient(
          90deg,
          white 0%,
          rgba(243, 105, 147, 1) 30%,
          rgba(229, 47, 85, 1) 31%,
          rgba(229, 47, 85, 1) 69%,
          rgba(243, 105, 147, 1) 70%,
          white 100%
        );
        border-radius: 0rem;
      }
    }
  }
}
