@import "var";

.avatarFloor {
	position: absolute;
	left: 0;
	right: 0;
	top: 90px;
	z-index: $info-z-index;

	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding-left: 20px;

	> .homeWrapper {
		width: 64px;
		height: 64px;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 50%;

		position: relative;

		flex-shrink: 0;
		
		&:hover{
			cursor: pointer;
		}

		> .icon {
			position: absolute;
			width: 41px;
			height: 41px;
			top: 11px;
			left: 11px;
		}
	}

	> .anchorWrapper {
		//width: 308px;
		height: 64px;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 32px;

		display: flex;
		align-items: center;
		margin-left: 16px;

		box-sizing: border-box;
		padding-right: 7px;

		> .avatar {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			display: block;
		}

		> .center {
			margin-left: 6px;

			box-sizing: border-box;
			padding-top: 9px;
			align-self: stretch;

			flex-grow: 1;

			> .floor1 {
				display: flex;
				align-items: center;

				> .icon {
					width: 20px;
					height: 20px;
				}

				> .title {
					font-family: PingFang SC;
					font-weight: bold;
					font-size: 24px;
					color: #FFFFFF;
					line-height: 1;

					margin-left: 4px;

				}
			}

			> .floor2 {
				display: flex;
				align-items: center;
				margin-top: 6px;

				> .icon {
					width: 17px;
					height: 18px;
				}

				> .value {
					font-family: PingFang SC;
					font-weight: bold;
					font-size: 16px;
					color: rgba(255, 255, 255, 0.6);
					line-height: 1;
					margin-left: 8px;
				}
			}
		}

		> .followWrapper {
			flex-shrink: 0;

			width: 72px;
			height: 50px;
			border-radius: 25px;
			margin-left: 19px;

			font-family: PingFang SC;
			font-weight: bold;
			font-size: 20px;
			line-height: 50px;

			text-align: center;

			&:hover {
				cursor: pointer;
			}

			&.unfollow {
				color: #FFFFFF;

				background-image: url("./img/关注按钮背景.png");
				background-size: 100% 100%;
				background-repeat: no-repeat;
			}

			&.follow {
				color: #FF5AAF;


				background-color: #FFFFFF;
			}
		}
	}

	> .memberAvatarList {
		margin-left: auto;
		padding-right: 20px;
		display: flex;

		> .list {
			display: flex;

			> .avatar {
				width: 50px;
				height: 50px;
				border-radius: 50%;

				&:not(:first-child) {
					margin-left: 3px;
				}
			}
		}

		> .count {
			width: 62px;
			height: 44px;
			background-color: rgba(0, 0, 0, 0.2);
			border-radius: 22px;

			line-height: 44px;
			text-align: center;

			font-family: PingFang SC;
			font-weight: bold;
			font-size: 20px;
			color: #FFFFFF;

			margin-left: 6px;
		}
	}

}