@import 'styles/common/flex.scss';

.simple-order-header {
  position: relative;
  height: 2.1rem;
  font-size: 0.68rem;
  font-weight: bold;
  color: rgba(37, 37, 37, 1);
  box-shadow: 1px 0 5px 1px rgba(240, 240, 240, 0.9);
  background: rgba(255, 255, 255, 1);

  &__back {
    width: 0.38rem;
    height: 0.68rem;
    position: absolute;
    left: 0.48rem;
    top: 0.7rem;
    z-index: 10;
  }
  @include flex-horizontal-center;
}
