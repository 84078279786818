.glx-refund-apply {


  .btn-floor {
    margin: {
      top: 2.28rem;
    }
    text-align: center;
    .submit-btn{
      width: 6.96rem;
      height: 1.72rem;
      background: linear-gradient(90deg, rgba(245, 4, 4, 1), rgba(248, 94, 53, 1));
      box-shadow: 0 .28rem .84rem .14rem rgba(235, 9, 8, 0.18);
      border-radius: .86rem;

      font-size: .56rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
    }
  }
}