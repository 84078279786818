.glx-live__video-list {
  > .title-container {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding: {
      left: .30rem * 2;
    }

    > .title-img {
      display: block;
      height: .64rem * 2;
    }

    > .more-wrapper {
      display: flex;
      align-items: center;
      margin-left: auto;
      padding: {
        right: .30rem * 2;
        top: .10rem * 2;
      }

      > .text {
        font-size: .20rem * 2;
        font-family: PingFang SC;
        font-weight: bold;
        color: #EFF0F6;
      }

      > .more-icon {
        display: block;
        width: .10rem * 2;
        height: .15rem * 2;
        margin-left: .13rem * 2;
      }
    }
  }

  > .swiper-container {
    margin-top: .17rem * 2;
    box-sizing: border-box;
    padding: {
      left: .30rem * 2;
    }
    height: 1.96rem * 2;

    > .swiper-wrapper {
      > .swiper-slide {
        height: 100%;
        width: 3.20rem * 2;
        transition-duration: 0.5s;
        border-radius: .12rem * 2;

        &:not(:first-child) {
          margin-left: .24rem * 2;
        }

        > .video-wrapper {
          width: 100%;
          height: 100%;
          position: relative;

          > .poster {
            border-radius: .12rem * 2;
            width: 100%;
            height: 100%;
            display: block;
          }

          > .play-icon {
            position: absolute;
            width: .32rem * 2;
            height: .42rem * 2;
            display: block;
            left: 50%;
            top: .71rem * 2;
            transform: translateX(-50%);
          }

          > .title-container {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: {
              left: .14rem * 2;
              bottom: .08rem * 2;
            }

            > .title {
              font-size: .22rem * 2;
              font-family: PingFang SC;
              font-weight: bold;
              color: #F4FBF9;
            }
          }
        }
      }
    }
  }
}