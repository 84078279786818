.orders {
  background-color: #f9f9f9;
  .orders-header {
    margin-bottom: 0.48rem;
  }
  .orders-placeholder {
    margin-bottom: 0.78rem;
  }
  .orders-item {
    margin-top: 0.4rem;
  }
}
