.videos {
  background-color: #141720;
  min-height: 100vh;
  padding: {
    bottom: 1rem;
  }
  > .spin-block {
    display: flex;
    justify-content: center;
  }
}
