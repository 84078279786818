.glx-live-room__gift-animate {
  height: 100%;
  display: flex;

  > .gift-animate-container {
    flex-shrink: 0;
    height: 100%;
    display: inline-flex;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.3);
    border-radius: .39rem * 2;

    box-sizing: border-box;
    padding: {
      left: .06rem * 2;
      right: .06rem * 2;
    }
    opacity: 0;

    > .avatar-wrapper {
      width: .68rem * 2;
      height: .68rem * 2;

      > img.avatar {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
      }
    }

    > .content-wrapper {

      margin: {
        left: .16rem * 2;
      }

      > .name {
        font-size: .26rem * 2;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 1;
      }

      > .gift-name-wrapper {
        margin: {
          top: .09rem * 2;
        }
        display: flex;
        align-items: center;

        @mixin text {
          font-size: .20rem * 2;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 1;
        }

        > .label {
          @include text;
        }

        > .gift-name {
          @include text;
          margin: {
            left: .10rem * 2;
          }
        }

      }
    }

    > .gift-icon {
      max-height: .53rem * 2;
      margin: {
        left: auto;
      }
      display: block;
    }
  }

  > .count-container {
    align-self: flex-end;
    margin: {
      left: .10rem * 2;
      bottom: .05rem * 2;
    }
    display: flex;

    @mixin text($font-size) {
      font-size: $font-size;
      font-family: FZChaoCuHei-M10S;
      font-weight: bold;
      font-style: italic;
      color: #FFFFFF;
      line-height: .63rem * 2;
      text-shadow: 0 .01rem * 2 0 rgba(0, 0, 0, 0.2);
    }

    > .text-x {
      @include text(.38rem * 2)
    }

    > .count {
      @include text(.64rem * 2);
      margin: {
        left: .12rem * 2;
      }
    }
  }
}