.glx-merchant-step {
  height: 2.76rem;
  background: linear-gradient(
    60deg,
    rgba(185, 35, 222, 1),
    rgba(237, 23, 69, 1)
  );
  box-shadow: 0 0 0.96rem 0.2rem rgba(55, 3, 21, 0.18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  > .floor-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    > .item {
      width: calc(100% / 4);

      font-size: 0.4rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);
      line-height: 0.72rem;
      text-align: center;

      &.active {
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  > .floor-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: {
      top: 0.2rem;
    }
    > .item {
      width: calc(100% / 4);
      display: flex;
      justify-content: center;

      @mixin dot {
        width: 0.14rem;
        height: 0.14rem;
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
      }

      > .dot {
        @include dot();
      }

      &.active {
        > .dot-wrapper {
          width: 0.3rem;
          height: 0.3rem;
          border: 0.04rem solid rgba(255, 255, 255, 0.8);
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          > .dot {
            @include dot();
          }
        }
      }
    }
  }
}
