.glx-live-room__goods-list-panel {
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 1);
  border-radius: .14rem * 2 .14rem * 2 0 0;

  > .title-container {
    height: .86rem * 2;
    padding: {
      left: .24rem * 2;
    }
    display: flex;
    align-items: center;

    > .title {
      font-size: .26rem * 2;
      font-family: PingFang SC;
      font-weight: 800;
      color: #171520;
      line-height: .32rem * 2;
    }
  }

  > .goods-list-container {
    overflow-y: scroll;

    > .goods {
      height: 2.20rem * 2;
      box-sizing: border-box;
      padding: {
        left: .32rem * 2;
        right: .35rem * 2;
      }
      display: flex;
      align-items: center;

      &.active {
        background-color: rgba(254, 240, 237, 1);
      }

      > .img-container {
        width: 1.60rem * 2;
        height: 1.60rem * 2;
        border-radius: .08rem * 2;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;

        > .number {
          position: absolute;
          top: 0;
          left: 0;
          width: .34rem * 2;
          height: .30rem * 2;
          line-height: .30rem * 2;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: .08rem * 2 0 .08rem * 2 0;

          font-size: .22rem * 2;
          font-family: Arial;
          font-weight: bold;
          color: #FFFFFF;
          text-align: center;
          transform: scale(0.8);
        }

        > .img {
          position: absolute;
          max-height: 100%;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }

        > .showing {
          position: absolute;
          width: 100%;
          height: .34rem * 2;
          left: 0;
          bottom: 0;

          background-color: rgba(255, 0, 44, 0.5);
          border-radius: 0 0 .08rem * 2 .08rem * 2;

          display: flex;
          align-items: center;

          > .icon {
            margin: {
              left: .3rem * 2;
            }
            display: block;
            width: .16rem * 2;
            height: .16rem * 2;
          }

          > .text {
            margin: {
              left: .08rem * 2;
            }
            font-size: .22rem * 2;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: .32rem * 2;
            transform: scale(0.8);
          }
        }
      }

      > .info-container {
        margin: {
          left: .27rem * 2;
        }
        align-self: stretch;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        padding: {
          top: .38rem * 2;
          bottom: .23rem * 2;
        }
        box-sizing: border-box;

        > .name {
          flex-shrink: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 2; //显示的行

          font-size: .26rem * 2;
          font-family: PingFang SC;
          font-weight: 800;
          color: #171520;
          line-height: .32rem * 2;
        }

        > .price-container {
          display: flex;

          > .price {
            font-size: .22rem * 2;
            font-family: PingFang SC;
            font-weight: bold;
            color: #E71631;
            line-height: .32rem * 2;
          }

          > .to-buy {
            margin: {
              left: auto;
              right: .14rem * 2;
            }

            width: 1.58rem * 2;
            height: .54rem * 2;
            line-height: .54rem * 2;
            //background: linear-gradient(45deg, #FF1324, #FF350D);
            //box-shadow: 0 .11rem * 2 .35rem * 2 .03rem * 2 rgba(254, 0, 27, 0.2);
            border-radius: .27rem * 2;

            font-size: .24rem * 2;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            position: relative;
            z-index: 0;

            &::before {
              content: '';
              display: block;
              position: absolute;
              background-image: url("./img/抢购按钮-背景.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;

              top: -0.4rem;
              bottom: -0.9rem;
              left: -0.7rem;
              right: -0.7rem;
              z-index: -1;
            }
          }
        }
      }
    }
  }
}