@import 'styles/common/flex.scss';

$padding-width: 0.6rem;
.order-detail {
  width: 100%;
  height: 11.92rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 0rem 0rem rgba(187, 195, 203, 0.1);
  border-radius: 0rem;
  margin-top: 0.48rem;
  padding-left: $padding-width;
  padding-top: 0.86rem;

  &__key {
    font-size: 0.48rem;
    color: rgba(13, 13, 13, 1);
  }

  &__value {
    font-size: 0.5rem;
    font-weight: bold;
    color: rgba(13, 13, 13, 1);
  }

  &__copy {
    width: 1.24rem;
    height: 0.62rem;
    background: rgba(243, 243, 243, 1);
    border-radius: 0.2rem;

    font-size: 0.4rem;
    font-weight: bold;
    color: rgba(13, 13, 13, 1);
  }

  &__full-line {
    width: calc(100% - #{$padding-width * 2});

    font-size: 0.48rem;

    font-weight: bold;
    color: rgba(13, 13, 13, 1);
    @include flex-horizontal-between;
  }

  &__pay {
    width: calc(100% - #{$padding-width * 2});
    @include flex-horizontal-end;

    font-size: 0.48rem;
    font-weight: bold;
    color: rgba(20, 20, 20, 1);

    .glx-price {
      font-size: 0.4rem;
      font-weight: bold;
      color: rgba(247, 41, 27, 1);

      &__int {
        font-size: 0.64rem;
      }
    }
  }
}

.order-detail-divider {
  width: 11.98rem;
  height: 1px;
  background: rgba(246, 246, 246, 1);
  margin-top: 0.82rem;
  margin-bottom: 0.82rem;
  margin-left: 3.02rem;
}

.order-pay-select {
  > .option {
    &:not(:first-child) {
      margin-top: .4rem;
    }
  }
}