.index {
	margin-top: 31px;

	> .container {
		> .titleFloor {
			display: flex;

			box-sizing: border-box;
			padding-left: 32px;
			padding-right: 33px;

			> .titleWrapper {
				box-sizing: border-box;
				padding-top: 7px;

				> .title {
					font-family: PingFang SC;
					font-weight: bold;
					font-size: 32px;
					color: #343434;
					line-height: 1;
				}
			}

			> .timeContainer {
				display: flex;
				margin-left: 22px;
				display: flex;

				> .item {
					width: 48px;
					height: 48px;

					position: relative;
					z-index: 0;

					background-size: 100% 100%;
					background-repeat: no-repeat;

					font-family: PingFang SC;
					font-weight: 800;
					font-size: 28px;
					color: #FFFFFF;
					line-height: 48px;
					text-align: center;

					&:nth-child(1), &:nth-child(2) {
						background-image: url("../../images/home/限时抢购背景.png");
					}

					&:nth-child(3) {
						background-image: url("../../images/home/限时抢购背景特殊.png");
					}

					&:not(:first-child) {
						margin-left: 19px;

						&::before {
							content: '';
							display: block;
							position: absolute;
							z-index: 10;

							width: 6px;
							height: 18px;

							left: -7px;
							top: 15px;

							transform: translateX(-100%);

							background-image: url("../../images/home/秒杀冒号.png");
							background-size: 100% 100%;
							background-repeat: no-repeat;
						}
					}
				}
			}

			> .moreWrapper {
				margin-left: auto;
				display: flex;

				> .text {
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 20px;
					color: #989898;
					line-height: 1;
				}

				> .iconWrapper {
					margin-left: 14px;

					> img {
						width: 10px;
						height: 20px;
					}
				}
			}
		}

		> .goodsListContainer {
			margin-top: 26px;
			padding-left: 34px;
			padding-right: 32px;

			> .goodsList {
				display: flex;
				justify-content: space-between;

				> .goods {
					> .imgWrapper {
						box-sizing: border-box;
						padding: 0 12px;

						> img {
							width: 154px;
							height: 154px;
							border-radius: 12px;
						}
					}

					> .bottom {
						margin-top: 13px;

						> .name {
							max-width: 178px;
							font-family: PingFang SC;
							font-weight: bold;
							font-size: 22px;
							color: #707070;
							line-height: 30px;

							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box; //作为弹性伸缩盒子模型显示。
							-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
							-webkit-line-clamp: 2; //显示的行

							> span {
								height: 22px;
								padding: 0 5px;

								font-family: PingFang SC;
								font-weight: 500;
								font-size: 18px;
								color: #FFFFFF;
								line-height: 22px;

								margin-inline-end: 5px;

								&.pdd, &.jd {
									background: rgba(33, 114, 235, 1);
								}

								&.self {
									background: rgba(230, 87, 118, 1);
								}
							}
						}
					}
				}
			}
		}
	}
}