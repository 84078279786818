@import "styles/common/flex.scss";

.category-type {

  &,
  &--active {
    @include flex-horizontal-center;
    width: 3.74rem;
    height: 2.42rem;
  }


  &--active {
    font-size: 0.48rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(20, 20, 20, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 1rem 0rem 0rem 1rem;
    box-shadow: -0.2rem 0rem .2rem .1rem rgba(48, 17, 12, 0.09);



    span {
      background-image: linear-gradient(0deg, rgba(215, 37, 108, 1) 0%, rgba(240, 63, 16, 1) 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0 .38rem;
      border-left: 0.1rem solid #DF4343;
      border-right: 0.1rem solid transparent;
    }
  }
}