.glx-message {
  display: flex;
  align-items: flex-start;

  &.from {
    justify-content: flex-end;
  }
  &.to {
    justify-content: flex-start;
  }

  &:not(:first-child) {
    margin: {
      top: 0.54rem;
    }
  }

  > .avatar {
    width: 1.24rem;
    height: 1.24rem;
    border-radius: 50%;
    display: block;
  }

  > .text-block {
    padding: 0.4rem;

    font-size: 0.44rem;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 0.56rem;
    &.from {
      background: rgba(255, 255, 255, 1);
      border: 0.02rem solid rgba(0, 45, 61, 1);
      color: rgba(3, 40, 52, 1);
      margin: {
        right: 0.2rem;
      }
    }
    &.to {
      background: rgba(85, 159, 186, 0.8);
      border: 0.02rem solid rgba(0, 45, 61, 1);
      color: rgba(254, 254, 254, 1);
      margin: {
        left: 0.2rem;
      }
    }
  }
}
