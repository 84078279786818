.product-card {
  width: 6.92rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 0.14rem 0rem rgba(0, 0, 0, 0.06);
  border-radius: 0.16rem;
  &:nth-child(n + 3) {
    margin-top: 0.28rem;
  }

  > .img-block {
    height: 6.92rem;
    border-radius: 0.16rem 0.16rem 0rem 0rem;
    display: flex;
    justify-content: center;
    overflow: hidden;
    > .img {
      display: block;
      height: 100%;
    }
  }

  > .info-block {
    padding-top: 0.28rem;
    padding-bottom: 0.22rem;
    padding-left: 0.22rem;
    padding-right: 0.48rem;

    > .name {
      font-size: 0.48rem;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(28, 28, 28, 1);
      line-height: 0.6rem;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    > .price-floor {
      margin-top: 0.28rem;
      display: flex;
      align-items: center;
      > .current-price {
        font-size: 0.4rem;
        font-family: PingFangSC;
        font-weight: 500;
        color: rgba(214, 23, 45, 1);
        line-height: 0.4rem;
      }

      > .self {
        margin-left: 0.26rem;
        background: rgba(223, 67, 67, 1);
        border-radius: 0.08rem;
        padding: 0.08rem 0.2rem;

        font-size: 0.4rem;
        font-family: PingFangSC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 0.4rem;
      }

      > .hot {
        margin-left: 0.2rem;
        background: rgba(240, 93, 59, 1);
        border-radius: 0.08rem;
        padding: 0.08rem 0.2rem;

        font-size: 0.4rem;
        font-family: PingFangSC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 0.4rem;
      }
    }
  }
}
