@import 'styles/common/flex.scss';
$padding-right: 1rem;
.order-operate {
  width: calc(100% - #{$padding-right});
  height: 2.12rem;
  margin-top: 0.2rem;
  padding-right: $padding-right;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.04);
  @include flex-horizontal-end;

  .order-operate-button {
    margin-left: 0.48rem;
  }
}

.order-operate-button {
  @include flex-horizontal-center;
  width: 3.24rem;
  height: 1.12rem;
  border: 1px solid rgba(212, 212, 212, 1);
  border-radius: 1rem;

  font-size: 0.48rem;
  font-weight: bold;
  color: rgba(20, 20, 20, 1);
  &--important {
    background: linear-gradient(
      45deg,
      rgba(245, 4, 4, 1),
      rgba(248, 94, 53, 1)
    );
    box-shadow: 0rem 0rem 1rem 0rem rgba(235, 9, 8, 0.18);
    border: 0px;

    color: white;
  }
}
