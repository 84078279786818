@import 'styles/common/flex.scss';

.order-goods {
  margin-top: 0.4rem;
  display: flex;
  @include flex-vertical-start;

  .order-contact {
    margin-top: 0.2rem;
    height: 2.02rem;
    width: 100%;
    background-color: white;
  }
}

.order-goods-title {
  height: 2.04rem;
  width: 100%;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  box-sizing: border-box;
  font-size: 0.56rem;
  font-weight: bold;
  color: rgba(40, 40, 40, 1);
  background-color: white;
  @include flex-horizontal-start;

  &__icon {
    width: 0.52rem;
    height: 0.5rem;
    margin-right: 0.32rem;
  }

  &__name {
    font-size: 0.56rem;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
    margin-right: 0.38rem;
  }

  &__enter {
    width: 0.14rem;
    height: 0.28rem;
  }

  > .self {
    margin-left: auto;
  }


}

.order-goods-body {
  height: 3.76rem;
  width: 100%;
  background-color: white;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  box-sizing: border-box;
  @include flex-horizontal-start;

  &__img {
    width: 3rem;
    height: 3rem;
    border-radius: 0rem;
    display: block;
  }

  &__info {
    @include flex-vertical-start-align-start;

    line-height: 0.9rem;
    padding-left: 0.5rem;
    width: 0;
    flex-grow: 1;
  }

  &__name {
    max-width: 100%;

    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行

    font-size: .48rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #1C1C1C;
    line-height: 1;

  }

  &__desc {
    margin-top: .38rem;
    font-size: .4rem;
    line-height: 1;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #898888;
  }

  &__price {
    margin-top: .38rem;

    .glx-price {
      font-size: .36rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #242424;
      line-height: 1;

      &__int {
        font-size: 0.6rem;
      }
    }
  }
}
