@import 'styles/common/flex.scss';

.glx-shops {
  background-image: url('../images/shops/bg.png');
  background-repeat: no-repeat;
  background-size: 100%;

  .shops-header {
    @include flex-vertical-center;

    padding-top: 0.5rem;
    margin-bottom: 0.4rem;

    &__back {
      position: fixed;
      width: 0.3rem;
      height: 0.5rem;
      left: 0.42rem;
      top: 1.02rem;
    }

    &__icon {
      width: 3.68rem;
      height: 1.56rem;
    }

    &__banner {
      margin-top: 0.5rem;
      width: 14.2rem;
      height: 4.52rem;
      border-radius: 0.2rem;
      box-shadow: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
    }
  }

  .shops-content-wrapper {
    .shops-content {
      overflow: hidden;
      margin-top: 0.4rem;
    }
    .shops-loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
