@import "styles/common/flex.scss";

.detail {
  &__img {
    position: fixed;
    width: 100%;
    z-index: -1;
  }
}

.detail-nav {
  position: fixed;
  z-index: 2;

  img {
    width: 1.2rem;
    height: 1.2rem;
    background: rgba(0, 0, 0, 1);
    opacity: 0.3;
    border-radius: 50%;
    position: fixed;
  }

  &__back {
    left: 0.4rem;
    top: 0.76rem;
  }

  &__cart {
    right: 0.4rem;
    top: 0.76rem;
  }

  &__cart-count {
    @include flex-center;
    width: 0.52rem;
    height: 0.52rem;
    background: rgba(233, 25, 51, 1);
    border-radius: 50%;
    padding: 2px;

    font-size: 0.44rem;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);

    position: fixed;
    right: 0.44rem;
    top: 0.62rem;
  }
}

.detail-card {
  width: 100%;
  min-height: 20rem;
  background: rgba(249, 249, 249, 1);
  box-shadow: 0rem 0rem 1rem 0rem #15151524;
  border-radius: 0.5rem 0.5rem 0rem 0rem;

  position: absolute;
  top: 11.88rem;
  z-index: 10;

  &__wrapper {
    padding-left: 0.78rem;
    padding-right: 0.78rem;
    position: relative;

    > .collect {
      position: absolute;
      top: 0;
      right: 0.8rem;
      transform: translateY(-50%);

      width: 1.36rem;
      height: 1.36rem;
      background: rgba(245, 119, 175, 1);
      box-shadow: 0 0 0.68rem 0.08rem rgba(134, 20, 71, 0.14);
      border-radius: 50%;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__control {
    width: 100%;
    height: 1.14rem;
    @include flex-center();
  }

  &__control-icon {
    height: 0.1rem;
    width: 0.84rem;
    background-color: #d1d1d1;
  }

  &__title {
    font-size: 0.72rem;
    font-family: PingFangSC;
    font-weight: bolder;
    color: rgba(24, 24, 24, 1);
    line-height: 0.84rem;
  }

  &__tags {
    font-size: 0.48rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(186, 186, 186, 1);
    margin-top: 0.38rem;
    margin-bottom: 0.62rem;
  }

  &__price {
    width: 3.28rem;
    height: 0.64rem;
    font-size: 0.84rem;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(239, 29, 59, 1);
    line-height: 0.84rem;
  }

  &__origin-price {
    font-size: 0.44rem;
    font-family: PingFangSC;
    font-weight: 400;
    text-decoration: line-through;
    color: rgba(170, 170, 170, 1);
    line-height: 0.84rem;
  }
}

.detail-choose {
  &__title {
    font-size: 0.44rem;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(169, 169, 169, 1);
    line-height: 0.84rem;

    margin-top: 0.6rem;
    margin-bottom: 0.38rem;
  }

  &__content {
    @include flex-horizontal-start;
  }
}

.detail-description {
  margin-top: 0.4rem;

  > .description-block {
    width: 100%;
    background: rgba(17, 24, 38, 1);
    overflow: hidden;

    * {
      color: #fff !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center !important;
      margin-top: 0.4rem !important;
      margin-bottom: 0.4rem !important;
    }

    img {
      width: 100% !important;
    }
  }

  > .img-block {
    > .info-img {
      width: 100%;
      /* height: 600px; */
      background-color: #fff;
    }
  }
}

.detail-choose__content {
  display: flex;
  padding-left: 0.1rem;

  > .ope {
    font-size: 0.4rem;
    height: 0.8rem;
    transform: scale(2);
    display: block;

    &.minus {
      color: #cacaca;
    }
  }

  > .res {
    width: 1.48rem;
    height: 0.64rem;
    background: rgba(237, 237, 237, 1);
    border-radius: 0.4rem;

    padding: 0.14rem 0.66rem;
    margin: 0.3rem;
    font-size: 0.44rem;

    @include flex-center();
  }
}

.detail-shop {
  width: 13.28rem;
  height: 3.24rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0.2rem 0.1rem rgba(21, 21, 21, 0.06);
  border-radius: 0.4rem;

  padding: 0.4rem 0.46rem;
  margin: auto;
  margin-top: 1.24rem;
  @include flex-horizontal-between;
  @include flex-align-start;

  &__info {
    @include flex-horizontal-start;
    @include flex-align-start;
  }

  &__avatar {
    width: 2.4rem;
    height: 2.4rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 0.05rem 0.02rem rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    margin-right: 0.58rem;
  }

  &__title {
    font-size: 0.52rem;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(40, 40, 40, 1);
    line-height: 0.64rem;
  }

  &__follow {
    width: 1.9rem;
    height: 0.9rem;
    background: rgba(170, 88, 228, 1);
    border-radius: 0.5rem;

    @include flex-horizontal-center;

    font-size: 0.44rem;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
}

.detail-ope {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 2.14rem;
  background: rgba(255, 255, 255, 1);

  font-size: 0.56rem;
  color: #ffffff;

  span {
    @include flex-horizontal-center;
  }

  @include flex-horizontal-evenly;

  &__cart {
    width: 6.3rem;
    height: 1.56rem;
    background: rgba(238, 56, 103, 1);
    border-radius: 1rem;
  }

  &__buy {
    width: 6.3rem;
    height: 1.56rem;
    background: rgba(245, 113, 86, 1);
    border-radius: 1rem;
  }
}

.detail-image-viewer {
  width: 100vw;
  height: 100vh;
  background: rgba(47, 43, 46, 1);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  pointer-events: all;

  // @include flex;
  .swiper-slide {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    @include flex-align-center;
  }

  &__img {
    width: 100%;
    max-width: 100vw;
  }
}

.detail-image {
  width: 100%;
}

.detail-image-swiper {
  width: 100vw;
  height: 11.88rem;
  z-index: -1;
  overflow: hidden;

  .swiper-slide {
    // width: calc(100% - 0.9rem);
    text-align: center;
    font-size: 18px;

    img {
      width: 100vw;
    }
  }
}

.specification-block {
  .specification-floor {
    display: flex;
    align-items: center;

    > .label {
      width: 80px;
      text-align: justify;
      text-align-last: justify;

      font-size: 0.44rem;
      font-family: PingFangSC;
      font-weight: 500;
      color: darkgray;
      line-height: 0.84rem;
      margin-top: 0.6rem;
      margin-bottom: 0.38rem;
      margin-right: .4rem;
    }

    > .specification-value-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      padding: 0 20px;

      border: 1px solid rgba(161, 161, 161, 0.6);
      border-radius: 4px;

      &:not(:first-child) {
        margin-left: 0.3rem;
      }

      &.active {
        background: rgba(224, 54, 98, 0.6);
        border-radius: 4px;
      }

      &__pdd {
        max-width: 16rem;
      }

      &.active {
      }
    }
  }
}
