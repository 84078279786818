@import 'styles/common/flex.scss';


.category {}

.category-body {
  @include flex-horizontal-start;
  @include flex-align-start;
}

.category-header {

  @include flex-horizontal-center();

  width: 15rem;
  height: 2.08rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.07);

  .search-input {
    background-color: #E1E1E1;
    color: #C5C5C5;

    input {
      font-size: 0.48rem;
      font-family: PingFangSC;
      font-weight: 400;

      background-color: #E1E1E1;

      &::placeholder {
        color: rgba(197, 197, 197, 1);
      }
    }
  }

  &__all {
    font-size: 0.46rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: #383838;
    line-height: 0.4rem;

    margin-left: .44rem;

  }
}

.category-sidebar {
  width: 3.74rem;
  height: 22.96rem;
  background: rgba(239, 239, 239, 1);


  font-size: 0.48rem;
  font-family: PingFangSC;
  font-weight: 500;
  color: rgba(135, 135, 135, 1);


}

.category-content {
  width: 10.86rem;
  padding-top: .38rem;
  padding-left: .4rem;


  &__banner {
    display: block;
    width: 10.46rem;
    height: 3.52rem;
    border-radius: 0.2rem;
    background-color: #C5C5C5;
  }

  &__sections {
    height: 19.06rem;
    overflow-y: auto;
  }

  .category-section {
    margin-top: .48rem;
  }
}