.glx-merchant-complete {
  min-height: 100vh;
  box-sizing: border-box;
  padding: {
    bottom: 0.4rem;
  }
  display: flex;
  flex-direction: column;

  > .content {
    box-sizing: border-box;
    box-sizing: border-box;
    padding: {
      top: 1.92rem;
    }
    @mixin img-block {
      width: 7.02rem;
      height: 2.84rem;
      margin: {
        left: auto;
        right: auto;
      }
      > .img {
        width: 100%;
        height: 100%;
      }
    }
    @mixin title-block($type) {
      margin: {
        top: 1.2rem;
      }
      > .title {
        text-align: center;
        font-size: 0.96rem;
        font-family: PingFang SC;
        font-weight: bold;
        @if $type == failed {
          color: rgba(36, 36, 36, 1);
        } @else {
          color: rgba(11, 200, 149, 1);
        }
        line-height: 1;
      }
      > .sub-title {
        text-align: center;
        margin: {
          top: 0.3rem;
        }

        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: 500;
        @if $type == failed {
          color: rgba(71, 71, 71, 1);
        } @else {
          color: rgba(11, 200, 149, 1);
        }
        line-height: 1;
      }
    }
    @mixin tip-floor {
      text-align: center;
      margin: {
        top: 0.7rem;
      }
      font-size: 0.6rems;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(129, 127, 127, 1);
      line-height: 0.72rem;
    }

    &.success {
      > .img-block {
        @include img-block();
      }
      > .title-block {
        @include title-block($type: success);
      }
      > .tip-floor {
        @include tip-floor();
      }

      > .card {
        margin: {
          top: 0.6rem;
          left: 0.8rem;
          right: 0.8rem;
        }
        > .floor {
          height: 1.96rem;
          line-height: 1.96rem;
          display: flex;

          position: relative;
          &:not(:first-child) {
            ::before {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0.36rem;
              right: 0.46rem;
              border-top: 0.04rem dashed rgba(230, 230, 230, 1);
            }
          }

          > .label {
            padding: {
              left: 0.4rem;
            }
            margin: {
              right: 1.08rem;
            }

            font-size: 0.44rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(138, 138, 138, 1);
          }

          > .value {
            padding: {
              right: 0.4rem;
            }

            font-size: 0.48rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: rgba(21, 165, 239, 1);

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    &.failed {
      > .img-block {
        @include img-block();
      }

      > .title-block {
        @include title-block($type: failed);
      }

      > .tip-floor {
        @include tip-floor();

        .retry {
          color: blue;
          text-decoration: underline;
        }
      }
    }
  }

  > .bottom-btn {
    margin: {
      top: auto;
    }
  }
}
