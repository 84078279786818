.glx-live-room__current-goods {
  width: 100%;
  height: 100%;
  border-radius: .12rem * 2;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -10;
    top: -0.4rem;
    bottom: -0.2rem;
    left: -0.3rem;
    right: -0.2rem;
    background-image: url("./img/背景图.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  > .title-container {
    $height: .37rem * 2;
    height: $height;
    box-sizing: border-box;
    flex-shrink: 0;

    > .title {
      text-align: center;
      //line-height: $height;
      font-size: .20rem * 2;
      font-family: PingFang SC;
      font-weight: 500;
      color: #DD4D31;
      transform: scale(0.8);
    }
  }

  > .img-container {
    height: 1.82rem * 2;
    flex-shrink: 0;

    > .img {
      width: 1.82rem * 2;
      height: 100%;
      border-radius: .08rem * 2;
      display: block;
      margin: {
        left: auto;
        right: auto;
      }
    }
  }

  > .info-container {
    display: flex;
    align-items: center;
    flex-grow: 1;

    > .price {
      margin: {
        left: .11rem * 2;
      }
      font-size: .18rem * 2;
      font-family: PingFang SC;
      font-weight: 800;
      color: #F90E19;
      line-height: 1;
    }

    > .buy {
      margin: {
        left: auto;
      }
      width: .82rem * 2;
      $height: .36rem * 2;
      height: $height;
      //background: linear-gradient(90deg, #FF800F, #F91424);
      //box-shadow: 0 .02rem * 2 .16rem * 2 0 rgba(250, 41, 32, 0.33);
      border-radius: .18rem * 2 0 0 .18rem * 2;
      box-sizing: border-box;
      padding: {
        left: .14rem * 2;
      }

      font-size: .24rem * 2;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: $height;
      position: relative;
      z-index: 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        background-image: url("./img/购买按钮-背景.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        top: -0.16rem;
        bottom: -0.28rem;
        left: -0.28rem;
        right: -0.28rem;
      }
    }
  }
}