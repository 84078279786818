@import "var";

.curGoodPanel {
  position: absolute;
  z-index: $info-z-index;

  width: 182px;
  height: 284px;
  background: #FFFFFF;
  border-radius: 16px;

  bottom: 142px;
  right: 20px;

  box-sizing: border-box;
  padding-top: 3px;
  padding-left: 3px;

  display: flex;
  flex-direction: column;

  > .imgWrapper {
    width: 176px;
    height: 176px;
    position: relative;

    flex-shrink: 0;

    > .img {
      width: 100%;
      height: 100%;
      border-radius: 14px;
    }

    > .speaking {
      position: absolute;
      top: 2px;
      left: 2px;

      height: 26px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 13px;

      padding-left: 17px;
      padding-right: 6px;
      padding-top: 5px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 7px;
        top: 10px;

        width: 6px;
        height: 6px;
        background: #FFFFFF;
        border-radius: 50%;
      }

      > .text {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 1;
      }
    }

    > .closeWrapper {
      width: 26px;
      height: 26px;
      //background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;

      position: absolute;
      top: 2px;
      right: 2px;

      &:hover {
        cursor: pointer;
      }

      > .closeIcon {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  > .bottom {
    flex-grow: 1;
    align-self: stretch;

    box-sizing: border-box;
    padding-top: 9px;

    > .name {
      width: 148px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 20px;
      color: #191919;
      line-height: 1;

      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
    }

    > .lowestPriceText {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #F65482;
      line-height: 1;

      margin-top: 6px;
      margin-left: 4px;
    }

    > .btnToPrice {
      height: 42px;
      background: linear-gradient(270deg, #F7498A, #EF3054);
      border-radius: 21px;

      position: absolute;
      bottom: 3px;
      left: 3px;
      right: 3px;

      display: flex;
      align-items: center;

      &.disable {
        background: gray;
      }

      &:hover {
        cursor: pointer;
      }

      > .price {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 22px;
        color: #FFFFFF;
        line-height: 1;

        box-sizing: border-box;
        padding-left: 10px;
      }

      > .icon {
        display: block;
        width: 60px;
        height: 42px;
        margin-left: auto;
      }

      > .undercarriageIcon {
        display: block;
        width: 40px;
        height: 40px;
        margin-left: 20px;
      }
    }
  }

}