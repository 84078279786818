.index {
	display: flex;

	box-sizing: border-box;
	padding-left: 32px;

	> .iconLocation {
		width: 33px;
		height: 40px;
		align-self: flex-end;
		padding-bottom: 11px;

		> img {
			width: 100%;
			height: 100%;
		}
	}

	> .cityWrapper {

		margin-left: 9px;

		align-self: flex-end;

		box-sizing: border-box;
		padding-bottom: 11px;

		> .city {
			font-family: PingFang SC;
			font-weight: bold;
			font-size: 20px;
			color: #3D3D3D;
			line-height: 1;
		}

	}

	> .inputWrapper {
		width: 446px;
		height: 62px;
		background: #FFFFFF;
		border-radius: 31px;

		margin-left: 23px;

		display: flex;

		box-sizing: border-box;
		padding-left: 26px;

		> .iconWrapper {
			width: 28px;
			height: 28px;
			align-self: center;
			flex-shrink: 0;

			> img {
				width: 100%;
				height: 100%;
			}
		}

		> .input {
			box-sizing: border-box;
			padding-left: 13px;
			padding-right: 13px;

			font-family: PingFang SC;
			font-weight: 500;
			font-size: 26px;
			color: #787878;

			flex-grow: 1;

			&::placeholder {
				font-family: PingFang SC;
				font-weight: 500;
				font-size: 26px;
				color: #CACACA;
			}
		}
	}

	> .cartWrapper {
		width: 42px;
		height: 41px;
		margin-left: 24px;

		> img {
			width: 100%;
			height: 100%;
		}
	}

	> .personalWrapper {
		width: 36px;
		height: 41px;
		margin-left: 27px;

		> img {
			width: 100%;
			height: 100%;
		}
	}
}