@import "styles/common/flex.scss";

.shop-preview {
  width: 13.22rem;
  height: 6.42rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0.2rem 0.1rem rgba(21, 21, 21, 0.06);
  border-radius: 0.4rem;
  padding: 0.4rem 0.46rem;
  margin: auto;
  &:not(:first-child) {
    margin-top: 0.4rem;
  }

  .divider {
    width: 13.4rem;
    height: 0.02rem;
    border-top: 1px solid rgba(238, 238, 238, 1);
  }
}

.shop-preview-header {
  height: 1.52rem;
  @include flex-horizontal-between;
  @include flex-align-start;

  &__info {
    @include flex-horizontal-start;
    @include flex-align-start;

    > .img-wrapper {
      width: 2.62rem;
      height: 1.08rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0 0.05rem 0.02rem rgba(0, 0, 0, 0.1);
      border-radius: 0.2rem;
      margin-right: 0.58rem;
      display: flex;
      justify-content: center;
      overflow: hidden;
      > .img {
        height: 100%;
      }
    }
  }

  &__text {
    @include flex-vertical-start-align-start;
  }

  &__title {
    font-size: 0.48rem;

    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(40, 40, 40, 1);
    line-height: 0.64rem;
  }

  &__follower {
    font-size: 0.4rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(148, 148, 148, 1);
  }

  &__opt {
    width: 1.9rem;
    height: 0.9rem;
    background: #e11948;
    border-radius: 0.5rem;

    @include flex-horizontal-center;

    font-size: 0.44rem;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
  }
}

.shop-preview-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.22rem;
  padding-right: 0.14rem;

  > .no-src {
    width: 100%;
  }

  > .goods {
    width: 4rem;
    height: 4rem;
    border-radius: 0.28rem;
    position: relative;

    > .img-block {
      height: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      > .img {
        height: 100%;
      }
    }

    > .price-block {
      position: absolute;
      right: 0;
      bottom: 0;

      width: 2.22rem;
      height: 0.66rem;
      line-height: 0.66rem;
      text-align: center;

      background: rgba(0, 0, 0, 0.7);
      border-radius: 0.28rem 0 0.28rem 0;

      font-size: 0.44rem;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
  }
  //   &__goods {
  //     overflow: hidden;
  //     border-radius: 0.2rem;
  //     width: 4rem;
  //     height: 4rem;
  //     position: relative;
  //   }

  //   &__goods-image {
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //   }

  //   &__goods-price {
  //     min-width: 2.22rem;
  //     height: 0.66rem;
  //     background: rgba(0, 0, 0, 1);
  //     opacity: 0.7;
  //     border-radius: 0.2rem 0rem 0rem 0rem;

  //     color: white;
  //     font-size: 0.44rem;
  //     @include flex-horizontal-center;

  //     position: absolute;
  //     bottom: 0;
  //     right: 0;
  //   }
}
