.glx-my-page {
  position: relative;
  height: 100vh;

  .mescroll {
    position: fixed;
    top: 0;
    bottom: 0;
    height: auto; /*如设置bottom:50px,则需height:auto才能生效*/
  }

  .page-container {
    height: 100%;
    box-sizing: border-box;
    padding-top: 1.26rem * 2;
    background-color: #fbf8fb;
  }

  .my-info {
    margin-bottom: 0.1rem;
  }

  .my-entries {
    position: relative;
    z-index: 1;
    margin: auto;
  }

  .my-recent-deal {
    margin-top: 0.98rem;
  }

  .my-recommend {
    margin-top: 0.96rem;
  }
}

@mixin my-card-mixin {
  background-color: white;
  border-radius: 0.3rem;
  margin: auto;
}
