.track {
  padding-top: 3.08rem;
  box-sizing: border-box;
  > .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
  }
  > .head {
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
    height: 3.08rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0.02rem 0.16rem 0rem rgba(21, 21, 21, 0.08);

    > .title-floor {
      display: flex;
      justify-content: center;
      position: relative;
      padding-top: 0.56rem;
      > .text {
        font-size: 0.64rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(49, 49, 49, 1);
      }
      > .back {
        position: absolute;
        left: 0;
        top: 0;
        padding: {
          top: 0.64rem;
          left: 0.42rem;
          right: 0.42rem;
        }
        > .img {
          display: block;
          width: 0.28rem;
          height: 0.5rem;
        }
      }
    }

    > .condition-floor {
      margin-top: 1.04rem;

      padding-left: 0.7rem;
    }
  }

  .map-wrapper {
    padding: 0.4rem;
    box-shadow: 0 0.02rem 0.16rem 0.02rem rgba(0, 0, 0, 0.04);
    border-radius: 0.28rem 0 0 0.28rem;
    background: rgba(249, 249, 249, 1);
    > .day-item {
      &:not(:first-child) {
        margin-top: 0.4rem;
      }
      > .title {
        font-size: 0.64rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(49, 49, 49, 1);
        line-height: 0.4rem;
      }

      > .container {
        margin-top: 0.4rem;

        > .item {
          height: 4.04rem;
          padding: {
            top: 0.4rem;
            left: 0.4rem;
          }
          display: flex;
          background: rgba(255, 255, 255, 1);

          position: relative;
          &:not(:last-child) {
            &::before {
              content: '';
              display: block;
              position: absolute;
              left: 0.4rem;
              right: 0;
              bottom: 0;
              height: 1px;
              border-top: 1px solid rgba(243, 243, 243, 1);
            }
          }

          > .img-block {
            width: 3.24rem;
            height: 3.24rem;
            border-radius: 0.2rem;
            display: flex;
            justify-content: center;
            overflow: hidden;
            flex-shrink: 0;
            > .img {
              height: 100%;
            }
          }

          > .info-block {
            flex-grow: 1;
            margin: {
              left: 0.4rem;
            }

            > .name-floor {
              font-size: 0.48rem;
              font-family: PingFang SC;
              font-weight: bold;
              color: rgba(24, 24, 24, 1);
              line-height: 0.64rem;
              padding: {
                right: 0.72rem;
              }

              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }

            > .price-floor {
              margin: {
                top: 0.46rem;
              }
              font-size: 0.44rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: rgba(239, 29, 59, 1);
              line-height: 0.64rem;
            }

            > .dot-floor {
              display: flex;
              align-items: center;
              padding: {
                right: 0.4rem;
              }
              > .dots-wrapper {
                position: relative;
                padding: {
                  //   top: 0.2rem;
                }
                margin: {
                  left: auto;
                }
                &.active {
                  z-index: 1000;
                }
                > .dots {
                }

                > .handle {
                  position: absolute;
                  left: 0;
                  transform: translateX(-100%) translateY(calc(-50% + -0.2rem));

                  width: 13.28rem;
                  height: 3.28rem;
                  z-index: 100;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  box-sizing: border-box;
                  padding: {
                    left: 1rem;
                    right: 2.4rem;
                  }

                  > .bg-img {
                    position: absolute;
                    z-index: -10;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                  }

                  > .item {
                    text-align: center;
                    > .icon {
                      display: block;
                      margin: {
                        left: auto;
                        right: auto;
                      }
                    }
                    > .text {
                      margin-top: 0.26rem;

                      font-size: 0.44rem;
                      font-family: PingFang SC;
                      font-weight: 500;
                      color: rgba(161, 161, 161, 1);
                      line-height: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
