.glx-merchant-shop2-add-category {
  @mixin mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
  }

  > .title-floor {
    display: flex;
    align-items: center;

    > .title {
      padding: {
        left: 0.8rem;
      }

      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(24, 23, 23, 1);
      line-height: 0.72rem;
    }

    > .add-btn {
      margin: {
        left: 2.4rem;
      }
      width: 3.06rem;
      height: 1rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0.18rem 0.38rem 0.04rem rgba(18, 76, 155, 0.13);
      border-radius: 0.5rem;

      line-height: 1rem;

      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(25, 104, 234, 1);
      line-height: 0.72rem;
    }
  }

  > .table-header {
    display: flex;
    margin: {
      top: 0.66rem;
      left: 0.4rem;
      right: 0.4rem;
    }

    height: 1.08rem;
    line-height: 1.08rem;
    background: linear-gradient(
                    90deg,
                    rgba(44, 99, 229, 1),
                    rgba(39, 181, 234, 1)
    );
    border-radius: 0.12rem 0.12rem 0 0;

    > .item {
      width: calc(100% / 4);
      text-align: center;

      font-size: 0.4rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);

      &:not(:last-child) {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 0.4rem;
          bottom: 0.4rem;

          width: 0.02rem;
          background: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  > .table-body {
    margin: {
      left: 0.4rem;
      right: 0.4rem;
    }

    > .floor {
      display: flex;
      height: 1.44rem;
      line-height: 1.44rem;
      position: relative;

      &:not(:first-child) {
        &::before {
          content: '';
          display: block;
          position: absolute;

          top: 0;
          left: 0.14rem;
          right: 0.1rem;
          height: 0.02rem;
          border-top: 0.02rem solid rgba(238, 238, 238, 1);
        }
      }

      > .item {
        text-align: center;
        width: calc(100% / 4);

        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(24, 23, 23, 1);

        &:nth-child(2n + 1) {
          background: rgba(245, 248, 251, 1);
        }

        &:nth-child(2n) {
          background: rgba(255, 255, 255, 1);
        }

        &.delete {
          font-size: 0.4rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(237, 53, 96, 1);

          > .text {
            cursor: pointer;
          }
        }
      }
    }
  }

  > .panel-mask {
    @include mask();

    > .panel {
      position: absolute;
      left: 0.8rem;
      right: 0.8rem;
      margin-left: auto;
      margin-right: auto;
      top: 10vh;
      bottom: 10vh;
    }
  }
}
