.glx-cart {
  $head-height: 1.68rem;
  padding: {
    top: $head-height;
  }
  min-height: 100vh;
  background: rgba(249, 249, 249, 1);
  > .head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $head-height;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0.02rem 0.12rem 0 rgba(0, 0, 0, 0.07);
    display: flex;
    align-items: center;
    justify-content: center;

    > .title-block {
      width: 1.74rem;
      height: 0.58rem;
      > .img {
        display: block;
        height: 100%;
        transform: scale(1.2);
      }
    }

    > .handle-block {
      position: absolute;
      right: 0;
      height: 100%;
      padding: {
        right: 0.4rem;
        left: 0.4rem;
      }
      display: flex;
      align-items: center;

      > .text {
        display: block;

        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(24, 24, 24, 1);
        line-height: 0.04rem;
      }
    }
  }

  > .cart-container {
    margin-top: 0.48rem;
    padding: {
      left: 0.4rem;
      right: 0.4rem;
    }

    > .item {
      display: flex;
      align-items: center;
      padding: {
        top: 0.4rem;
        bottom: 0.4rem;
      }

      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0.12rem 0.7rem 0.06rem rgba(0, 0, 0, 0.04);
      border-radius: 0.28rem;

      > .dot {
        width: 0.48rem;
        height: 0.48rem;
        border: 0.02rem solid rgba(191, 191, 191, 1);
        border-radius: 50%;
        margin: {
          left: 0.44rem;
        }
        &.hidden {
          visibility: hidden;
        }

        &.active {
          border: 0.04rem solid rgba(236, 87, 148, 1);
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;

            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            width: 0.28rem;
            height: 0.28rem;
            background: rgba(236, 87, 148, 1);
            border-radius: 50%;
          }
        }
      }

      > .img-block {
        margin: {
          left: 0.44rem;
        }
        width: 3.24rem;
        height: 2.34rem;
        border-radius: 0.2rem;
        display: flex;
        justify-content: center;
        overflow: hidden;
        > .img {
          height: 100%;
          display: block;
        }
      }

      > .info-block {
        margin: {
          left: 0.84rem;
        }

        > .name-floor {
          font-size: 0.48rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(24, 24, 24, 1);
          line-height: 0.64rem;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        > .specification-floor {
          background: rgba(233, 233, 233, 1);
          border-radius: 0.08rem;
          margin: {
            top: 0.34rem;
          }
          padding: {
            top: 0.14rem;
            bottom: 0.14rem;
            left: 0.2rem;
            right: 0.2rem;
          }
          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(166, 166, 166, 1);
          line-height: 0.64rem;
        }

        > .price-floor {
          margin: {
            top: 0.52rem;
          }
          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(239, 29, 59, 1);
          line-height: 0.64rem;

          > .current-price {
            font-size: 0.44rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(239, 29, 59, 1);
            line-height: 0.64rem;
          }

          > .num-block {
            margin: {
              left: auto;
              right: 0.4rem;
            }

            display: block;
            @mixin handle {
              font-size: 0.46rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: rgba(68, 68, 68, 1);
              line-height: 0.64rem;
            }
            .subtract,
            .add {
              @include handle();
              &.disable {
                color: rgba(202, 202, 202, 1);
              }
            }
            .input-num {
              margin: {
                left: 0.26rem;
                right: 0.26rem;
              }
              width: 1.26rem;
              height: 0.56rem;
              background: rgba(237, 237, 237, 1);
              border-radius: 0.28rem;
              text-align: center;

              font-size: 0.44rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: rgba(68, 68, 68, 1);
              line-height: 0.64rem;
            }
          }
        }
      }

      &:not(:first-child) {
        margin-top: 0.48rem;
      }
    }
  }

  > .handle-block {
    position: fixed;
    bottom: 2rem;
    left: 0.4rem;
    right: 0.4rem;

    height: 1.76rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0.02rem 0.12rem 0p rgba(0, 0, 0, 0.07);
    border-radius: 0.88rem;

    display: flex;
    align-items: center;

    > .select-block {
      display: flex;
      align-items: center;
      margin: {
        left: 0.52rem;
      }
      > .dot {
        width: 0.48rem;
        height: 0.48rem;
        border: 0.02rem solid rgba(191, 191, 191, 1);
        border-radius: 50%;
        margin: {
          left: 0.44rem;
        }
        &.hidden {
          visibility: hidden;
        }

        &.active {
          border: 0.04rem solid rgba(236, 87, 148, 1);
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;

            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            width: 0.28rem;
            height: 0.28rem;
            background: rgba(236, 87, 148, 1);
            border-radius: 50%;
          }
        }
      }

      > .text {
        margin: {
          left: 0.22rem;
        }
        font-size: 0.44rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(147, 147, 147, 1);
        line-height: 0.64rem;
      }
    }

    //   not-edit
    > .info-block {
      margin: {
        left: 0.76rem;
      }
      > .top {
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(166, 166, 166, 1);
        line-height: 1;
      }
      > .bottom {
        margin: {
          top: 0.28rem;
        }
        font-size: 0.44rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(39, 38, 38, 1);
        line-height: 1;
        .price {
          color: rgba(239, 29, 59, 1);
        }
      }
    }

    > .pay-btn {
      display: block;
      margin: {
        right: 0.14rem;
        left: auto;
      }
      width: 3.68rem;
      height: 1.56rem;
      background: rgba(238, 56, 103, 1);
      border-radius: 7.8rem;
      line-height: 1.56rem;

      font-size: 0.52rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
    //   edit
    > .move-btn {
      margin: {
        left: auto;
      }
      width: 2.72rem;
      height: 1.02rem;
      background: rgba(245, 130, 159, 1);
      border-radius: 5.2rem;

      font-size: 0.44rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 1.02rem;
    }
    > .delete-btn {
      margin: {
        left: auto;
        right: 0.36rem;
      }
      width: 2.38rem;
      height: 1.02rem;
      background: rgba(238, 238, 238, 1);
      border-radius: 0.52rem;

      font-size: 0.44rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(164, 164, 164, 1);
      line-height: 0.64rem;

      &.delete-btn-active{
        background-color: red;
        color:white;
      }
    }
  }
}
