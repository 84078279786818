@import 'styles/common/flex.scss';

.search-input {
  @include flex-horizontal-start;
  width: 11.68rem;
  height: 0.48rem;
  background: rgba(225, 225, 225, 1);
  border-radius: 1rem;

  padding: 0.4rem;

  &__icon {
    width: 0.48rem;
    height: 0.48rem;
    margin-right: 0.3rem;
  }

  input {
    font-size: 0.48rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(36, 36, 36, 1);
    border: none;
    background: rgba(225, 225, 225, 1);
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  &__clear {
    width: 0.56rem;
    height: 0.56rem;
  }
}
