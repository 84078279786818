.order-deliver {
  width: 15rem;
  min-height: 3.96rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 1rem 0rem rgba(245, 4, 4, 0.08);
  border-radius: 0.3rem;
  overflow: hidden;
  position: relative;

  > .add-address {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 3.2rem;
    height: 1.12rem;
    background: linear-gradient(45deg, rgba(245, 4, 4, 1), rgba(248, 94, 53, 1));
    box-shadow: 0 .28rem .84rem .14rem rgba(235, 9, 8, 0.18);
    border-radius: .56rem;


    font-size: .48rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 .02rem .1rem rgba(168, 12, 11, 0.18);
  }
}

.order-deliver-info {
  padding-left: 1.35rem;
  padding-top: 1.02rem;
  background: rgba(255, 255, 255, 1);

  &__text {
    font-size: 0.44rem;

    font-weight: bold;
    color: rgba(24, 24, 24, 1);
    line-height: 0.6rem;
  }

  &__time {
    font-size: 0.4rem;
    font-weight: bold;
    color: rgba(126, 126, 126, 1);
    line-height: 0.6rem;
  }
}

.order-deliver-address {
  // padding-left: 1.35rem;
  padding: 1rem 1.35rem;
  background: rgba(255, 255, 255, 1);

  &__name {
    font-size: 0.48rem;
    font-weight: bold;
    color: rgba(24, 24, 24, 1);
  }

  &__phone {
    font-size: 0.44rem;
    font-weight: bold;
    color: rgba(24, 24, 24, 1);
  }

  &__address {
    font-size: 0.44rem;
    font-weight: bold;
    color: rgba(126, 126, 126, 1);
  }
}
