.glx-live-page {
  height: 100vh;
  background-image: url(./img/bg.png);
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  > .live-icon {
    display: block;
    position: absolute;
    top: .40rem * 2;
    left: .15rem * 2;
    height: .81rem * 2;
  }

  > .close-icon-wrapper {
    position: absolute;
    top: .25rem * 2;
    right: 0;
    padding: {
      top: .25rem * 2;
      bottom: .25rem * 2;
      left: .24rem * 2;
      right: .24rem * 2;
    }

    > .close-icon {
      display: block;
      width: .56rem * 2;
      height: .56rem * 2;
    }
  }

  > .live-list-container {
    margin: {
      top: auto;
    }
  }

  > .video-list-container {
    margin: {
      top: .52rem * 2;
      bottom: .30rem * 2;
    }
  }

  > .live-panel-container {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}