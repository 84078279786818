$live-z-index: 500;

.index {
	min-height: 100vh;
	background: rgba(248, 248, 248, 1);

	> .container {
		box-sizing: border-box;
		//padding-top: 113px;

		.floorList {
			margin-top: 48px;

			.floor {
				&:not(:first-child) {
					margin-top: 31px;
				}
			}
		}


		.liveContainer {
			// todo 切换是否是开发的时候，开发完成后需要设为 false
			$is-test: false;

			top: 103px;
			z-index: $live-z-index;
			overflow: hidden;

			@if ($is-test) {
				position: absolute;
				left: 500px;
			} @else {
				position: fixed;
				top: 0;
				left: 0;
				//right: 24px;

				// 调整大小和位置
				//transform: scale(0.4);
				//top: -385px;
				//right: -200px;
			}

		}
	}
}