.glx-my__my-header {
  position: absolute;
  width: 100%;
  height: 2.51rem * 2;
  top: 0;
  left: 0;
  z-index: 0;

  > .bg {
    position: absolute;
    display: block;
    z-index: -10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  > .img-text {
    display: block;
    position: absolute;
    height: .80rem * 2;
    top: .20rem * 2;
    left: 45%;
    transform: translateX(-50%);
  }

  @mixin icon-shadow{
    position: absolute;
    z-index: -10;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1.5);
  }

  .setting-wrapper {
    position: absolute;
    top: .54rem * 2;
    left: .28rem *2;
    width: .36rem * 2;
    height: .37rem * 2;
    z-index: 0;

    > .icon-setting {
      display: block;
      width: 100%;
      height: 100%;
    }

    > .icon-shadow {
      @include icon-shadow;
    }
  }

  > .customer-service-wrapper {
    position: absolute;
    width: .37rem * 2;
    height: .35rem * 2;
    top: .56rem * 2;
    right: .31rem * 2;

    > .icon-customer-service {
      display: block;
      width: 100%;
      height: 100%;
    }

    > .icon-shadow {
      @include icon-shadow;
    }
  }
}