.condition {
  padding-left: 1.08rem;
  padding-right: 1.08rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .item {
    padding-bottom: 0.3rem;
    box-sizing: border-box;

    position: relative;
    &.active {
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.08rem;
        background: linear-gradient(
          -90deg,
          rgba(241, 61, 31, 1),
          rgba(237, 23, 69, 1)
        );
        border-radius: 0.04rem;
      }
    }
    > .text {
      position: relative;

      font-size: 0.44rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(31, 31, 31, 1);
      line-height: 0.4rem;

      > .img-wrapper {
        position: absolute;
        right: -0.1rem;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateX(100%);

        display: flex;
        flex-direction: column;
        justify-content: center;

        > .arrow {
          width: 0.24rem;
          height: 0.1rem;

          &.arrow-down {
            margin-top: 0.06rem;
          }
        }

        > .select {
          width: 0.32rem;
          height: 0.32rem;
        }
      }

      &.select {
        display: flex;
        align-items: center;
        > .num {
          margin-left: 0.1rem;

          width: 0.4rem;
          height: 0.4rem;
          background: rgba(244, 23, 65, 1);
          border-radius: 50%;

          line-height: 0.4rem;
          text-align: center;
          font-size: 0.36rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          line-height: 0.4rem;
        }
      }
    }
  }
}
