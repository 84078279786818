.my-recent-deal {
  &__title {
    // width: 2.16rem;
    padding-left: 0.38rem;
    height: 0.54rem;
    font-size: 0.56rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(19, 19, 19, 1);
    line-height: 0.4rem;
  }

  &__content {
    & > span {
      padding-left: 0.38rem;
    }
    height: 4.24rem;
    overflow-y: hidden;
  }
}
