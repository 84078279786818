.glx-help {
  position: relative;
  z-index: 10;
  min-height: 100vh;
  box-sizing: border-box;
  padding: {
    top: 1rem;
  }
  background: rgba(249, 249, 249, 1);
  > .top-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;

    display: block;
    width: 100%;
    height: 7.32rem;
  }

  > .service-img {
    position: absolute;
    z-index: 1;
    display: block;

    width: 2.96rem;
    height: 3.22rem;
    top: 0.44rem;
    right: 1.14rem;
  }

  > .top {
    > .floor1 {
      display: flex;
      margin: {
        left: 0.76rem;
      }

      > .text {
        font-size: 0.6rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 1;
        text-shadow: 0.02rem 0.02rem 0rem rgba(97, 103, 213, 0.32);
      }
    }

    > .floor2 {
      margin: {
        top: 0.36rem;
        left: 0.76rem;
      }
      > .text {
        font-size: 0.6rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 1;
        text-shadow: 0.02rem 0.02rem 0 rgba(97, 103, 213, 0.32);
      }
    }

    > .floor3 {
      margin: {
        top: 0.86rem;
      }
      display: flex;
      justify-content: center;

      > .input-wrapper {
        display: block;

        width: 13.6rem;
        height: 1.6rem;
        background: rgba(255, 255, 255, 1);
        border-radius: 0.8rem;

        position: relative;
        > .search-icon {
          position: absolute;
          display: block;

          width: 0.62rem;
          height: 0.62rem;
          left: 0.56rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        > .input {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: {
            left: 1.74rem;
            right: 0.56rem;
          }

          font-size: 0.5rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(67, 67, 67, 1);
          line-height: 0.4rem;
        }
      }
    }
  }

  > .content {
    margin: {
      top: 0.88rem;
    }
    background: rgba(255, 255, 255, 1);
    border-radius: 0.28rem 0.28rem 0rem 0rem;

    > .item {
      &:not(:last-child) {
        > .container {
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;

            bottom: 0;
            left: 0.7rem;
            right: 0.7rem;

            height: 0.1rem;
            border-top: 0.02rem solid rgba(239, 244, 246, 1);
          }
        }
      }
      > .container {
        padding: {
          left: 0.7rem;
          right: 0.7rem;
        }
        min-height: 2.04rem;
        display: flex;
        align-items: center;

        > .name {
          font-size: 0.56rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(20, 20, 20, 1);
          line-height: 0.4rem;
          &.active {
            color: rgba(39, 100, 238, 1);
          }
        }

        > .arrow {
          margin: {
            left: auto;
          }
          display: block;
          &.close {
            width: 0.2rem;
            height: 0.38rem;
          }
          &.open {
            width: 0.4rem;
            height: 0.22rem;
          }
        }
      }

      @mixin child-item {
        height: 1.4rem;
        padding: {
          left: 1.26rem;
        }
        box-sizing: border-box;
        display: flex;
        align-items: center;

        font-size: 0.5rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(67, 67, 67, 1);
        line-height: 0.4rem;
      }

      > .child-item {
        @include child-item();
      }

      > .child-container {
        box-sizing: border-box;
        padding: {
          top: 0.3rem;
          bottom: 0.88rem;
        }
        > .item {
          @include child-item();
        }
      }
    }
  }
}
