.glx-service {
  width: 12.92rem;
  margin: {
    left: auto;
    right: auto;
  }

  > .title-floor {
    display: flex;
    justify-content: center;
    position: relative;

    > .title {
      width: 7.56rem;
      height: 0.9rem;
      z-index: 10;
      position: relative;

      > .bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -10;
        pointer-events: none;

        background: {
          image: url(./img/title.png);
          size: 100% 100%;
        }
      }
    }

    > .close-btn {
      width: 1.86rem;
      height: 0.76rem;
      position: absolute;
      right: 0;
      bottom: 0;

      background: {
        image: url(./img/close/bg.png);
        size: 100% 100%;
      }

      display: flex;
      align-items: center;
      justify-content: center;

      > .icon-img {
        width: 0.28rem;
        height: 0.28rem;
        display: block;
      }

      > .text {
        display: block;
        margin-left: 0.22rem;

        font-size: 0.44rem;
        font-family: SimHei;
        font-weight: 400;
        color: rgba(91, 176, 207, 1);
      }
    }
  }

  > .container {
    height: 16.14rem;
    position: relative;
    margin: {
      top: 0.2rem;
    }
    padding: 0.72rem;
    box-sizing: border-box;

    z-index: 10;

    > .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: scale(1.07);
      z-index: -10;
      pointer-events: none;
      background: {
        image: url(./img/bg.png);
        size: 100% 100%;
      }
    }

    > .content {
      height: 100%;
      background: rgba(1, 41, 52, 0.9);
      border: 1px solid rgba(0, 84, 114, 0.9);

      padding: 0.72rem 0;
      box-sizing: border-box;

      > .wrapper {
        padding: 0 0.72rem;
        height: 100%;

        box-sizing: border-box;
        overflow: hidden;
        overflow-y: scroll;
      }
    }

    > .input-floor {
      position: absolute;

      left: 0;
      right: 0;
      bottom: -1.02rem;

      display: flex;
      justify-content: center;

      > .input-wrapper {
        width: 11.74rem;
        height: 1.52rem;
        position: relative;

        > .input-form {
          width: 100%;
          height: 100%;
          > .input {
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 1);
            box-sizing: border-box;
            padding: {
              left: 0.6rem;
              right: 0.6rem;
            }

            font-size: 0.44rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(149, 150, 150, 1);
            line-height: 0.36rem;
          }
        }

        @mixin decorate {
          position: absolute;
          width: 0.26rem;
          height: 1.64rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        > .left-decorate {
          @include decorate();
          left: 0;
          transform: translateX(-50%);
        }
        > .right-decorate {
          @include decorate();
          right: 0;
          transform: translateX(50%);
        }
      }
    }
  }
}
