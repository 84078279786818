.glx-merchant-need-know {
  background: rgba(249, 249, 249, 1);

  display: flex;
  flex-direction: column;
  height: 100vh;
  
  box-sizing: border-box;
  padding: {
    bottom: 0.4rem;
  }

  > .step {
    flex-shrink: 0;
  }

  > .content {
    box-sizing: border-box;
    padding: {
      left: 0.4rem;
      right: 0.4rem;
    }
    background-color: rgba(255, 255, 255, 1);
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    > .title-floor {
      height: 1.86rem;

      font-size: 0.64rem;
      font-family: PingFang SC;
      font-weight: 800;
      color: rgba(56, 56, 56, 1);
      line-height: 1.86rem;

      flex-shrink: 0;
    }

    > .content-floor {
      background: rgba(247, 244, 242, 1);
      box-shadow: 0 0 0.5rem 0.04rem rgba(0, 0, 0, 0.09);
      border-radius: 0.2rem;
      padding: {
        top: 0.8rem;
        bottom: 0.8rem;
      }
      box-sizing: border-box;
      flex-grow: 1;
      overflow: hidden;

      > .container {
        padding: {
          left: 0.4rem;
          right: 0.4rem;
        }
        height: 100%;
        box-sizing: border-box;
        overflow-y: scroll;

        /*控制整个滚动条*/
        ::-webkit-scrollbar {
          background-color: transparent;
          width: 0.06rem;
          background-clip: padding-box;

          background: linear-gradient(
            0deg,
            rgba(13, 117, 223, 1),
            rgba(5, 224, 216, 1)
          );
        }

        /*滚动条两端方向按钮*/
        ::-webkit-scrollbar-button {
          // background-color: rgba(19, 238, 179, 1);
          background: linear-gradient(
            0deg,
            rgba(13, 117, 223, 1),
            rgba(5, 224, 216, 1)
          );
        }

        /*滚动条中间滑动部分*/
        ::-webkit-scrollbar-thumb {
          // background-color: rgba(19, 238, 179, 1);

          background: linear-gradient(
            0deg,
            rgba(13, 117, 223, 1),
            rgba(5, 224, 216, 1)
          );
          border-radius: 0.08rem;
        }

        /*滚动条右下角区域*/
        ::-webkit-scrollbar-corner {
          // background-color: rgba(19, 238, 179, 1);
          background: linear-gradient(
            0deg,
            rgba(13, 117, 223, 1),
            rgba(5, 224, 216, 1)
          );
        }
      }
    }
  }
}
