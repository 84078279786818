.glx-help-info {
  position: relative;
  z-index: 10;
  background: rgba(249, 249, 249, 1);
  min-height: 100vh;
  box-sizing: border-box;
  > .top-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -10;
  }

  $top-height: 2rem;
  > .top {
    display: flex;
    height: $top-height;
    align-items: center;

    > .back {
      padding: {
        top: 0.48rem;
        bottom: 0.48rem;
        left: 0.46rem;
        right: 0.28rem;
      }
      > .img {
        width: 0.3rem;
        height: 0.5rem;
        display: block;
      }
    }

    > .title {
      font-size: 0.56rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0.02rem 0.02rem 0rem rgba(97, 103, 213, 0.32);
    }
  }

  > .content {
    background: rgba(255, 255, 255, 1);
    border-radius: 0.28rem 0.28rem 0 0;
    padding: {
      top: 0.62rem;
      bottom: 0.62rem;
    }

    > .container {
      padding: {
        left: 0.46rem;
        right: 0.54rem;
      }
      overflow-y: scroll;
      height: calc(100vh - #{$top-height});

      font-size: 0.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(33, 33, 33, 1);
      line-height: 0.84rem;
    }
  }
}
