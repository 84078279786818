@import 'styles/common/flex.scss';

.order-header {
  position: relative;

  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
  }
  &__back {
    width: 0.38rem;
    height: 0.68rem;
    position: absolute;
    left: 0.48rem;
    top: 0.7rem;
    z-index: 10;
  }

  &--pay,
  &--receive {
    height: 6.24rem;
  }

  &--finished {
    height: 3.42rem;
  }
}

.order-header-status {
  font-size: 0.44rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0rem 0rem 0rem rgba(193, 4, 21, 0.1);
  padding-top: 1.42rem;

  position: relative;

  width: 100%;
  @include flex-horizontal-start;
  flex-wrap: wrap;
  &-time {
    width: 100%;
    font-size: 0.48rem;
    font-weight: bold;
  }
  &-price,
  &-remain {
    width: calc(50% - (0.72rem / 2));
  }
}

.order-header-status-button {
  $width: 4.3rem;
  width: $width;
  height: 1.32rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 0rem 0rem rgba(123, 11, 11, 0.29);
  border-radius: 1rem;

  font-size: 0.44rem;
  font-weight: bold;
  color: rgba(244, 16, 9, 1);

  @include flex-horizontal-center;

  position: absolute;
  top: 4.08rem;
  left: calc(50% - (#{$width} / 2));
}

.order-header-status-title {
  font-size: 0.72rem;
  margin: auto;
  width: 100%;
  @include flex-horizontal-center;

  & > img {
    height: 0.6rem;
    margin-right: 0.36rem;
  }
}

.order-header-status-price {
  @include flex-horizontal-end;
  margin-right: 0.72rem;
}

.order-header-status-remain {
  @include flex-horizontal-start;
}

.order-header-status-time {
  @include flex-horizontal-center;
}
