.glx-back {
  height: 2.1rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 .1rem .16rem 0 rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    font-size: .68rem;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(37, 37, 37, 1);
  }
}