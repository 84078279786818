@import "var";

//.div1200 {
//	position: fixed;
//	top: 0;
//	left: 0;
//	width: 100vw;
//	height: 100vh;
//	z-index: 100;
//
//	pointer-events: none;
//}


.live {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
	//height: 100vh;
	z-index: 100;
	pointer-events: none;


	.liveIconContainer {
		//width: 1200px;
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		> .liveIconWrapper {
			pointer-events: auto;
			width: 72px;
			height: 72px;

			position: absolute;
			//top: 50px;
			//right: -30px;
			top: 1202px;
			right: 33px;
			//transform: translateX(100%);

			&:hover {
				cursor: pointer;
			}

			> img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.container {
		//width: 300px;
		//height: 648px;
		border-radius: 20px;

		pointer-events: auto;

		> .videoWrapper {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 0;

			> .welfare {
				position: absolute;
				width: 234px;
				height: 112px;
				right: 20px;
				bottom: 462px;
				z-index: $info-z-index;
			}
		}
	}

	@keyframes hideKF {
		0% {
			width: 750px;
			height: 1624px;
			z-index: 0;
		}
		100% {
			width: 0;
			height: 0;
			z-index: -100;
		}
	}


	@keyframes showKF {
		0% {
			width: 0;
			height: 0;
			z-index: -100;
		}
		100% {
			width: 750px;
			height: 1624px;
			z-index: 0;
		}
	}

	:global {
		// 这个css和pc不一样 width:100% height:100%
		.container-normal {
			width: 100%;
			height: 100%;
			//width: 750px;
			//height: 1624px;
			z-index: 0;
		}

		.container-to-hide {
			& :local {
				animation-name: hideKF;
				animation-duration: 1s;
				animation-fill-mode: forwards;
				animation-direction: normal;
			}
		}

		.container-hide {
			width: 0;
			height: 0;
			z-index: -100;
		}

		.container-to-normal {
			& :local {
				animation-name: showKF;
				animation-duration: 1s;
				animation-fill-mode: forwards;
				animation-direction: normal;
			}
		}
	}
}