.index {
	max-width: 100vw;
	margin-top: 39px;

	> .container {
		height: 128px;

		margin-right: 32px;
		margin-left: 15px;

		> .imgWrapper {
			width: 100%;
			height: 100%;

			> .img {
				width: 100%;
				height: 100%;
			}
		}
	}
}