.history-search {
  > .title-floor {
    display: flex;
    margin-bottom: 0.4rem;
    > .title {
      font-size: 0.56rem;
      font-family: PingFangSC;
      font-weight: 10rem;
      color: rgba(24, 24, 24, 1);
      line-height: 0.4rem;
    }
  }

  > .list-floor {
    display: flex;
    flex-wrap: wrap;
    > .item {
      background: rgba(233, 233, 233, 1);
      border-radius: 0.08rem;

      padding: 0.22rem 0.42rem;

      font-size: 0.44rem;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(111, 107, 115, 1);
      line-height: 0.4rem;

      margin: {
        bottom: 0.4rem;
      }

      &:not(:last-child) {
        margin: {
          right: 0.4rem;
        }
      }
    }
  }
}
