.index {
	margin-top: 24px;

	> .container {
		> .titleFloor {
			box-sizing: border-box;
			padding-left: 32px;

			> .title {
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 28px;
				color: #383838;
			}
		}

		> .goodsListContainer {
			margin-top: 17px;
			box-sizing: border-box;
			padding: 0 32px;

			> .goodsList {
				display: flex;
				flex-wrap: wrap;

				> .goods {
					width: 336px;
					height: 449px;
					background: #FFFFFF;
					border-radius: 12px;

					&:not(:nth-child(2n + 1)) {
						margin-left: 14px;
					}

					&:nth-child(n + 3) {
						margin-top: 14px;
					}

					> .top {
						box-sizing: border-box;
						padding-top: 20px;

						> .imgWrapper {
							width: 296px;
							height: 296px;

							> img {
								border-radius: 10px;
								width: 100%;
								height: 100%;
							}
						}
					}

					> .bottom {
						box-sizing: border-box;
						padding-left: 21px;
						padding-right: 28px;

						> .nameWrapper {
							margin-top: 15px;
							min-height: 53px;

							> .name {
								font-family: PingFang SC;
								font-weight: bold;
								font-size: 24px;
								color: #343434;
								line-height: 30px;

								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box; //作为弹性伸缩盒子模型显示。
								-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
								-webkit-line-clamp: 2; //显示的行

								> span {
									padding: 0 6px;
									height: 22px;

									font-family: PingFang SC;
									font-weight: 500;
									font-size: 18px;
									color: #FFFFFF;
									line-height: 22px;

									margin-right: 6px;

									&.self {
										background-color: rgba(33, 114, 235, 1);
									}

									&.pdd, &.jd {
										background-color: rgba(243, 93, 64, 1);
									}
								}
							}
						}

						> .priceFloor {
							margin-top: 18px;

							> .price {
								font-family: Alibaba PuHuiTi;
								font-weight: 800;
								font-size: 28px;
								color: #E83B3B;
								line-height: 1;
							}
						}
					}
				}
			}
		}
	}
}