@import 'styles/common/flex.scss';
.search {
  background-color: #f9f9f9;
  height: 100vh;

  > .search-head {
    width: 100%;
    height: 2.08rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.07);

    @include flex-horizontal-center;

    &__cancel {
      font-size: 0.46rem;
      font-family: PingFangSC;
      font-weight: 400;
      color: rgba(106, 106, 106, 1);
      margin-left: 0.44rem;
    }
  }

  > .search-content {
    padding-top: 0.38rem;
    padding-left: 0.4rem;
    padding-right: 0.58rem;
    box-sizing: border-box;
    > .history-search {
      margin-bottom: 0.94rem;
    }

    > .hot-search {
    }

    >.goods-list{
        
    }
  }
}
