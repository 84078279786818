@import "var";

.imUserListPanel {
	position: absolute;
	z-index: $info-z-index + 200;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	> .card {
		position: absolute;
		top: 660px;
		bottom: 0;
		left: 0;
		right: 0;

		background: #FFFFFF;
		border-radius: 24px 24px 0px 0px;

		display: flex;
		flex-direction: column;
		align-items: stretch;
		> .titleFloor {
			height: 101px;
			text-align: center;

			flex-shrink: 0;

			> .title {
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 28px;
				color: #050505;
				line-height: 101px;
			}
		}

		> .userList {
			flex-grow: 1;
			overflow-y: scroll;
			> .user {
				height: 136px;
				display: flex;
				align-items: center;

				box-sizing: border-box;
				padding-left: 61px;

				> .avatar {
					width: 102px;
					height: 102px;
					border-radius: 50%;
				}

				> .nickname {
					font-family: PingFang SC;
					font-weight: bold;
					font-size: 26px;
					color: #050505;
					line-height: 1;

					margin-left: 30px;
				}

				&:hover {
					cursor: pointer;
				}

				&.active {
					background: linear-gradient(90deg, #FFFFFF 0%, #FCEBEB 100%);
				}
			}
		}
	}
}