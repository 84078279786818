@import 'styles/common/flex.scss';

.my-recent-card {
  padding: 0.5rem 0.6rem 0.2rem 0.6rem;

  width: 7.94rem;
  height: 3.34rem;
  flex-shrink: 0;
  // background-color: rgba(255, 255, 255, 1);
  background-image: url(../../../images/my/recent.png);
  background-repeat: no-repeat;
  background-size: cover;
  // box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.05);
  // border-radius: 0rem;

  @include flex-horizontal-between;
  @include flex-align-start;

  &__left {
    height: 100%;
    width: 100%;
    @include flex-vertical-between;
    @include flex-align-start;
  }

  &__left-top {
    @include flex-vertical;
    width: 100%;
  }

  &__name {
    width: 100%;
    font-size: 0.48rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(70, 70, 70, 1);
    line-height: 0.6rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__price {
    font-size: 0.4rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    line-height: 0.6rem;
  }

  &__time {
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
    line-height: 0.6rem;
  }

  &__img {
  }
}
