* {
  box-sizing: initial;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  width: unset;
  height: unset;
}

ul {
  list-style: none;
  margin: unset;
  padding: unset;
}

input {
  padding: unset;
  border: unset;
  outline-style: unset;
  box-sizing: border-box;
  background: unset;
  margin: unset;
}
input:focus {
  outline: unset;
}

button {
  background-color: transparent;
  padding: unset;
  border: unset;
  font: inherit;
  color: inherit;
  cursor: pointer;
}
button:focus, button:active {
  outline: none 0;
}
a,
span {
  text-decoration: none;
  outline: none;
  color: unset;
}

p {
  margin: 0;
}

.clear-float {
  clear: both;
}

.none {
  display: none;
}

.money-symbol {
  font-size: 0.8em;
}

.mall-row {
  width: 100%;
}

.mall-column {
  height: 100%;
}

img {
  font-size: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}

input[type=radio] {
  outline: none;
  width: 0.6rem;
  height: 0.6rem;
  vertical-align: middle;
  font-family: PingFangSC-Regular;
  color: rgb(56, 71, 68);
  -webkit-appearance: none;
  border: 0.02rem solid #ddd;
  display: inline-block;
  border-radius: 50%;
  position: relative;
}

input[type=radio]:checked:before {
  content: "";
  display: block;
  width: 0.34rem;
  height: 0.34rem;
  border-radius: 50%;
  background: #108ee9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type=radio]:checked {
  border: 0.04rem solid #108ee9;
  border-radius: 50%;
}