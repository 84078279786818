.glx-pay-success-look-around-floor {
  margin: {
    top: .50rem * 2;
  }

  > .container {
    padding: {
      left: .24rem * 2;
      right: .24rem * 2;
    }
    box-sizing: border-box;

    > .title-floor {
      > .title {
        font-size: .36rem * 2;
        font-family: PingFang SC;
        font-weight: 800;
        color: #121212;
        line-height: 1;

        background: linear-gradient(0deg, #121212 0%, #4D4D4D 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    > .goods-list {
      margin: {
        top: .23rem * 2;
      }
      display: flex;
      flex-wrap: wrap;

      .goods {
        width: 3.45rem * 2;
        height: 5.02rem * 2;
        background-image: url("./img/goods-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &:not(:nth-child(2n+1)) {
          margin-left: auto;
        }

        &:nth-child(n + 3) {
          margin: {
            top: .12rem * 2;
          }
        }

        > .img-block {
          box-sizing: border-box;
          padding: {
            top: .19rem * 2;
          }

          > .img-wrapper {
            width: 3.06rem * 2;
            height: 3.08rem * 2;
            border-radius: .10rem * 2 .36rem * 2 .10rem * 2 .10rem * 2;
            margin: {
              left: auto;
              right: auto;
            }

            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background-color: #fff;

            > img {
              width: 100%;
              display: block;
            }
          }
        }

        > .info-block {
          margin: {
            top: .16rem * 2;
            left: .19rem * 2;
            right: .24rem * 2;
          }

          > .name {
            font-size: .24rem * 2;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: .32rem * 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          > .price-floor {
            margin: {
              top: .23rem * 2;
              left: .19rem * 2;
              right: .19rem *2;
            }
            display: flex;
            align-items: center;

            > .current-price {
              font-size: .36rem * 2;
              font-family: Arial;
              font-weight: 900;
              color: #F44B5B;
              line-height: .20rem * 2;
            }

            > .tag-block {
              display: flex;
              align-items: center;
              margin-left: .23rem * 2;

              > .tag {
                width: .55rem * 2;
                height: .24rem * 2;
                border-radius: .04rem * 2;
                display: block;

                &:not(:first-child) {
                  margin-left: .06rem * 2;
                }

              }
            }

          }
        }
      }
    }
  }

  > .load-more {
    display: flex;
    justify-content: center;
    padding: {
      top: .2rem;
      bottom: .2rem;
    }
  }
}