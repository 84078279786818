@import 'styles/common/flex.scss';

.videos-header {
  width: 15rem;
  height: 2.46rem;
  background-color: #262c39;
  color: #ffffff;

  padding-top: 0.82rem;

  @include flex-vertical-center;

  &__back {
    width: 0.3rem;
    height: 0.5rem;
    position: absolute;
    left: 0.42rem;
    top: 0.92rem;
  }

  &__img {
    width: 2.86rem;
    height: 0.62rem;
    font-size: 0.72rem;
    font-family: Tensentype XiChaoHeiJ;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 0.64rem;
  }

  &__tabs {
    @include flex-horizontal-evenly;

    width: 100%;

    margin-top: 0.92rem;
  }

  &__tab {
    @include flex-horizontal-center;

    width: 0.72rem;
    padding-bottom: 0.42rem;
    font-size: 0.48rem;
    font-family: PingFang SC;
    font-weight: bold;

    line-height: 0.64rem;
    white-space: nowrap;

    a {
      color: rgba(255, 255, 255, 1);
    }

    &--active {
      $border-height: 3px;
      border-bottom: #d7249e $border-height solid;
      padding-bottom: calc(0.42rem - #{$border-height});
    }
  }
}
