.search-result {
  $head-height: 3.28rem;
  padding-top: $head-height;
  position: relative;
  > .head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $head-height;
    padding-top: 0.42rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0.02rem 0.12rem 0px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    > .input-floor {
    }
    > .condition {
      //   margin-top: 0.82rem;
      margin-top: auto;
    }
  }
  > .goods-list-container {
    padding-top: 0.4rem;
  }
  .ant-drawer.select-drawer {
    > .ant-drawer-content-wrapper {
      width: 11.2rem !important;
      > .ant-drawer-content {
        background: rgba(255, 255, 255, 0.96);
        border-radius: 0.4rem 0rem 0rem 0.4rem;
        > .ant-drawer-wrapper-body {
          > .ant-drawer-body {
            padding: unset;
          }
        }
      }
    }
  }
}
