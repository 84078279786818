.index {
	> .container {
		> .titleFloor {
			box-sizing: border-box;
			padding-left: 32px;

			> .title {
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 28px;
				color: #383838;
				line-height: 1;
			}
		}

		> .advWrapper {
			width: 686px;
			height: 224px;

			margin-left: auto;
			margin-right: auto;

			margin-top: 26px;

			> img {
				width: 100%;
				height: 100%;
				border-radius: 12px;
			}
		}

		> .goodsListContainer {
			width: 686px;
			height: 228px;
			background: #FFFFFF;
			border-radius: 10px;

			margin-top: 12px;
			margin-left: auto;
			margin-right: auto;

			//overflow-x: scroll;

			> .goodsList {
				display: flex;

				box-sizing: border-box;
				padding: 0 10px;
				justify-content: space-between;

				> .goods {
					> .top {
						box-sizing: border-box;
						padding-top: 18px;

						> .imgWrapper {
							width: 148px;
							height: 148px;
							position: relative;
							z-index: 0;

							> img {
								border-radius: 10px;
								width: 100%;
								height: 100%;
							}

							> span {
								position: absolute;
								z-index: 10;
								top: 0;
								right: 0;

								padding: 0 6px;
								height: 22px;
								border-radius: 0px 10px 0px 10px;

								font-family: PingFang SC;
								font-weight: 500;
								font-size: 18px;
								color: #FFFFFF;
								line-height: 22px;

								&.self {
									background-color: rgba(33, 114, 235, 1);
								}

								&.pdd, &.jd {
									background-color: rgba(33, 114, 235, 1);
								}
							}
						}
					}

					> .priceWrapper {
						margin-top: 7px;
						box-sizing: border-box;
						padding-left: 23px;

						> .price {
							font-family: Alibaba PuHuiTi;
							font-weight: 800;
							font-size: 20px;
							color: #E83B3B;
							line-height: 1;
						}
					}
				}
			}
		}
	}
}