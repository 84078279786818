@import 'styles/common/flex.scss';
@import 'styles/my.scss';

.glx-my__my-info {
  > .card-container {
    position: relative;

    > .card {
      margin: {
        left: .20rem * 2;
        right: .20rem * 2;
      }
      height: 2.39rem * 2;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: {
        top: .30rem * 2;
        bottom: .32rem * 2;
      }


      > .info-container {
        display: flex;
        align-items: center;
        padding-left: .30rem * 2;

        > .avatar-container {
          width: .72rem * 2;
          height: .72rem * 2;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.35);

          > .img-avatar {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        > .right {
          margin-left: .21rem * 2;

          > .title-container {
            display: flex;
            align-items: center;

            > .text-title {
              font-size: .32rem * 2;
              font-family: PingFang SC;
              font-weight: 800;
              color: #FFFFFF;
              line-height: 1;
            }

            > .img-verified {
              display: block;
              margin-left: .15rem * 2;
              width: .90rem * 2;
              height: .30rem * 2;
            }
          }

          > .text-sub-title {
            margin-top: .12rem * 2;
            font-size: .18rem * 2;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.8);
            line-height: 1;
          }
        }
      }

      > .data-container {
        margin-top: auto;
        display: flex;
        justify-content: center;


        > .item {
          &:not(:first-child) {
            margin-left: 1.40rem * 2;
          }

          > .value {
            font-size: .30rem * 2;
            font-family: Arial;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
          }

          > .label {
            margin-top: .15rem * 2;
            font-size: .20rem * 2;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(255, 224, 237, 0.7);
            text-align: center
          }
        }
      }

      > .img-bg {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -10;
      }

      > .modify-container {
        position: absolute;
        top: .21rem * 2;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: .24rem * 2;

        > .modify-text {
          font-size: .22rem * 2;
          font-family: PingFang SC;
          font-weight: 500;
          color: #F9E9E4;
          line-height: 1;
        }

        > .img-enter {
          display: block;
          margin-left: .11rem * 2;
          width: .08rem * 2;
          height: .15rem *2;
        }
      }
    }

    > .img-shadow {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      display: block;
      height: .42rem * 2;
    }

    > .img-top-shadow {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      display: block;
      height: .65rem * 2;
      transform: translateY(-100%);
    }
  }
}
