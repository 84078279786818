.modify-avatar {
  $header-height: 1.74rem;
  height: 100vh;
  box-sizing: border-box;
  padding-top: $header-height;
  background: rgba(249, 249, 249, 1);
  > .header {
    position: fixed;
    top: 0;
    width: 100%;

    height: $header-height;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0rem 0.02rem 0.16rem 0rem rgba(21, 21, 21, 0.08);

    display: flex;
    align-items: center;

    > .back {
      height: 100%;
      padding-left: 0.4rem;
      padding-right: 0.6rem;
      display: flex;
      align-items: center;

      > .arrow {
        width: 0.28rem;
        height: 0.5rem;
        display: block;
      }
    }

    > .title {
      margin-left: auto;
      margin-right: auto;

      font-size: 0.64rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(49, 49, 49, 1);
      line-height: 0.4rem;
    }
  }

  > .form {
    padding-top: 1.14rem;
    > .floor {
    //   height: 1.76rem;
      display: flex;
      justify-content: center;
      padding-left: 1.2rem;
      padding-right: 1.2rem;

      &:not(:first-child) {
        margin-top: 0.52rem;
      }
      &.btn-floor {
        margin-top: 1.8rem;
      }

      > .input {
        height: 100%;
        background: rgba(255, 255, 255, 1);
        border: 0.02rem solid rgba(229, 229, 229, 1);
        border-radius: 0.2rem;
        display: block;
        flex-grow: 1;

        padding-left: 0.8rem;
        padding-right: 0.8rem;

        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(154, 154, 154, 1);
        line-height: 0.4rem;
      }

      > .submit-btn {
        width: 100%;
        height: 1.4rem;
        background: linear-gradient(
          90deg,
          rgba(75, 123, 233, 1),
          rgba(88, 164, 235, 1)
        );
        box-shadow: 0rem 0.04rem 0.12rem 0rem rgba(64, 137, 214, 0.1);
        border-radius: 0.7rem;

        display: block;

        line-height: 1.4rem;
        font-size: 0.52rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        text-shadow: 0 0.02rem 0rem rgba(0, 0, 0, 0.45);
      }
    }
  }
}
