.goods-list {
  > .block-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    > .item {
      width: 6.92rem;
      border-radius: 0.16rem 0.16rem 0rem 0rem;

      background: rgba(255, 255, 255, 1);
      box-shadow: 0rem 0rem 0.14rem 0rem rgba(0, 0, 0, 0.06);

      &:nth-child(n + 3) {
        margin-top: 0.36rem;
      }
      > .img-block {
        height: 6.92rem;
        display: flex;
        justify-content: center;
        overflow: hidden;
        > .img {
          height: 100%;
          display: block;
        }
      }
      > .info-block {
        height: 3.12rem;

        padding-top: 0.28rem;
        padding-left: 0.22rem;
        padding-right: 0.28rem;

        > .name-floor {
          font-size: 0.48rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(28, 28, 28, 1);
          line-height: 0.6rem;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        > .price-floor {
          display: flex;
          align-items: center;
          margin-top: 0.28rem;

          > .current-price {
            font-size: 0.4rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(214, 23, 45, 1);
            line-height: 0.4rem;
          }

          > .self {
            background: rgba(223, 67, 67, 1);
            border-radius: 0.08rem;
            margin-left: 0.24rem;

            font-size: 0.4rem;
            font-family: PingFangSC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: 0.4rem;

            padding: 0.06rem 0.2rem;
          }

          > .hot {
            margin-left: 0.2rem;
            background: rgba(240, 93, 59, 1);
            border-radius: 0.08rem;

            font-size: 0.4rem;
            font-family: PingFangSC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: 0.4rem;
            padding: 0.06rem 0.2rem;
          }
        }
        > .sold-floor {
          margin-top: 0.26rem;
          > .sold-num {
            font-size: 0.04rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(173, 173, 173, 1);
            line-height: 0.6rem;
          }
        }
      }
    }
  }

  > .list-list {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    box-sizing: border-box;
    > .item {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0rem 0rem 0.14rem 0rem rgba(0, 0, 0, 0.06);
      border-radius: 0.16rem;

      display: flex;
      align-items: flex-start;
      padding-left: 0.2rem;
      padding-right: 0.24rem;
      padding-top: 0.2rem;
      padding-bottom: 0.3rem;
      box-sizing: border-box;

      &:not(:first-child) {
        margin-top: 18px;
      }

      > .img-block {
        width: 3.38erm;
        height: 3.38rem;
        border-radius: 0.16rem;
        display: flex;
        justify-content: center;
        overflow: hidden;
        flex-shrink: 0;
        > .img {
          height: 100%;
          display: block;
        }
      }

      > .info-block {
        margin-left: 0.26rem;

        > .name-floor {
          font-size: 0.48rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(28, 28, 28, 1);
          line-height: 0.68rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        > .price-floor {
          margin-top: 0.46rem;
          font-size: 0.4rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(214, 23, 45, 1);
          line-height: 0.4rem;
        }

        > .self-floor {
          margin-top: 0.24rem;
          display: flex;
          align-items: center;
          > .self {
            background: rgba(223, 67, 67, 1);
            border-radius: 0.08rem;
            padding: 0.06rem 0.2rem;

            font-size: 0.4rem;
            font-family: PingFangSC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: 0.4rem;
          }
          > .hot {
            margin-left: 0.2rem;
            background: rgba(240, 93, 59, 1);
            border-radius: 0.08rem;
            padding: 0.06rem 0.2rem;

            font-size: 0.4rem;
            font-family: PingFangSC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: 0.4rem;
          }
        }

        > .sold-floor {
          display: flex;

          > .sold-num {
            margin-left: auto;
            font-size: 0.4rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(173, 173, 173, 1);
            line-height: 0.6rem;
          }
        }
      }
    }
  }
}
