.news-swiper {
  width: 14.2rem;
  height: 5.32rem;
  margin: auto;

  overflow-x: hidden;

  > .swiper-container {
    border-radius: 0.3rem;
  }

  &__img {
    height: 100%;
    width: 100%;
  }
}
