.account {
  padding-top: 4.92rem;
  height: 100vh;
  box-sizing: border-box;
  background: rgba(249, 249, 249, 1);

  > .card {
    margin-left: 0.4rem;
    margin-right: 0.4rem;

    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0.02rem 0.1rem 0.1rem rgba(0, 0, 0, 0.04);
    border-radius: 0.2rem;
    position: relative;
    z-index: 1;

    > .item {
      height: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      padding-left: 0.4rem;
      padding-right: 0.4rem;
      box-sizing: border-box;

      position: relative;

      &.avatar {
        height: 2.4rem;
      }
      &.username {
        margin-right: 0.4rem + 0.18rem;
      }
      &:not(:first-child) {
        &::before {
          content: '';
          display: block;

          position: absolute;
          top: 0;
          left: 0.42rem;
          right: 0.42rem;

          height: 1px;
          border-top: 1px solid rgba(246, 246, 246, 1);
        }
      }

      > .title {
        margin-right: auto;

        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(45, 45, 45, 1);
        line-height: 0.4rem;
      }

      > .avatar {
        width: 1.52rem;
        height: 1.52rem;
        border-radius: 50%;
        display: block;
      }

      > .username,
      > .nickname {
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(115, 115, 115, 1);
        line-height: 0.4rem;
      }

      > .enter {
        margin-left: 0.4rem;
        display: block;
        width: 0.18rem;
        height: 0.34rem;
      }
    }
  }

  > .btn-floor {
    position: absolute;
    bottom: 0.86rem;
    left: 0.4rem;
    right: 0.4rem;
    > .logout-btn {
      display: block;
      width: 100%;
      height: 1.4rem;
      background: linear-gradient(
        90deg,
        rgba(240, 55, 81, 1),
        rgba(252, 37, 128, 1)
      );
      box-shadow: 0 0.04rem 0.12rem 0px rgba(26, 206, 178, 0.1);
      border-radius: 0.7rem;

      line-height: 1.4rem;
      font-size: 0.52rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      text-shadow: 0 0.02rem, 02rem rgba(0, 0, 0, 0.45);
    }
  }
}
