.videoWrapper {
	width: 100%;
	height: 100%;

	position: relative;
	z-index: 0;

	> .bgImg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -100;
	}

	> .table {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 100;
		object-fit: contain;
		width: 100%;
	}

	> .goodsOnTable {
		position: absolute;
		left: 55px;
		bottom: 235px;
		z-index: 110;
		object-fit: contain;
		width: 406px;
	}

	.playPanel {
		position: absolute;
		z-index: 500;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		background-color: rgba(0, 0, 0, 0.4);

		&:hover {
			cursor: pointer;
		}

		> .iconPlay {
			position: absolute;

			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 200px;
			height: 200px;
		}
	}
}

.video {
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;

	background-size: 100% 100%;
	background-repeat: no-repeat;
	//object-position: 0 68%;
	//mix-blend-mode: screen;
}

.videoHide {
	position: absolute;
	z-index: -10000;
	opacity: 0;
	//top: -10000px;
	//left: -10000px;
}