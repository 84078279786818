@import 'styles/common/flex.scss';

.order-pay {
  &__bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    &--color {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -2;
      background-color: #f5f5f5;
    }
  }
}

.order-pay-header {
  height: 1.68rem;
  position: relative;

  &__back {
    width: 0.28rem;
    height: 0.5rem;
    position: absolute;
    left: 0.42rem;
    top: 0.6rem;
  }

  span {
    font-size: 0.6rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 0.4rem;
  }

  @include flex-horizontal-center;
}

.order-pay-address {
  width: calc(14.2rem - 0.74rem);
  height: calc(5.14rem - 0.84rem);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.04);
  border-radius: 0.3rem;
  margin: auto;

  padding-left: 0.74rem;
  padding-top: 0.84rem;

  position: relative;

  &__default {
    width: 0.88rem;
    height: 0.44rem;
    background: rgba(67, 232, 230, 1);
    border-radius: 0.1rem;
    font-size: 0.36rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 0.4rem;
  }

  &__enter {
    width: 0.14rem;
    height: 0.38rem;
    position: absolute;
    right: 0.66rem;
    top: 2.38rem;
  }

  div:nth-child(1) {
    font-size: 0.48rem;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
    line-height: 0.4rem;
  }

  div:nth-child(2) {
    width: 11.22rem;
    height: 1.62rem;
    font-size: 0.68rem;
    font-weight: 800;
    color: rgba(24, 24, 24, 1);
    line-height: 0.96rem;
  }

  > .select-text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: block;
      margin-left: 30%
    }

    img.arrow {
      margin-left: auto;
      margin-right: 1rem;
    }
  }
}

.order-pay-type {
  @include flex-horizontal-between;

  width: calc(14.2rem - 0.6rem * 2);
  height: 2.14rem;
  background: rgba(255, 255, 255, 1);
  border: 0px solid rgba(242, 242, 242, 1);
  margin: auto;
  margin-top: 0.4rem;
  padding-left: 0.62rem;
  padding-right: 0.62rem;
  border-radius: 0.4rem;

  font-size: 0.48rem;
  font-weight: bold;
  color: rgba(31, 31, 31, 1);
  line-height: 0.4rem;
}

.order-pay-goods {
  $padding-horizontal: 0.64rem;
  $padding-vertical: 0.82rem;
  width: calc(14.2rem - #{$padding-horizontal} * 2);
  //height: calc(7.12rem - #{$padding-vertical} * 2);
  background: rgba(255, 255, 255, 1);
  border: 0px solid rgba(242, 242, 242, 1);
  border-radius: 0.3rem;
  margin: auto;
  margin-top: 0.4rem;

  padding: $padding-vertical $padding-horizontal;

  position: relative;

  > .flex {
    display: flex;

    &.floor1 {
      align-items: center;

      > .self {
        margin: {
          left: auto;
        }
        font-size: 0.48rem;
        font-weight: bold;
        color: #1f1f1f;
      }
    }

    &.floor2 {
      margin-top: 0.4rem;

      > .order-pay-goods__desc {
        > .price-floor {
          display: flex;

          > .price {
            font-size: .36rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #EF1E3B;
            line-height: .64rem;
          }

          > .count {
            margin-left: auto;

            font-size: .32rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #454545;
            line-height: .64rem;
          }
        }
      }
    }
  }

  &__shop-icon {
    width: 0.42rem;
    height: 0.38rem;
  }

  &__shop-name {
    margin-left: .2rem;
    font-size: 0.48rem;
    font-weight: bold;
    color: rgba(31, 31, 31, 1);
  }

  &__img {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.1rem;
  }

  &__desc {
    margin-left: .2rem;
    width: 0;
    flex-grow: 1;

    > .name {
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
      //color: rgba(27, 27, 27, 1);
      //font-weight: bold;

      font-size: .48rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #202020;
      line-height: 1;
    }

    > .specification {
      display: block;
      margin-top: .2rem;
      font-size: .36rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #979797;
      line-height: 1;
    }

    .price-floor{
      margin-top: .46rem;
      .glx-price {
        font-size: 0.36rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #EF1E3B;
        line-height: 1;

        &__int {
          font-size: 0.52rem;
        }
      }
    }

    span:nth-child(1) {
      font-size: 0.48rem;
      font-weight: bold;
      //color: rgba(31, 31, 31, 1);
      line-height: 0.4rem;
    }

    span:nth-child(2) {
      font-size: 0.36rem;
      font-weight: 500;
      //color: rgba(151, 151, 151, 1);
      line-height: 0.4rem;
    }

    span:nth-child(3) {
      font-size: 0.36rem;
      font-weight: 500;
      //color: rgba(239, 29, 59, 1);
      line-height: 0.64rem;

    }

    span:nth-child(4) {
      font-size: 0.36rem;
      font-weight: bold;
      color: rgba(249, 65, 22, 1);
      line-height: 0.64rem;
    }
  }

  &__deliver {
    @include flex-horizontal-between;
  }

  &__deliver-time {
    @include flex-horizontal-end;
  }
}

.order-pay-price {
  $padding-horizontal: 0.64rem;
  $padding-vertical: 0.82rem;
  width: calc(14.2rem - #{$padding-horizontal} * 2);
  height: calc(3.04rem - #{$padding-vertical} * 2);
  background: rgba(255, 255, 255, 1);
  border: 0px solid rgba(242, 242, 242, 1);
  border-radius: 0.4rem;
  padding: $padding-vertical $padding-horizontal;
  margin: auto;
  margin-top: 0.4rem;

  font-size: 0.48rem;
  font-weight: bold;
  color: rgba(31, 31, 31, 1);
  line-height: 0.64rem;

  &__deliver {
    color: rgba(239, 29, 59, 1);
  }

  & > div {
    @include flex-horizontal-between;
  }

  div:last-child {
    margin-top: 0.5rem;
  }
}

.order-pay-select {
  $padding-horizontal: 0.64rem;
  $padding-vertical: 0.82rem;
  width: calc(14.2rem - #{$padding-horizontal} * 2);
  height: calc(4.24rem - #{$padding-vertical} * 2);
  background: rgba(255, 255, 255, 1);
  border: 0px solid rgba(242, 242, 242, 1);
  border-radius: 0.3rem;
  margin: auto;
  margin-top: 0.4rem;
  padding: $padding-vertical $padding-horizontal;

  .option {
    display: flex;
    align-items: center;

    .pay-icon {
      display: block;
      width: 0.64rem;
      height: 0.64rem;
    }

    .pay-name {
      display: block;
      margin: {
        left: .36rem;
      }
      font-size: .48rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(31, 31, 31, 1);
    }

    .radio-btn {
      margin: {
        left: auto;
      }
      width: .64rem;
      height: .64rem;
      border: .04rem solid rgba(220, 220, 220, 1);
      border-radius: 50%;

      &.active {
        background: rgba(250, 22, 39, 1);
      }
    }
  }
}

.order-pay-ope {
  width: 14.18rem;
  height: 1.76rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.07);
  border-radius: 1rem;
  margin: auto;
  margin-top: 0.72rem;

  @include flex-horizontal-between;

  &__price {
    padding: 0.62rem;

    .glx-price {
      font-size: 0.4rem;
      font-weight: bold;
      color: rgba(239, 29, 59, 1);
      line-height: 0.64rem;

      &__int {
        font-size: 0.64rem;
      }
    }
  }

  &__button {
    @include flex-horizontal-center;
    width: 3.68rem;
    height: 1.56rem;
    background: linear-gradient(
                    90deg,
                    rgba(251, 59, 104, 1),
                    rgba(237, 23, 69, 1)
    );
    border-radius: 1rem;

    font-size: 0.48rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 0.64rem;
  }
}

.price-block {
  margin: {
    left: .4rem;
    right: .4rem;
    top: .4rem;
  }
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-radius: 14px;
  box-sizing: border-box;
  padding: {
    top: .8rem;
    bottom: .8rem;
    left: .64rem;
    right: .65rem;
  };
  display: flex;

  > .label {

  }

  > .price {
    margin: {
      left: auto;
    }
  }
}
