.glx-live__live-list {
  > .container {
    height: 8.18rem * 2;

    > .swiper-container {
      width: 5.16rem * 2;
      height: 100%;

      > .swiper-wrapper {
        > .swiper-slide {
          transition-duration: 0.5s;

          > .player-wrapper {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 100%;

            box-shadow: 0 .03rem * 2 .22rem * 2 .02rem * 2 rgba(0, 0, 0, 0.18);

            > .poster {
              position: absolute;
              z-index: -1;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              display: block;

              border-radius: .12rem * 2;
            }

            > .panel-container {
              height: 100%;
              background-color: rgba(0, 0, 0, 0.18);
              position: relative;

              > .top-container {
                box-sizing: border-box;
                padding: {
                  top: .17rem * 2;
                  left: .16rem * 2;
                  right: .16rem * 2;
                }
                display: flex;

                > .playing-icon-wrapper {
                  width: 1.38rem * 2;
                  height: .42rem * 2;

                  > .icon {
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transform: scaleX(1.1) scaleY(1.4) translateY(5%);
                  }
                }


                > .heat-wrapper {
                  margin: {
                    left: auto;
                  }
                  height: .42rem * 2;
                  line-height: .42rem * 2;
                  background-color: rgba(0, 0, 0, 0.2);
                  border-radius: 0.21rem * 2;
                  box-sizing: border-box;
                  padding: {
                    left: .16rem * 2;
                    right: .16rem * 2;
                  }

                  font-size: .22rem * 2;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #F1F3F8;
                }

              }

              > .play-icon-wrapper {
                position: absolute;
                width: .86rem * 2;
                height: .76rem * 2;
                left: 0;
                right: 0;
                top: 46%;
                margin: {
                  left: auto;
                  right: auto;
                }

                > .play-icon {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }

              > .bottom-container {
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;

                box-sizing: border-box;
                padding: {
                  left: .30rem * 2;
                }

                > .cart-wrapper {
                  height: .42rem * 2;
                  background-color: rgba(255, 255, 255, 0.3);
                  border: 1px solid #FFFFFF;
                  border-radius: .21rem * 2;
                  padding: {
                    left: .15rem * 2;
                    right: .15rem * 2;
                  }
                  box-sizing: border-box;
                  display: inline-flex;
                  align-items: center;

                  > .cart-icon {
                    width: .24rem * 2;
                    height: .23rem * 2;
                    display: block;
                  }

                  > .text {
                    margin: {
                      left: .08rem * 2;
                    }
                    font-size: .20rem * 2;
                    font-family: PingFang SC;
                    font-weight: 800;
                    color: #FEFEFE;
                  }
                }

                > .info-wrapper {
                  margin: {
                    top: .24rem * 2;
                  }
                  display: flex;

                  > .avatar {
                    display: block;
                    width: .42rem * 2;
                    height: .42rem * 2;
                    border-radius: 50%;
                  }

                  > .username {
                    margin: {
                      left: .13rem * 2;
                    }
                    font-size: .24rem * 2;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #FFFFFF;
                    //line-height: .32rem * 2;
                  }

                  > .focus {
                    margin: {
                      left: .19rem * 2;
                    }

                    height: .48rem * 2;
                    line-height: .48rem * 2;
                    background: linear-gradient(90deg, #F97934, #F42635);
                    border-radius: 24px;
                    padding: {
                      left: .09rem * 2;
                      right: .09rem * 2;
                    }
                    box-sizing: border-box;

                    font-size: .20rem * 2;
                    font-family: PingFang SC;
                    font-weight: 800;
                    color: #FFFFFF;
                  }
                }

                > .title-wrapper {
                  margin: {
                    top: .11rem * 2;
                    bottom: .23rem * 2;
                  }
                  box-sizing: border-box;
                  padding: {
                    right: .55rem * 2;
                  }

                  font-size: .26rem * 2;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #FFFFFF;

                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}