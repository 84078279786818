.index {
	margin-top: 34px;

	> .container {
		box-sizing: border-box;
		padding-left: 32px;
		padding-right: 32px;

		> .floor {
			display: flex;
			align-items: stretch;
			justify-content: space-between;

			height: 335px;

			> .left {
				width: 336px;

				> img {
					width: 100%;
					height: 100%;
					border-radius: 12px;
				}
			}

			> .right {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				width: 336px;

				> .top {
					width: 336px;
					height: 160px;

					> img {
						width: 100%;
						height: 100%;
						border-radius: 12px;
					}
				}

				> .bottom {
					width: 336px;
					height: 160px;
					border-radius: 12px;

					> img {
						width: 100%;
						height: 100%;
						border-radius: 12px;
					}
				}
			}
		}
	}
}