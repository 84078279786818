.glx-merchant-bottom-btn {
  margin: {
    top: 0.86rem;
    left: 0.4rem;
    right: 0.4rem;
  }

  > .button {
    display: block;

    width: 100%;
    height: 1.6rem;
    background: linear-gradient(
      90deg,
      rgba(13, 117, 223, 1),
      rgba(5, 224, 216, 1)
    );
    box-shadow: 0 0.18rem 0.3rem 0.02rem rgba(9, 141, 192, 0.2);
    border-radius: 0.8rem;

    line-height: 1.6rem;
    font-size: 0.56rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 0.72rme;
    text-shadow: 0 0.18rem 0.36rem rgba(18, 76, 155, 0.22);
  }
}
