.glx-play-video {
  > .player {
    height: 100vh;

    > .dplayer-video-wrap {
      > .dplayer-video {
        object-fit: fill
      }
    }
  }
}