.glx-live-room__user-list-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, 0.9);
  border-radius: .14rem * 2 .14rem * 2 0 0;

  > .title-container {
    $height: .97rem * 2;
    height: $height;
    flex-shrink: 0;

    > .title {
      height: 100%;
      line-height: $height;
      font-size: .26rem * 2;
      font-family: PingFang SC;
      font-weight: 800;
      color: #171520;
      text-align: center;
    }
  }

  > .content-container {
    flex-grow: 1;

    overflow-y: scroll;

    > .item {
      height: 1.04rem * 2;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: {
        left: .58rem * 2;
      }

      //&:hover {
      //  background: linear-gradient(270deg, #FAC5C9, #FEF7F2);
      //}

      > .avatar {
        display: block;
        width: .68rem *2;
        height: .68rem * 2;
        border-radius: 50%;
      }

      > .nickname {
        margin: {
          left: .24rem * 2;
        }
      }
    }
  }
}