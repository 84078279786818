@import 'styles/common/flex.scss';

.orders-placeholder {
  @include flex-vertical-center;
  padding: 1.1rem;
  &__bg {
    width: 3.86rem;
    height: 3.62rem;
  }
  span {
    font-size: 0.44rem;

    font-weight: bold;
    color: rgba(150, 147, 149, 1);
  }
}
