.shop-collect {
  padding-top: 3.08rem;
  padding-bottom: 0.66rem;
  background: rgba(249, 249, 249, 1);
  min-height: 100vh;
  box-sizing: border-box;

  > .tab {
    z-index: 20;
  }

  > .shop-list {
    margin-top: 0.4rem;
    > .shop-wrapper {
      margin-left: 0.4rem;
      margin-right: 0.4rem;
      &:not(:first-child) {
        margin-top: 0.4rem;
      }
      > .am-swipe {
        > .am-swipe-content {
          > .shop {
            display: flex;
            box-sizing: border-box;
            padding: 0.4rem 0.4rem;
            > .img-block {
              width: 3.24rem;
              height: 3.24rem;
              flex-shrink: 0;
              border-radius: 0.2rem;
              display: flex;
              justify-content: center;
              overflow: hidden;
              > .img {
                height: 100%;
              }
            }

            > .info-block {
              margin-left: 0.4rem;
              display: flex;
              flex-direction: column;
              flex-grow: 1;

              > .name {
                font-size: 0.48rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: rgba(24, 24, 24, 1);
                line-height: 0.64rem;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }

  > .recommend-list {
    margin-top: 0.96rem;

    > .title-floor {
      padding-left: 0.44rem;
      font-size: 0.56rem;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(49, 49, 49, 1);
      line-height: 0.4rem;
    }

    > .list {
      margin-top: 0.48rem;
      display: flex;
      flex-wrap: wrap;
      padding: 0 0.44rem;
      justify-content: space-between;

      > .item {
        width: 6.92rem;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0rem 0rem 0.14rem 0rem rgba(0, 0, 0, 0.06);
        border-radius: 0.16rem;
        &:nth-child(n + 3) {
          margin-top: 0.28rem;
        }

        > .img-block {
          height: 6.92rem;
          border-radius: 0.16rem 0.16rem 0rem 0rem;
          display: flex;
          justify-content: center;
          overflow: hidden;
          > .img {
            display: block;
            height: 100%;
          }
        }

        > .info-block {
          padding-top: 0.28rem;
          padding-bottom: 0.22rem;
          padding-left: 0.22rem;
          padding-right: 0.48rem;

          > .name {
            font-size: 0.48rem;
            font-family: PingFangSC;
            font-weight: 500;
            color: rgba(28, 28, 28, 1);
            line-height: 0.6rem;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          > .price-floor {
            margin-top: 0.28rem;
            display: flex;
            align-items: center;
            > .current-price {
              font-size: 0.4rem;
              font-family: PingFangSC;
              font-weight: 500;
              color: rgba(214, 23, 45, 1);
              line-height: 0.4rem;
            }

            > .self {
              margin-left: 0.26rem;
              background: rgba(223, 67, 67, 1);
              border-radius: 0.08rem;
              padding: 0.08rem 0.2rem;

              font-size: 0.4rem;
              font-family: PingFangSC;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
              line-height: 0.4rem;
            }

            > .hot {
              margin-left: 0.2rem;
              background: rgba(240, 93, 59, 1);
              border-radius: 0.08rem;
              padding: 0.08rem 0.2rem;

              font-size: 0.4rem;
              font-family: PingFangSC;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
              line-height: 0.4rem;
            }
          }
        }
      }
    }
  }
}
