.app {
  height: 100vh;
  @mixin mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;

    background: rgba(0, 0, 0, 0.8);
  }

  > .confirm-goods-mask {
    @include mask;
  }

  > .service-mask {
    @include mask;
    box-sizing: border-box;
    padding: {
      top: 120px;
    }
  }
}

html,
body {
  margin: 0;
  width: unset;
  height: 100%;
  background-color: unset;
}

ul {
  list-style: none;
  margin: unset;
  padding: unset;
}

input {
  padding: unset;
  border: unset;
  outline-style: unset;
  box-sizing: border-box;
  background: unset;
  margin: unset;

  &:focus {
    outline: unset;
  }
}

button {
  background-color: transparent;
  padding: unset;
  border: unset;
  font: inherit;
  color: inherit;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none 0;
  }

  &:hover {
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}
