.glx-home-random-product-card {
  &:nth-child(n + 3) {
    margin: {
      top: 0.28rem;
    }
  }
  //   &:nth-child(2n) {
  //     margin: {
  //       left: 0.28rem;
  //     }
  //   }
  width: calc((100% - 0.28rem) / 2);
  //   width: 6.92rem;
  //   height: 490px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 0.14rem 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.16rem;

  > .img-block {
    height: 6.86rem;
    border-radius: 0.16rem 0.16rem 0 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    > .img {
      height: 100%;
      border-radius: 0.16rem 0.16rem 0 0;
    }
  }
  > .info-block {
    height: 2.94rem;
    box-sizing: border-box;
    padding: {
      top: 0.34rem;
      left: 0.22rem;
      right: 0.42rem;
    }
    > .name-floor {
      font-size: 0.28rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(28, 28, 28, 1);
      line-height: 0.6rem;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    > .price-floor {
      margin: {
        top: 0.24rem;
      }
      display: flex;
      align-items: center;
      > .current-price {
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(214, 23, 45, 1);
        // line-height: 0.4rem;
      }
      > .self {
        margin: {
          left: 0.26rem;
        }

        background: rgba(223, 67, 67, 1);
        border-radius: 0.08rem;

        padding: 0.06rem 0.2rem;

        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        // line-height: 0.4rem;
      }

      > .hot {
        margin: {
          left: 0.2rem;
        }

        background: rgba(240, 93, 59, 1);
        border-radius: 0.08rem;

        padding: 0.06rem 0.2rem;

        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        // line-height: 0.4rem;
      }
    }
  }
}
