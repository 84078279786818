@import "var";

.messageList {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 156px;
	z-index: $info-z-index;

	height: 270px + 48px * 2;

	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;

	overflow-y: scroll;

	> .messageLine {
		max-width: 100%;

		> .messageWrapper {
			background: rgba(0, 0, 0, 0.2);
			border-radius: 24px;

			padding-left: 20px;
			padding-right: 10px;

			margin-bottom: 8px;
			display: inline-block;

			> span.message {
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 24px;
				color: rgba(255, 255, 255, 1);
				line-height: 42px;

				> em.nickname {
					color: rgba(255, 204, 158, 1);
					margin-inline-end: 10px;
					font-style: normal;
					font-weight: normal;
				}
			}
		}
	}

}