@import 'styles/common/flex.scss';

.orders-deliver-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: all;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}

.orders-deliver {
  width: 12.92rem;
  height: 18.18rem;
  // background: rgba(255, 255, 255, 1);
  border-radius: 0rem;
  margin: auto;
  margin-top: 3.36rem;
  background-image: url('image/deliver-bg.png');
  background-size: cover;
  &-head {
    height: (2.18rem+2.38rem);
    position: relative;
    p:first-child {
      font-size: 0.6rem;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      width: 2.4rem;
      height: 0.58rem;
      margin: auto;
      position: absolute;
      top: 1.14rem;
      left: 0;
      right: 0;
    }
    div:last-of-type {
      width: 3.08rem;
      height: 1.16rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0rem 0rem 0rem 0rem rgba(123, 11, 11, 0.29);
      border-radius: 1rem;

      font-size: 0.44rem;
      color: rgba(243, 34, 17, 1);

      position: absolute;
      top: 0.84rem;
      right: 0.88rem;

      @include flex-horizontal-center;
    }

    img:first-of-type {
      width: 2.18rem;
      height: 2.18rem;
      background: rgba(0, 0, 0, 1);
      border-radius: 0.2rem;
      margin: auto;
      position: absolute;
      top: 2.36rem;
      left: 0;
      right: 0;
    }
  }
  &-content {
    height: 12.16rem;
  }
}

.deliver-current-icon {
  width: 0.77rem;
}

.deliver-not-current-spot {
  width: 0.18rem;
  height: 0.18rem;
  background: rgba(221, 221, 221, 1);
  border-radius: 50%;
  display: block;
}

.orders-deliver-content {
  overflow: scroll;
  &__container {
  }
}

.orders-deliver-info {
  padding-left: 1.44rem;
  position: relative;
  &__time {
    font-size: 0.4rem;
    color: rgba(157, 157, 157, 1);
    margin-bottom: 0.42rem;
  }
  &__info {
    width: 8.78rem;
    font-size: 0.48rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    line-height: 0.64rem;
    padding-bottom: 1.26rem;
    &::before {
      content: '';
      width: 2px;
      height: 100%;
      background: rgba(236, 236, 236, 1);
      position: absolute;
      left: 0.8rem;
      top: 0;
    }
    &--current {
      &::before {
        background: rgba(245, 159, 159, 1);
      }
    }
  }
  .deliver-current-icon {
    position: absolute;
    background-color: white;
    left: 0.44rem;
    z-index: 1;
  }
  .deliver-not-current-spot {
    position: absolute;
    left: 0.74rem;
    z-index: 1;
  }
}

.orders-deliver-bottom {
  @include flex-horizontal-center;
  width: 100%;
  height: 1.48rem;
  &__to-detail {
    width: 0.1rem;
    height: 0.26rem;
    margin-left: 0.46rem;
  }
}
