.select {
  > .category-container {
    padding: 0.58rem 0.48rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 0.28rem;
    > .title-floor {
      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(32, 32, 32, 1);
      line-height: 0.4rem;
    }
    > .content-floor {
      margin-top: 0.54rem;
      display: flex;
      flex-wrap: wrap;

      > .item {
        height: 0.96rem;
        background: rgba(246, 246, 246, 1);
        border-radius: 0.48rem;

        padding: 0.28rem 0.7rem;
        box-sizing: border-box;

        font-size: 0.44rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(77, 77, 77, 1);
        line-height: 0.4rem;

        margin-bottom: 0.4rem;
        &:not(:last-child) {
          margin-right: 0.48rem;
        }

        &.active {
          background: rgba(249, 226, 229, 1);
          color: rgba(244, 22, 43, 1);
        }
      }
    }
  }
  > .price-container {
    background: rgba(255, 255, 255, 1);
    padding-left: 0.48rem;
    padding-right: 0.48rem;
    padding-top: 0.54rem;
    padding-bottom: 1.08rem;
    box-sizing: border-box;
    > .title-floor {
      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(32, 32, 32, 1);
      line-height: 0.4rem;
    }

    > .content-floor {
      margin-top: 0.46rem;
      display: flex;
      align-items: center;

      > .price-input {
        width: 4.66rem;
        height: 0.94rem;
        background: rgba(246, 246, 246, 1);
        border-radius: 0.48rem;

        text-align: center;
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(170, 170, 170, 1);
        line-height: 0.4rem;

        box-sizing: border-box;
        padding: 0 0.24rem;


      }

      > .split-line {
        width: 0.4rem;
        height: 0.06rem;
        background: rgba(75, 74, 74, 1);
        border-radius: 0.04rem;
        margin: 0 0.24rem;
      }
    }
  }
}
