.glx-live-poster-page {
  position: relative;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;

  > .bg {
    z-index: -1;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }

  > .mini-program-code-container {
    position: absolute;
    //top: 5.04rem * 2;
    top: 37vh;
    //left: 2.26rem * 2;
    left: 0;
    width: 100%;


    > .img-mini-program-code {
      display: block;
      //width: 100%;
      //height: 100%;
      width: 3.02rem * 2;
      height: 3.02rem * 2;
      margin: {
        left: 2.26rem * 2;
        right: 0;
      }
      border-radius: 50%;
    }

    > .text-tip {
      font-size: .22rem * 2;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: .49rem * 2;
      text-align: center;

    }
  }

  > .bottom-panel-container {
    position: absolute;
    width: 100%;
    height: (2.75rem + .98rem) * 2;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: .38rem * 2;

    > .btn-save-container {
      width: 100%;
      display: flex;
      justify-content: center;

      > .img-save {
        display: block;
        width: 2.74rem * 2;
        height: .98rem * 2;
      }
    }

    > .open-live-container {
      margin-top: .32rem * 2;
      display: flex;
      justify-content: center;
      align-items: center;

      @mixin text {
        font-size: .22rem * 2;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 1;
      }

      > .text {
        @include text;
      }

      > .text-link {
        margin-left: .17rem * 2;
        text-decoration: underline;
        color: #FD78FF;
      }
    }


    >.enter-personal-container {
      margin-top: auto;
      margin: {
        left: auto;
        right: auto;
      }
      position: relative;
      z-index: 0;

      > .img-shadow {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: -1;
        opacity: 0.4;
        pointer-events: none;
      }

      > .icon-arrow-up {
        display: block;
        width: .34rem * 2;
        height: .28rem * 2;
        margin: {
          left: auto;
          right: auto;
        }
      }

      > .text {
        margin-top: .20rem * 2;
        font-size: .24rem * 2;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
}