.index {
	margin-top: 33px;

	> .container {
		width: 686px;
		height: 171px;
		border-radius: 10px;

		margin-left: auto;
		margin-right: auto;

		background-image: url("../../images/home/猜你喜欢背景.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;

		box-sizing: border-box;

		> .titleWrapper {
			box-sizing: border-box;
			padding-left: 16px;
			padding-top: 15px;

			> .title {
				font-family: PingFang SC;
				font-weight: bold;
				font-size: 26px;
				color: #FFFFFF;
				line-height: 1;
			}
		}

		> .goodsList {
			display: flex;
			justify-content: space-between;

			margin-top: 14px;
			margin-left: 16px;
			margin-right: 16px;
			box-sizing: border-box;

			> .goods {
				width: 319px;
				height: 106px;
				background: #FFFFFF;
				border-radius: 10px;

				display: flex;

				> .left {
					box-sizing: border-box;
					padding-top: 4px;
					padding-left: 4px;

					> .imgWrapper {
						width: 98px;
						height: 98px;

						> img {
							width: 100%;
							height: 100%;
							border-radius: 10px;
						}
					}
				}

				> .right {
					box-sizing: border-box;
					padding-top: 15px;
					margin-left: 8px;

					> .name {
						min-height: 45px;

						font-family: PingFang SC;
						font-weight: bold;
						font-size: 20px;
						color: #343434;
						line-height: 26px;

						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box; //作为弹性伸缩盒子模型显示。
						-webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
						-webkit-line-clamp: 2; //显示的行

						> span {
							padding: 0 6px;
							height: 22px;

							margin-inline-end: 6px;

							font-family: PingFang SC;
							font-weight: 500;
							font-size: 18px;
							color: #FFFFFF;
							line-height: 22px;

							&.self {
								background-color: rgba(33, 114, 235, 1);
							}

							&.pdd, &.jd {
								background-color: rgba(230, 87, 118, 1);
							}
						}
					}

					> .priceWrapper {
						margin-top: 14px;

						> .price {
							font-family: Alibaba PuHuiTi;
							font-weight: 800;
							font-size: 20px;
							color: #E83B3B;
							line-height: 1;

							box-sizing: border-box;
							padding-right: 11px;
							text-align: right;
						}
					}
				}
			}
		}
	}
}