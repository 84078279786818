@import 'styles/common/flex.scss';

.my-recommend {
  &__title {
    font-size: 0.56rem;
    padding-left: 0.38rem;
    font-family: PingFangSC;
    font-weight: 600;
    color: rgba(49, 49, 49, 1);
    line-height: 0.4rem;
    @include flex-horizontal-start;
  }

  >.content {
    //@import 'styles/product/product-card.scss';
    margin-top: 0.6rem;
    padding-left: 0.44rem;
    display: flex;
    flex-wrap: wrap;

    // .product-card {
    //   // &:nth-child(odd) {
    //   //   margin-right: 0.28rem;
    //   // }
    //   float: left;
    //   margin-top: 0.28rem;
    // }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &__placeholder {
    height: 2rem;
  }
}
