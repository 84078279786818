.glx-open-live-process-page {
  position: relative;
  height: 100vh;
  z-index: 0;
  box-sizing: border-box;
  padding: {
    top: 1.58rem * 2;
  }

  > .bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  > .title-container {
    position: absolute;
    top: .65rem * 2;
    display: flex;
    align-items: center;
    padding: {
      left: .34rem * 2 - .4rem;
    }

    > .icon-close-wrapper {
      padding: .4rem;

      > .icon-close {
        display: block;
        width: .23rem * 2;
        height: .23rem * 2;
      }
    }

    > .text-title {
      margin-left: .34rem * 2 - .4rem;
      font-size: .36rem * 2;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  > .process-container {
    margin: {
      left: .28rem * 2;
      right: .38rem * 2;
    }
    box-sizing: border-box;

    > .process {
      position: relative;
      $padding-top: .48rem * 2;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: rgba(255, 255, 255, .4);
        width: .01rem * 2;
        height: 0.32rem + .62rem;
        top: .62rem + $padding-top;
        left: .35rem * 2;
      }

      &:first-child {
        &::before {
          top: .62rem;
        }
      }

      &:not(:first-child) {
        padding-top: $padding-top;

        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: rgba(255, 255, 255, .4);
          width: .01rem * 2;
          height: $padding-top;
          top: 0;
          left: .35rem * 2;
        }
      }

      &:nth-child(3) {
        > .content-container {
          > .content {
            align-self: center;

            > .text {
              padding: unset;
            }
          }
        }
      }

      > .title-container {
        display: flex;

        > .icon-step {
          display: block;
          width: .62rem * 2;
          height: .62rem * 2;
          margin-left: .04rem *2;
        }

        > .title {
          margin-left: .20rem * 2;
          font-size: .32rem * 2;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 1;
          align-self: flex-end;
          padding: {
            bottom: .10rem * 2;
          }
        }
      }

      > .content-container {
        margin: {
          top: .16rem * 2;
        }
        display: flex;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          background-color: rgba(255, 255, 255, .4);
          width: .01rem * 2;
          left: .35rem * 2;
          top: .70rem * 2;
          bottom: 0;
        }

        > .icon-process {
          width: .70rem * 2;
          height: .70rem * 2;
          display: block;
        }

        > .content {
          flex-grow: 1;

          > .text {
            font-size: .24rem * 2;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: .32rem * 2;
            margin: {
              left: .26rem * 2;
              right: .09rem * 2;
            }
            box-sizing: border-box;
            padding: {
              bottom: .13rem * 2;
            }
          }

          > .img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}