.glx-news-info {
  $head-height: 1.74rem;
  box-sizing: border-box;
  padding: {
    top: $head-height;
  }
  background: rgba(249, 249, 249, 1);

  > .head {
    position: fixed;
    width: 100%;
    height: $head-height;
    top: 0;
    left: 0;

    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0.02rem 0.16rem 0 rgba(21, 21, 21, 0.08);

    // display: flex;
    // align-items: center;

    > .back-block {
      position: absolute;
      padding: {
        top: 0.6rem;
        bottom: 0.6rem;
        left: 0.4rem;
        right: 0.4rem;
      }

      > .back-img {
        display: block;
        width: 14px;
        height: 25px;
      }
    }

    > .title {
      height: 100%;
      line-height: $head-height;
      text-align: center;
      font-size: 0.64rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(49, 49, 49, 1);
    }
  }

  > .info {
    min-height: 80vh;
    box-sizing: border-box;
    padding: {
      top: 0.4rem;
      bottom: 0.4rem;
      left: 0.4rem;
      right: 0.48rem;
    }
    margin: {
      top: 0.24rem;
    }
    background: rgba(255, 255, 255, 1);

    > .title-floor {
      font-size: 0.56rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(21, 21, 21, 1);
      line-height: 0.4rem;
    }

    > .content {
      margin: {
        top: 0.8rem;
      }
      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(67, 67, 67, 1);
      line-height: 0.72rem;
    }
  }

  > .recommend-content {
    margin: {
      top: 0.24rem;
    }
    > .title-floor {
      display: flex;
      align-items: center;
      height: 1.52rem;
      line-height: 1.52rem;
      box-sizing: border-box;
      padding: {
        left: 0.4rem;
      }
      background: rgba(255, 255, 255, 1);
      > .line {
        width: 0.08rem;
        height: 0.36rem;
        background: rgba(240, 38, 38, 1);
      }
      > .title {
        margin: {
          left: 0.4rem;
        }
        font-size: 0.52rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(21, 21, 21, 1);
        line-height: 0.72rem;
      }
    }

    > .list {
      > .floor {
        display: flex;
        position: relative;
        box-sizing: border-box;
        padding: {
          left: 0.4rem;
          right: 0.4rem;
        }

        background: rgba(255, 255, 255, 1);
        &:not(:first-child) {
          ::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;

            left: 0.4rem;
            right: 0.4rem;
            height: 0.04rem;
            border-top: 0.04rem solid rgba(243, 243, 243, 1);
          }
        }

        > .info-block {
          padding: {
            top: 0.94rem;
            right: 1.2rem;
          }
          box-sizing: border-box;
          flex-grow: 1;
          > .title {
            font-size: 0.52rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: rgba(21, 21, 21, 1);
            line-height: 0.72rem;
          }
        }

        > .img-block {
          flex-shrink: 0;
          width: 4.3rem;
          height: 2.96rem;
          border-radius: 0.2rem;
          display: flex;
          justify-content: center;

          > .img {
            height: 100%;
            background-color: rgba(151, 151, 151, 1);
          }
        }
      }
    }
  }
}
