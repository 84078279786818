.glx-merchant-company {
  min-height: 100vh;
  box-sizing: border-box;
  padding: {
    bottom: 0.4rem;
  }
  display: flex;
  flex-direction: column;

  > .content {
    border-radius: 0.2rem;
    > .title-floor {
      height: 2.48rem;

      padding: {
        left: 0.4rem;
        top: 0.58rem;
      }

      background-color: rgba(255, 255, 255, 1);

      > .title {
        font-size: 0.64rem;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(56, 56, 56, 1);
        line-height: 1;
      }

      > .sub-title {
        margin: {
          top: 0.46rem;
        }
        font-size: 0.44rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(56, 56, 56, 1);
        line-height: 1;
      }
    }
    > .container {
      background: rgba(250, 251, 253, 1);

      > .am-list-item {
        > .am-list-line {
          > .am-list-content {
            .label {
              display: inline-block;
              width: 85px;
            }
            > .am-radio-wrapper {
              margin-right: 2.28rem;
              > .am-radio {
                padding: 2.5px;
                border: 1px solid #ccc;
                border-radius: 50%;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  > .bottom-btn {
    margin: {
      top: auto;
    }
  }
}
