@import 'styles/common/flex.scss';

.shop-title {
  width: 13.4rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.1rem;

  padding: 0.56rem 0.4rem;

  @include flex-horizontal-between;

  &__info {
    @include flex;
  }

  .img-block {
    width: 2.62rem;
    height: 1.08rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: 0.1rem;
    display: flex;
    justify-content: center;
    overflow: hidden;
    > .img {
      height: 100%;
    }
  }

  &__text {
    @include flex-vertical-start-align-start;
    margin-left: 0.34rem;
  }

  &__title {
    font-size: 0.48rem;
    font-family: PingFangSC;
    font-weight: 500;
    color: rgba(24, 24, 24, 1);
    line-height: 0.64rem;
  }

  &__followers {
    font-size: 0.4rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(148, 148, 148, 1);
    line-height: 0.64rem;
  }

  &__ope {
    height: 0.7rem;
    background: rgba(225, 25, 72, 1);
    border-radius: 0.5rem;
    font-size: 0.4rem;
    font-family: PingFang SC;
    color: rgba(255, 255, 255, 1);
    line-height: 0.7rem;
    padding: {
      left: 0.5rem;
      right: 0.5rem;
    }

    @include flex-center;
  }
}
