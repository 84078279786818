@import 'styles/common/flex.scss';

.category-section {
  &__title {
    font-size: 0.48rem;
    font-family: PingFangSC;
    font-weight: 700;
    color: rgba(24, 24, 24, 1);
  }

  &__items {
  }

  &__item {
    @include flex-vertical-center;

    float: left;
    width: 3.4rem;
    margin-top: 0.6rem;

    &:last-child::after {
      content: '';
      display: block;
      clear: both;
    }

    .img-block {
      position: relative;
      width: 2.42rem;
      height: 2.22rem;
      z-index: 10;
      //   line-height: 2.22rem;
      display: flex;
      align-items: center;
      > .bg-img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -10;
      }

      > .name {
        // height: 100%;
        padding: {
          left: 0.4rem;
        }
        text-align: center;
        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        // line-height: 0.36rem;
        text-shadow: 0 0.02rem 0 rgba(2, 2, 2, 0.35);
      }
    }
  }

  &__img {
    display: block;
    width: 2.08rem;
    height: 2.48rem;
    background-color: #c5c5c5;
  }

  &__name {
    margin-top: 0.46rem;
    font-size: 0.44rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(24, 24, 24, 1);
    line-height: 0.4rem;
  }
}
