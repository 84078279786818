.glx-merchant-shop {
  box-sizing: border-box;
  padding: {
    top: 0.66rem;
    bottom: 0.4rem;
  }
  > .content {
    border-radius: 0.2rem;
    > .title-floor {
      height: 2.48rem;

      padding: {
        left: 0.4rem;
        top: 0.58rem;
      }

      background-color: rgba(255, 255, 255, 1);

      > .title {
        font-size: 0.64rem;
        font-family: PingFang SC;
        font-weight: 800;
        color: rgba(56, 56, 56, 1);
        line-height: 1;
      }

      > .sub-title {
        margin: {
          top: 0.46rem;
        }
        font-size: 0.44rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(56, 56, 56, 1);
        line-height: 1;
      }
    }
    > .container {
      background: rgba(250, 251, 253, 1);

      //   > .am-list-item {
      //     > .am-list-line {
      //       > .am-list-content {
      //         .label {
      //           display: inline-block;
      //         //   width: 85px;
      //         }

      //       }
      //     }
      //   }

      > .row {
        display: flex;
        background-color: #fff;
        padding: {
          left: 15px;
        }

        > .label {
          font-size: 0.48rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(24, 23, 23, 1);
          line-height: 0.72rem;
          display: inline-block;
          flex-shrink: 0;

          &.experience {
            margin: {
              right: 2rem;
            }
          }

          &.open-sale-qualification {
            margin: {
              right: 1.8rem;
            }
          }
        }

        > .am-radio-wrapper {
          > .am-radio {
            padding: 2.5px;
            border: 1px solid #ccc;
            border-radius: 50%;
            margin-right: 5px;

            > .am-radio-inner {
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }

          &.experience {
            &:not(:last-child) {
              margin: {
                right: 1.28rem;
              }
            }
          }
          &.open-sale-qualification {
            &:not(:last-child) {
              margin: {
                right: 2.2rem;
              }
            }
          }
        }

        > .tip {
          font-size: 0.4rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(187, 187, 187, 1);
          line-height: 0.72rem;
        }
      }
    }
  }
}
