.glx-upload-img-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @mixin item {
    width: 2.8rem;
    height: 2.8rem;
    background: rgba(246, 246, 246, 1);
    border: 0.02rem solid rgba(229, 229, 229, 1);
    border-radius: 0.2rem;

    margin-bottom: 0.4rem;
    &:not(:last-child) {
      margin-right: 0.6rem;
    }
  }

  > .img-item {
    @include item();
    > .img-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      > .img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .img-handle {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        transition: all 0.3s;

        .delete-img {
          width: 1.2rem;
          height: 1.2rem;

          display: block;
        }
      }

      &::before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 1;
        transition: all 0.3s;
        content: ' ';
      }
    }
  }

  > .upload-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3.06rem;
    height: 1rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0.18rem 3.8rem 0.04rem rgba(18, 76, 155, 0.13);
    border-radius: 0.5rem;

    font-size: 0.48rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(25, 104, 234, 1);
    line-height: 0.72rem;

    &.hidden {
      visibility: hidden;
    }

    > .upload {
      display: none;
    }
  }
}
