@import 'styles/common/flex.scss';
@import 'styles/my.scss';

.my-orders {
background-color: rgba(249, 249, 249, 1);
}

.my-orders-title {
  width: calc(100% - 0.52rem * 2);
  height: 1.36rem;
  padding: 0 0.52rem;
  border-bottom: 0.04rem #fbf8fb solid;
  @include flex-horizontal-between;

  &__text {
    font-size: 0.52rem;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
  }
}

.my-orders-entries {
  height: 3.76rem;
  //width: calc(100% - 1rem * 2);
  margin: {
    left: .2rem * 2;
    right: .2rem * 2;
  }
  padding: 0 1rem;
  background-color: #FFFFFF;
  border-radius: 12px;
  @include flex-horizontal-between;
}

.my-entry {
  @include flex-vertical-center;

  img {
    width: 0.88rem;
    height: 0.88rem;
  }

  &__img {
  }

  &__text {
    color: #5d5b5b;

    &,
    &--guest {
      margin-top: 0.44rem;

      font-size: 0.44rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 0.4rem;
    }

    &--guest {
      color: rgba(175, 175, 175, 1);
    }
  }
}
