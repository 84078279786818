@import 'styles/common/flex.scss';

.modify-order {
  width: 100vw;
  min-height: 100vh;
  background: rgba(249, 249, 249, 1);
  .modify-order-top {
    margin-bottom: 0.48rem;
  }
}

.modify-order-top {
  @include flex-horizontal-center;
  width: 15rem;
  height: 2.1rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 1px 0 5px 1px rgba(240, 240, 240, 0.9);
  &__back-icon {
    position: absolute;
    left: 0.48rem;
    top: 0.7rem;
    width: 0.38rem;
    height: 0.68rem;
  }

  &__title {
    font-size: 0.68rem;
    font-weight: bold;
    color: rgba(37, 37, 37, 1);
  }
}

.modify-order-body {
  padding-left: 0.46rem;
  width: calc(100vw - 0.46rem);
  height: 6.96rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 0rem 0rem rgba(104, 21, 29, 0.04);
  border-radius: 0rem;
  @include flex-vertical-start-align-start;
  &__title {
    font-size: 0.52rem;
  }
  p {
    @include flex-horizontal-start;
    height: 1.74rem;
    span:first-child {
      width: 3.04rem;
    }
    span:last-child {
      font-weight: bold;

      a {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        max-width: 9.32rem;
      }
    }
    font-size: 0.46rem;
    color: rgba(37, 37, 37, 1);
  }

  &__address-icon {
    position: absolute;
    right: 0.88rem;
    img {
      width: 0.12rem;
      height: 0.32rem;
    }
  }
}

.modify-order-confirm {
  width: 13.72rem;
  height: 1.72rem;
  background: linear-gradient(90deg, rgba(245, 4, 4, 1), rgba(248, 94, 53, 1));
  box-shadow: 0rem 0rem 1rem 0rem rgba(235, 9, 8, 0.18);
  border-radius: 1rem;
  color: white;
  @include flex-horizontal-center;
  margin: auto;
  margin-top: 2.18rem;
}
