.horizontal-list {
  min-width: 100%;
  overflow: hidden;
  &-wrapper {
    height: 100%;
    width: auto;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 1rem;
  }
}
