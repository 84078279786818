.glx-back-icon {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  height: 100%;
  padding: {
    left: .48rem;
    right: .48rem;
  }
  display: flex;
  align-items: center;

  > .icon {
    width: .4rem;
    height: .68rem;
  }
}