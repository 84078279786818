@mixin flex {
  display: flex;
}

@mixin flex-vertical {
  @include flex();
  flex-direction: column;
}

@mixin flex-horizontal {
  @include flex();
  flex-direction: row;
}

@mixin flex-center {
  @include flex();
  justify-content: center;
  align-items: center;
}

@mixin flex-start {
  @include flex();
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-evenly {
  @include flex();
  justify-content: space-evenly;
  align-items: center;
}

@mixin flex-between {
  @include flex();
  justify-content: space-between;
  align-items: center;
}

@mixin flex-end {
  @include flex();
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-align-start {
  @include flex();
  align-items: flex-start;
}

@mixin flex-align-center {
  @include flex();
  align-items: center;
}

@mixin flex-vertical-between {
  @include flex-vertical();
  @include flex-between();
}

@mixin flex-vertical-center {
  @include flex-vertical();
  @include flex-center;
}

@mixin flex-vertical-evenly {
  @include flex();
  @include flex-vertical();
  @include flex-evenly();
}

@mixin flex-vertical-start {
  @include flex();
  @include flex-vertical();
  @include flex-start();
}

@mixin flex-vertical-start-align-start {
  @include flex-vertical();
  @include flex-start;
  @include flex-align-start();
}

@mixin flex-horizontal-center {
  @include flex-horizontal();
  @include flex-center;
}

@mixin flex-horizontal-evenly {
  @include flex-horizontal();
  @include flex-evenly();
}

@mixin flex-horizontal-start {
  @include flex-horizontal();
  @include flex-start();
}

@mixin flex-horizontal-between {
  @include flex-horizontal();
  @include flex-between();
}

@mixin flex-horizontal-end {
  @include flex-horizontal();
  @include flex-end();
}
