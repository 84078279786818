.glx-merchant-wait {
  min-height: 100vh;
  box-sizing: border-box;
  padding: {
    bottom: 0.4rem;
  }
  display: flex;
  flex-direction: column;

  > .img-block {
    margin: {
      left: 1.14rem;
      right: 1.8rem;
    }

    > .img {
      width: 100%;
    }
  }

  > .status-floor {
    > .title {
      margin: {
        top: 1rem;
      }
      text-align: center;
      font-size: 0.96rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(19, 102, 239, 1);
      line-height: 1;
    }
    > .sub-title {
      margin: {
        top: 0.3rem;
      }
      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(68, 128, 226, 1);
      line-height: 1;
      text-align: center;
    }
    > .thanks {
      margin: {
        top: 0.56rem;
      }
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(129, 127, 127, 1);
      line-height: 1;
      text-align: center;
    }
  }

  > .card {
    margin: {
      left: 0.8rem;
      right: 0.8rem;
    }
    > .floor {
      > :not(:first-child) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0.4rem;
          right: 0.4rem;
          border-top: 1px solid rgba(230, 230, 230, 1);
        }
      }
      height: 1.94rem;
      display: flex;
      align-items: center;

      > .label {
        padding: {
          left: 0.74rem;

          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(138, 138, 138, 1);
          line-height: 0.72rem;
        }
      }

      > .text {
        margin: {
          left: 1.1rem;
        }
        padding: {
          right: 1.3rem;
        }
        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(21, 165, 239, 1);
        line-height: 0.72rem;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  > .bottom-btn {
    margin: {
      top: auto;
    }
  }
}
