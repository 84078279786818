.glx-live-room__gift-panel {
  width: 100%;
  height: 100%;
  background-color: rgba(21, 23, 35, 0.94);
  border-radius: .14rem * 2 .14rem * 2 0 0;
  position: relative;
  box-sizing: border-box;
  padding: {
    top: .33rem * 2;
    bottom: .55rem * 2;
  }

  > .close-container {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    padding: {
      top: .31rem * 2;
      bottom: .31rem * 2;
      right: .25rem * 2;
      left: .25rem * 2;
    }

    > .icon {
      display: block;
      width: .24rem * 2;
      height: .24rem * 2;
    }
  }

  > .title-container {
    box-sizing: border-box;
    padding: {
      left: .39rem * 2;
    }
    font-size: .22rem * 2;
    font-family: PingFang SC;
    font-weight: 500;
    color: #E8CA3F;
  }

  > .gift-container {
    margin: {
      top: .59rem * 2;
    }
    box-sizing: border-box;
    padding: {
      left: .24rem * 2;
      right: .24rem * 2;
    }

    display: flex;
    flex-wrap: wrap;

    > .item {
      $width: 1.60rem * 2;
      width: $width;
      height: 2rem * 2;
      box-shadow: 0 .12em .68rem .02rem rgba(0, 0, 0, 0.15);
      border-radius: .12rem * 2;
      box-sizing: border-box;

      &:not(:first-child) {
        margin: {
          left: calc((100% - #{$width * 4}) / 3);
        }
      }

      &:nth-child(n + 5) {
        margin: {
          top: .24rem * 2;
        }
      }

      &.active {
        background-color: rgba(39, 40, 53, 1);
      }

      > .icon-wrapper {
        display: flex;
        width: 100%;
        height: 1.20rem * 2;
        position: relative;
        z-index: 0;
        overflow: hidden;

        > .icon {
          position: absolute;
          z-index: -10;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          max-width: 100%;
          max-height: 100%;
        }
      }

      > .name {
        margin: {
          top: .06rem * 2;
        }
        font-size: .18rem * 2;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 1;
      }

      > .free {
        margin: {
          top: .06rem *2;
        }
        font-size: .16rem * 2;
        font-family: PingFang SC;
        font-weight: 500;
        color: #636375;
        text-align: center;
        line-height: 1;
      }

      > .send-btn {
        display: none;

        width: 1.47rem * 2;
        height: .38rem * 2;
        line-height: .38rem * 2;
        background: linear-gradient(90deg, #F97934, #F42635);
        border-radius: .19rem * 2;
        margin: {
          top: .08rem * 2;
        }

        font-size: .22rem * 2;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
      }

      &.active {
        > .name {
          display: none;
        }

        > .send-btn {
          display: block;
        }
      }
    }
  }

}