.glx-live-room-page {
  height: 100vh;

  /* 兼容不支持 var 变量的浏览器 (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  /* 修正后的 100vh */
  > .player-container {
    height: 100%;
    position: relative;
    z-index: 0;

    > .player {
      height: 100%;
      z-index: 0;

      > .dplayer-video-wrap {
        > .dplayer-video {
          object-fit: fill
        }
      }

      > .dplayer-controller-mask {
        display: none;
      }

      > .dplayer-controller {
        display: none;
      }

      > .dplayer-mobile-play {
        display: none;
      }
    }

    > .player-test {
      position: absolute;
      z-index: 10;
      width: 20%;
      height: 20%;
      background-color: #ff0000;
      top: 0;
      left: 0;
    }

    > .play-panel-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;

      .play-icon-wrapper {
        position: absolute;
        z-index: 10;
        width: .86rem * 2;
        height: .76rem * 2;
        top: 45%;
        left: 0;
        right: 0;
        margin: {
          left: auto;
          right: auto;
        }

        > .play-icon {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    > .playing-panel-container {
      position: absolute;
      z-index: 20;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}