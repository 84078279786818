.glx-merchant-head {
  height: 1.84rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  > .back {
    padding: {
      left: 0.4rem;
      right: 0.4rem;
      top: 0.4rem;
      bottom: 0.4rem;
    }
    > .img {
      display: block;
      width: 0.28rem;
      height: 0.5rem;
    }
  }
}
