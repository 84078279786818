.tab {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.08rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.02rem 0.16rem 0rem rgba(21, 21, 21, 0.08);

  > .tab-bar-floor {
    height: 1.32rem;
    display: flex;
    justify-content: center;
    position: relative;

    > .back {
      display: block;
      padding-top: 0.64rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      width: 0.3rem;
      height: 0.5rem;

      position: absolute;
      left: 0;
      top: 0;

      background-image: url('../../../images/goods-collect/back.png');
      background-size: 0.3rem 0.5rem;
      background-position: bottom center;
      background-repeat: no-repeat;
    }

    > .item {
      height: 100%;
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: 1.28rem;
      }
      &.active {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;

          height: 0.08rem;
          //   background: linear-gradient(
          //     0deg,
          //     rgba(242, 41, 107, 0.41),
          //     rgba(237, 38, 23, 0.41)
          //   );

          background: rgba(240, 55, 81, 1);
          border-radius: 0.04rem;
        }
      }
      > .text {
        font-size: 0.56rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(49, 49, 49, 1);
        line-height: 0.4rem;
        display: block;
      }
    }
  }

  > .condition-floor {
    margin-top: 0.4rem;
    height: 1.28rem;
    padding-left: 4.06rem;
    box-sizing: border-box;

    display: flex;
    align-items: center;

    > .item {
      &:not(:first-child) {
        margin-left: 0.96rem;
      }

      &.active {
        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(49, 49, 49, 1);
        line-height: 0.4rem;
        > .text {
          color: rgba(241, 49, 92, 1);
        }
      }
    }
  }
}
