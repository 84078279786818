.input-floor {
  display: flex;
  align-items: center;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  > .back {
    display: block;
    width: 0.28rem;
    height: 0.5rem;
  }
  > .search-input {
    margin-left: 0.4rem;
  }
  > .list-type {
    margin-left: auto;

    display: block;
    width: 0.64rem;
    height: 0.66rem;
  }
}
