.index {
	margin-top: 61px;

	> .container {
		box-sizing: border-box;
		padding-left: 32px;
		padding-right: 32px;

		> .menuList {
			display: flex;
			align-items: center;
			justify-content: space-between;

			> .menu {

				> .imgWrapper {
					width: 111px;
					height: 56px;
					background: #D9EB4F;
					border-radius: 28px;
					position: relative;
					z-index: 0;

					> img {
						display: block;
						position: absolute;
						z-index: 10;

						&.self {
							width: 89px;
							height: 65px;
							left: 12px;
							top: -4px;
						}

						&.secKill {
							width: 62px;
							height: 87px;
							left: 24px;
							top: -23px;
						}

						&.shopStreet {
							width: 60px;
							height: 94px;
							left: 28px;
							top: -29px;
						}

						&.news {
							width: 98px;
							height: 66px;
							left: 5px;
							top: -4px;
						}

						&.merchant {
							width: 61px;
							height: 87px;
							left: 25px;
							top: -24px;
						}
					}
				}

				> .name {
					font-family: PingFang SC;
					font-weight: bold;
					font-size: 26px;
					color: #787878;
					line-height: 1;

					margin-top: 16px;
				}
			}
		}
	}
}