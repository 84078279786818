.glx-merchant-add-license-upload-img {
  display: table;
  //   width: 2.08rem;
  //   height: 2.08rem;
  text-align: center;
  cursor: pointer;
//   transition: border-color 0.3s ease;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.28rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    // border-color: #1890ff;
  }
  > .file {
    display: none;
  }

  > .upload-button {
    // background-color: #fafafa;
    // border: 1px dashed #d9d9d9;
    // border-radius: 0.08rem;
    // width: 100%;
    // height: 100%;

    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    > .text {
      color: #666;
      display: block;
      text-align: center;
    }
  }

  > .img {
    width: 100%;
  }
}
