.glx-confirm-goods {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: {
    top: 3rem;
  }

  > .card {
    margin: {
      left: 1.06rem;
      right: 1.06rem;
    }
    // height: 11.8rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0.04rem 0.5rem 0.04rem rgba(2, 12, 25, 0.18);
    border-radius: 0.2rem;
    position: relative;

    > .bg {
      position: absolute;
      z-index: -1000;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      background-image: url('./img/card-bg.png');
      background-size: 100% 100%;
    }

    > .title-floor {
      height: 1.92rem;
      line-height: 1.92rem;
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0.56rem;
        right: 0.56rem;
        height: 0.02rem;
        border: 0.02rem solid rgba(238, 238, 238, 1);
      }

      > .title {
        display: block;
        font-size: 0.64rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(50, 50, 50, 1);
        line-height: 0.4rem;
      }
    }
    > .content {
      height: 9.88rem;
      padding: {
        left: 0.56rem;
        right: 0.86rem;
      }
      overflow-y: scroll;

      /*控制整个滚动条*/
      ::-webkit-scrollbar {
        background-color: transparent;
        width: 0.06rem;
        background-clip: padding-box;
      }

      /*滚动条两端方向按钮*/
      ::-webkit-scrollbar-button {
        // background-color: rgba(19, 238, 179, 1);
        background: linear-gradient(
          0deg,
          rgba(25, 125, 237, 1),
          rgba(40, 190, 243, 1)
        );
      }

      /*滚动条中间滑动部分*/
      ::-webkit-scrollbar-thumb {
        // background-color: rgba(19, 238, 179, 1);
        background: linear-gradient(
          0deg,
          rgba(25, 125, 237, 1),
          rgba(40, 190, 243, 1)
        );
        border-radius: 0.1rem;
      }

      /*滚动条右下角区域*/
      ::-webkit-scrollbar-corner {
        // background-color: rgba(19, 238, 179, 1);
        background: linear-gradient(
          0deg,
          rgba(25, 125, 237, 1),
          rgba(40, 190, 243, 1)
        );
      }
      > .item {
        display: flex;
        align-items: center;
        padding: {
          top: 0.36rem;
          bottom: 0.36rem;
        }

        &:not(:first-child) {
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            height: 0.02rem;
            border-top: 0.02rem solid rgba(238, 238, 238, 0.4);
          }
        }

        > .img-block {
          width: 2.76rem;
          height: 2.76rem;
          border-radius: 0.2rem;
          display: flex;
          justify-content: center;
          overflow: hidden;
          flex-shrink: 0;
          > .img {
            display: block;
            height: 100%;
          }
        }

        > .info-block {
          align-self: stretch;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow: hidden;
          margin: {
            left: 0.48rem;
          }
          > .name-floor {
            font-size: 0.48rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: rgba(24, 24, 24, 1);
            line-height: 1;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > .specification-floor {
            margin-top: 0.26rem;
            display: flex;

            > .specification {
              display: inline-block;
              padding: {
                top: 0.12rem;
                bottom: 0.12rem;
                left: 0.24rem;
                right: 0.24rem;
              }

              background: rgba(238, 238, 238, 1);
              border-radius: 0.08rem;
              font-size: 0.4rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: rgba(172, 172, 172, 1);
              line-height: 1;
            }
          }
          > .price-floor {
            display: flex;
            margin-top: auto;

            > .current-price {
              font-size: 0.44rem;
              font-family: PingFang SC;
              font-weight: bold;
              color: rgba(239, 29, 59, 1);
              line-height: 0.82rem;
            }

            > .count {
              margin: {
                left: auto;
              }
              font-size: 0.4rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: rgba(188, 188, 188, 1);
              line-height: 0.84rem;
            }
          }
        }
      }
    }
  }

  > .contact-block {
    height: 4.06rem;
    margin: {
      left: 1.48rem;
      right: 1.48rem;
    }

    box-sizing: border-box;
    padding: {
      top: 0.68rem;
      left: 0.48rem;
      right: 0.48rem;
    }

    background: rgba(241, 242, 248, 0.96);
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;

    > .user-floor {
      font-size: 0.4rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(54, 54, 54, 1);
      line-height: 0.5rem;
    }

    > .phone-floor {
      display: flex;
      align-items: center;
      margin: {
        top: 0.64rem;
      }

      > .phone-icon {
        margin: {
          left: 1.8rem;
        }
        display: block;
        width: 1.48rem;
        height: 1.42rem;
      }
      > .phone {
        position: relative;
        width: 5.03rem;
        height: 1rem;
        line-height: 1rem;
        margin: {
          left: 0.44rem;
        }
        padding: {
          left: 0.76rem;
        }
        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        z-index: 10;
        > .bg {
          position: absolute;
          z-index: inherit;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('./img/phone-bg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  > .button-block {
    margin-top: 0.96rem;
    > .call-btn {
      width: 5.4rem;
      height: 1.34rem;
      background: rgba(238, 56, 103, 1);
      border-radius: 0.68rem;
      display: block;
      margin: {
        left: auto;
        right: auto;
      }

      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      line-height: 1.34rem;
    }
    > .cancel-btn {
      width: 5.4rem;
      height: 1.34rem;
      background: transparent;
      border-radius: 0.68rem;
      display: block;
      margin: {
        left: auto;
        right: auto;
      }

      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      line-height: 1.34rem;
    }
  }
}
