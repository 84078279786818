@import 'styles/common/flex.scss';

.order-contact {
  @include flex-horizontal-center;
  font-size: 0.48rem;

  font-weight: bold;
  color: rgba(40, 40, 40, 1);

  &__icon {
    width: 0.44rem;
    height: 0.46rem;
    margin-right: 0.2rem;
  }
}
