@import "var";

.goodsListPanel {
  position: absolute;
  z-index: $info-z-index + 10;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  > .card {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 516px;

    background: #FFFFFF;
    border-radius: 24px 24px 0px 0px;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    > .titleFloor {
      height: 98px;

      box-sizing: border-box;
      padding-left: 20px;
      padding-top: 39px;
      flex-shrink: 0;

      > .text {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 28px;
        color: #050505;
        line-height: 1;
      }
    }

    > .goodsList {
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px;
      flex-grow: 1;
      overflow-y: scroll;

      > .goods {
        height: 198px;
        border-radius: 10px;

        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-top: 32px - 8px;
        }

        &:hover {
          cursor: pointer;
        }

        > .imgWrapper {
          width: 182px;
          height: 182px;
          position: relative;
          flex-shrink: 0;

          > .img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }

          > .seqWrapper {
            width: 32px;
            height: 32px;
            background: rgba(4, 4, 4, 0.4);
            border-radius: 12px 0px 12px 0px;

            position: absolute;
            top: 0;
            left: 0;

            > .seq {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 22px;
              color: #FFFFFF;
              line-height: 32px;
              text-align: center;
            }
          }

          > .speakingWrapper {
            position: absolute;

            left: 0;
            right: 0;
            bottom: 0;
            height: 34px;
            background: rgba(241, 54, 97, 0.5);
            border-radius: 0px 0px 12px 12px;

            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 37px;

            > .icon {
              display: block;
              width: 19px;
              height: 17px;
            }

            > .text {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 20px;
              color: #FFFFFF;
              line-height: 1;

              margin-left: 6px;
            }
          }
        }

        > .right {
          flex-grow: 1;
          align-self: stretch;

          position: relative;

          > .name {
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 24px;
            color: #000000;
            line-height: 42px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box; //作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
            -webkit-line-clamp: 2; //显示的行

            margin-left: 19px;
          }

          > .tags {
            margin-top: 11px;
            margin-left: 19px;
            display: flex;

            > .item {
              width: 108px;
              height: 28px;
              background: rgba(249, 249, 250, 0.8);
              border-radius: 2px;

              box-sizing: border-box;
              padding-left: 6px;
              padding-right: 7px;

              &:not(:first-child) {
                margin-left: 6px;
              }

              > span {
                font-family: PingFang SC;
                font-weight: bold;
                font-size: 18px;
                color: #7B797A;
                line-height: 28px;
              }
            }
          }

          > .footer {
            position: absolute;
            bottom: 7px;
            left: 0;
            right: 0;
            display: flex;
            align-items: flex-end;

            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 9px;

            > .price {
              font-family: PingFang SC;
              font-weight: bold;
              font-size: 18px;
              color: #EF3156;
              line-height: 42px;
            }

            > .oldPrice {
              font-family: PingFang SC;
              font-weight: bold;
              font-size: 20px;
              color: #9D9D9D;
              line-height: 42px;
              text-decoration-line: line-through;

              margin-left: 18px;
            }

            > .btnToBuy {
              width: 140px;
              height: 48px;
              background: linear-gradient(270deg, #F7498A, #EF3054);
              border-radius: 24px;

              font-family: PingFang SC;
              font-weight: bold;
              font-size: 24px;
              color: #FFFFFF;
              line-height: 48px;

              text-align: center;

              margin-left: auto;

              &.disable {
                background: gray;
              }
            }
          }
        }

        &.active {
          background: #FFF3F5;
        }
      }
    }
  }
}