@import 'styles/common/flex.scss';

.pay-success {
  position: relative;

  width: 100vw;
  height: 100vh;

  &__bg {
    width: 14.2rem;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0.64rem;
    z-index: -1;
  }

  &__bg-color {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -2;
    background-color: #f7f7f7;
  }

  &__back {
    width: 0.28rem;
    height: 0.5rem;
    position: absolute;
    left: 0.4rem;
    top: 0.54rem;
  }

  .recommend-wrapper {
    //margin-top: 2rem;
  }
}

.pay-success-status {
  padding-top: 4.32rem;

  & > div {
    @include flex-horizontal-center;
  }

  &__status {
    font-size: 0.6rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 0.4rem;
  }

  &__price {
    margin-top: 0.52rem;

    font-size: 0.72rem;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 0.52rem;

    .glx-price {
      &__int {
        font-size: 1.24rem;
      }
    }
  }

  &__instr {
    margin-top: 0.56rem;

    font-size: 0.44rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 0.4rem;
    opacity: 0.8;
  }
}

.pay-success-goods {
  $padding-horizontal: 0.66rem;
  $padding-vertical: 0.84rem;
  margin: auto;
  margin-top: 2.1rem;
  width: calc(14.2rem - #{$padding-horizontal} * 2);
  height: calc(8.08rem - #{$padding-vertical} * 2);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0rem 0rem 0rem rgba(21, 21, 21, 0.11);
  border-radius: 0.3rem;

  padding: $padding-vertical $padding-horizontal;
  @include flex-vertical-start-align-start;

  position: relative;

  &__title {
    font-size: 0.48rem;
    font-weight: bold;
    color: rgba(31, 31, 31, 1);
    line-height: 0.4rem;
  }

  &__info {
    margin-top: 0.48rem;

    height: 2.4rem;
    @include flex-horizontal-start;
  }

  &__img {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.3rem;
  }

  &__detail {
    margin-left: 0.48rem;
  }

  &__name {
  }

  &__spec {
  }

  &__price {
    font-size: 0.36rem;
    font-weight: 500;
    color: rgba(239, 29, 59, 1);
    line-height: 0.64rem;
  }

  &__num {
    position: absolute;
    right: 0.88rem;
    bottom: 4.38rem;
  }

  .divider {
    width: 13.56rem;
    height: 1px;
    background: rgba(242, 242, 242, 1);
    content: '';
    margin: 0.84rem 0;
  }

  &__order-id,
  &__order-time {
    font-size: 0.48rem;
    font-weight: bold;
    color: rgba(31, 31, 31, 1);
    line-height: 0.64rem;
  }

  &__order-time {
    margin-top: 0.46rem;
  }
}

.pay-success-ope {
  @include flex-horizontal-center;

  margin-top: 1.01rem;

  & > div {
    width: 5.96rem;
    height: 1.48rem;
    border-radius: 1rem;
    font-size: 0.52rem;
    font-weight: bold;
    line-height: 0.64rem;
    @include flex-horizontal-center;
  }

  &__home {
    border: 1px solid rgba(249, 65, 22, 1);
    color: rgba(249, 65, 22, 1);
  }

  &__order {
    background: linear-gradient(
                    90deg,
                    rgba(249, 65, 22, 1),
                    rgba(254, 55, 92, 1)
    );
    font-weight: bold;
    color: rgba(255, 255, 255, 1);

    margin-left: 0.76rem;
  }
}

