.glx-live-room__playing-panel {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  box-sizing: border-box;
  padding: {
    top: .46rem * 2;
  }

  > .top-container {
    margin: {
      bottom: auto;
    }
    box-sizing: border-box;
    padding: {
      left: .24rem * 2;
    }
    display: flex;
    align-items: center;

    > .actor-info-container {
      width: 2.73rem * 2;
      height: .64rem * 2;
      background-color: rgba(75, 75, 82, 0.5);
      border-radius: .32rem * 2;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      box-sizing: border-box;
      padding: {
        left: .09rem * 2;
      }

      > .avatar {
        display: block;
        width: .62rem * 2;
        height: .62rem * 2;
        border-radius: 50%;
      }

      > .info {
        align-self: stretch;
        margin: {
          left: 0.03rem * 2;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;

        > .nickname {
          font-size: .23rem * 2;
          font-family: PingFang SC;
          font-weight: 800;
          color: #FFFFFF;
          line-height: 1;
          transform: scale(0.8, 0.8);
        }

        > .heat {
          font-size: .14rem * 2;
          font-family: PingFang SC;
          font-weight: 800;
          color: #FEFEFE;
          line-height: 1;
          transform: scale(0.8, 0.8)
        }
      }
    }

    > .member-avatar-container {
      $item-width: .54rem * 2;
      margin: {
        left: auto;
        right: (.24rem + .56rem ) * 2;
      }
      position: relative;
      height: .54rem * 2;
      width: $item-width * 4.2;
      overflow-x: hidden;

      > .item {
        $width: $item-width;
        position: absolute;
        width: $width;
        height: .54rem * 2;
        left: calc(var(--index) * (#{$width} - 0.1rem));
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 50%;

        // &:last-child {
        //   &::after {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     right: 0.4rem;
        //     top: 0;
        //     bottom: 0;
        //     width: 0.25rem;
        //     background: inherit;
        //     filter: blur(0.08rem);
        //   }
        // }
      }
    }
  }

  > .gift-list-container {
    > .item {
      height: .78rem * 2;

      &:not(:first-child) {
        margin: {
          top: .06rem * 2;
        }
      }

      margin: {
        left: .24rem * 2;
      }
    }
  }

  > .chat-message-list-container {
    margin: {
      top: .38rem * 2;
    }
    height: 4.10rem * 2;
    position: relative;

    > .chat-message-list {
      height: 100%;
      box-sizing: border-box;
      padding: {
        left: .24rem * 2;
        right: 2.54rem * 2;
      }
      overflow-y: scroll;

      > .message-container {
        box-sizing: border-box;
        padding: {
          left: .16rem * 2;
          right: .10rem * 2;
        }

        background-color: rgba(61, 61, 68, 0.4);
        border-radius: .21rem * 2;

        &:not(:first-child) {
          margin: {
            top: .06rem * 2;
          }
        }

        @mixin text() {
          font-size: .24rem * 2;
          font-family: PingFang SC;
          line-height: .30rem * 2;
        }

        > .from {
          display: inline-block;
          @include text;
          font-weight: 500;
          color: #FEC8FF;
        }

        > .content {
          display: inline-block;
          @include text;
          font-weight: bold;
          color: #FFFFFF;

          &:not(:first-child) {
            margin: {
              left: .20rem * 2;
            }
          }
        }

        &.tip {
          > .content {
            @include text;
            font-weight: 500;
            color: #C5F7FF;
          }
        }
      }
    }

    > .current-goods-container {
      position: absolute;
      right: .24rem * 2;
      width: 2.04rem * 2;
      height: 2.78rem * 2;
      bottom: 0;
    }
  }

  > .chat-control-container {
    margin: {
      top: .20rem * 2;
      bottom: .44rem * 2;
    }
    box-sizing: border-box;
    padding: {
      left: .24rem * 2;
      right: .24rem * 2;
    }
    display: flex;
    align-items: center;

    > .input-wrapper {
      //width: 388px;
      //width: 0;
      flex-grow: 1;
      height: .68rem * 2;

      > .input {
        width: 100%;
        height: 100%;
        background-color: rgba(209, 209, 209, 0.3);
        border: .01rem * 2 solid rgba(209, 209, 209, 0.3);
        border-radius: .34rem * 2;

        @mixin text() {

          font-size: .26rem * 2;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          //line-height: 32px;
        }
        @include text;

        padding: {
          left: .28rem * 2;
          right: .28rem * 2;
        }
        box-sizing: border-box;

        &::placeholder {
          @include text;
        }
      }
    }

    > .icon-container {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin: {
        left: .90rem * 2;
      }

      @mixin item {
        width: .68rem * 2;
        height: .68rem * 2;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:first-child) {
          margin: {
            left: .09rem * 2;
          }
        }

        > .icon {
          display: block;
        }
      }


      > .cart-item {
        @include item;

        > .icon {
          width: .40rem * 2;
          height: .38rem * 2;
        }
      }

      > .share-link-item {
        @include item;

        > .icon {
          width: .42rem * 2;
          height: .31rem * 2;
        }
      }

      > .gift-item {
        @include item;

        > .icon {
          width: .40rem * 2;
          height: .37rem * 2;
        }
      }
    }
  }

  > .bottom-shadow {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -10;
    pointer-events: none;
  }


  @mixin full-screen-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  @mixin bottom-panel($height) {
    position: absolute;
    width: 100%;
    height: $height;
    left: 0;
    bottom: 0;
  }

  > .gift-panel-container {
    @include bottom-panel($height: 5.60rem * 2)
  }


  > .goods-list-panel-container {
    @include full-screen-container;

    > .goods-list-panel-wrapper {
      @include bottom-panel($height: 9.88rem * 2)
    }
  }

  > .user-list-panel-container {
    @include full-screen-container;

    > .user-list-panel-wrapper {
      @include bottom-panel($height: 6.04rem * 2)
    }
  }

}