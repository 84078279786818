.recommend-word-list {
  > .floor {
    height: 1.64rem;
    padding-left: 0.38rem;
    padding-right: 0.4rem;

    display: flex;
    align-items: center;

    > .word {
      font-size: 0.5rem;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(36, 36, 36, 1);
      line-height: 0.4rem;
    }
  }
}
