@import 'styles/common/flex.scss';

.news-item {
  height: 3.76rem;

  &__content {
    height: 100%;
    width: calc(100% - 0.82rem);
    padding-left: 0.42rem;
    padding-right: 0.4rem;
    @include flex-horizontal-between;
  }

  &__left {
    width: 9.36rem;
    @include flex-vertical-center;
    @include flex-align-start;
    > .title-floor {
      display: flex;
      > .notice-icon {
        display: block;
        height: 0.48rem;
        margin: {
          right: 0.4rem;
        }
      }

      > .title {
        display: block;
        font-size: 0.52rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(21, 21, 21, 1);
        line-height: 0.64rem;
      }
    }
  }

  &__title {
    font-size: 0.52rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(21, 21, 21, 1);
    line-height: 0.64rem;
  }

  &__info {
    margin-top: 0.44rem;
    font-size: 0.44rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(151, 151, 151, 1);
    line-height: 0.64rem;
  }

  &__view {
    margin-left: 0.98rem;
    i {
      margin-right: 0.18rem;
    }
  }

  &__right {
  }

  &__img {
    width: 4.3rem;
    height: 2.96rem;
    border-radius: 0.3rem;
  }

  &__divider {
    width: 14.2rem;
    height: 0.02rem;
    background-color: #f3f3f3;
  }
}
