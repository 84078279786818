.address-item {
  width: 100vw;
  height: (3.14 - 0.7 * 2) rem;
  background: rgba(255, 255, 255, 1);
  padding: 0.7rem 0.84rem;
  .row:first-child {
    margin-bottom: 0.5rem;
  }

  .row:nth-child(2) {
    // 地址信息行
  }

  &__name {
    font-size: 0.6rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);

    margin-right: 1.6rem;
  }

  &__phone {
    font-size: 0.52rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    margin-right: 0.9rem;
  }

  &__is-default {
    width: 1.34rem;
    height: 0.6rem;
    background: rgba(239, 242, 245, 1);
    border-radius: 0rem;

    font-size: 0.36rem;
    font-weight: bold;
    color: rgba(145, 197, 236, 1);
  }

  &__detail {
    font-size: 0.44rem;
    // font-weight: bold;
    max-width: 11.34rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;

    color: rgba(35, 35, 35, 1);
  }
}
