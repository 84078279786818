.glx-order-refund-detail {
  min-height: 100vh;
  background: rgba(249, 249, 249, 1);

  > .card {
    margin: {
      top: .48rem;
    }
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 .3rem .02rem rgba(104, 21, 29, 0.04);
    border-radius: .2rem;

    > .floor {
      padding: {
        top: .63rem;
        bottom: .6rem;
        left: .46rem;
        right: .46rem;
      }

      &:not(:first-child) {
        border-top: 1px solid rgba(245, 245, 245, 1);
      }


      > .line {

        &:not(:first-child) {
          margin: {
            top: .44rem;
          }
        }

        > .value {
          &.status {
            color: rgba(247, 41, 27, 1);
          }
        }
      }
    }
  }
}