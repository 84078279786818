.glx-merchant-add-license {
  @mixin radio {
    &.am-radio-wrapper {
      opacity: 1;
      > .am-radio {
        padding: 2.5px;
        border: 1px solid #ccc;
        border-radius: 50%;
        margin-right: 5px;

        > .am-radio-inner {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }

  > .table-header {
    display: flex;
    margin: {
      top: 0.66rem;
      left: 0.4rem;
      right: 0.4rem;
    }

    height: 1.08rem;
    line-height: 1.08rem;
    background: linear-gradient(
      90deg,
      rgba(44, 99, 229, 1),
      rgba(39, 181, 234, 1)
    );
    border-radius: 0.12rem 0.12rem 0 0;

    > .item {
      //   width: calc(100% / 3);
      text-align: center;

      font-size: 0.4rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);

      &.category {
        width: 25%;
      }
      &.picture {
        width: 25%;
      }
      &.date {
        width: 50%;
      }
      &:not(:last-child) {
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 0.4rem;
          bottom: 0.4rem;

          width: 0.02rem;
          background: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  > .table-body {
    margin: {
      left: 0.4rem;
      right: 0.4rem;
    }
    > .floor {
      display: flex;
      height: 1.44rem;
      line-height: 1.44rem;
      position: relative;

      &:not(:first-child) {
        &::before {
          content: '';
          display: block;
          position: absolute;

          top: 0;
          left: 0.14rem;
          right: 0.1rem;
          height: 0.02rem;
          border-top: 0.02rem solid rgba(238, 238, 238, 1);
        }
      }

      > .item {
        text-align: center;
        width: calc(100% / 3);

        font-size: 0.48rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(24, 23, 23, 1);

        &:nth-child(2n + 1) {
          background: rgba(245, 248, 251, 1);
        }
        &:nth-child(2n) {
          background: rgba(255, 255, 255, 1);
        }

        > .upload-img {
          width: 100%;
          height: 100%;
        }

        &.category {
          width: 25%;
        }

        &.picture {
          width: 25%;
          font-size: 0.4rem;
          font-family: PingFang SC;
          font-weight: bold;
          text-decoration: underline;
          color: rgba(43, 123, 230, 1);
        }

        &.date {
          width: 50%;

          display: flex;
          align-items: center;

          .date-text {
            flex-grow: 1;
            width: 4rem;
          }

          > .end-date-infinite {
            flex-shrink: 0;
            margin: {
              left: 0.4rem;
              right: 2.4rem;
            }

            @include radio();
          }
        }
      }
    }
  }
}
