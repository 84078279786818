.glx-loading {
  margin: {
    top: 0.6rem;
  }
  display: flex;
  justify-content: center;

  > .img-loading {
    width: 1rem !important;
    display: block;
  }
}
