.news {
  background-color: #f6f6f6;
  height: 100vh;

  .news-swiper {
    padding: 0.4rem 0;
  }

  &__body {
    padding-top: 3.28rem;
    background-color: white;
    border-radius: 0.3rem;
    height: calc(100% - 3.28rem);
  }
}
