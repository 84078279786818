.glx-login {
  width: 100vw;
  height: 100vh;
  position: relative;

  padding: {
    top: 7.4rem;
  }

  > .bg {
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > .login-btn {
    top: 0.72rem;
    right: 0.84rem;

    display: block;
    position: absolute;

    width: 6.22rem;
    height: 1.46rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.74rem;

    line-height: 1.46rem;
    font-size: 0.52rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 127, 165, 1);
  }

  > .title-text {
    position: absolute;
    top: 2.16rem;
    left: 0.84rem;
    font-size: 0.72rem;
    font-family: FZDaHei-B02S;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 0.74rem;
    text-shadow: 0 0.12rem 0.12rem rgba(253, 120, 138, 0.76);
  }

  > .welcome-text {
    position: absolute;
    top: 3.32rem;
    left: 2.14rem;
    font-size: 1.62rem;
    font-family: FZDaHei-B02S;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 0.94rem;
    text-shadow: 0 0.12rem 0.12rem rgba(253, 120, 138, 0.76);
  }

  > .login-text {
    position: absolute;
    top: 4.4rem;
    right: 0.7rem;
    font-size: 2.84rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.6);
    line-height: 3.12rem;
  }

  > .card {
    height: 15.92rem;
    margin: {
      left: 0.84rem;
      right: 0.84rem;
    }

    box-sizing: border-box;
    padding: {
      top: 2.54rem;
      bottom: 0.92rem;
    }
    display: flex;
    flex-direction: column;

    background: #FFFFFF;
    box-shadow: 1px 27px 64px 10px rgba(255, 182, 190, 0.95);
    border-radius: 14px;


    > .floor {
      display: flex;
      height: 1.64rem;

      &:not(:first-child) {
        margin: {
          top: 1.12rem;
        }
      }

      &.username {
        margin: {
          left: 0.66rem;
          right: 1.34rem;
        }
      }

      &.verify {
        margin: {
          left: 1.1rem;
          right: 1.08rem;
        }
      }

      &.password {
        margin: {
          left: 1.1rem;
          right: 1.08rem;
        }
      }

      > .input-wrapper {
        flex-grow: 1;
        height: 100%;

        position: relative;
        z-index: 10;
        > .bg-img {
          position: absolute;
          z-index: -10;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        > .label {
          position: absolute;

          left: 1.3rem;
          top: 0;
          transform: translateY(-50%);

          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(149, 149, 149, 1);

          &.active {
            color: rgba(244, 48, 126, 1);
          }
        }

        > .icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 29px;
          margin: auto;
          &.username {
            width: 0.58rem;
            height: 0.7rem;
          }

          &.verify {
            width: 0.3rem;
            height: 0.66rem;
          }

          &.password {
            width: 0.56rem;
            height: 0.7rem;
          }
        }

        > .input {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: {
            left: 2.1rem;
            right: 0.6rem;
          }

          font-size: 0.52rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(43, 43, 43, 1);
        }
      }

      > .verify-img-block {
        margin: {
          left: 0.62rem;
        }
        flex-shrink: 0;

        width: 3.76rem;
        border-radius: 0.2rem;
        height: 100%;

        > .verify-img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0.2rem;
        }
      }

    //   &.forget-password {
    //     margin: {
    //       top: 0.54rem;
    //       right: 1.22rem;
    //     }
    //     > .forget-text {
    //       margin: {
    //         left: auto;
    //       }
    //       display: block;
    //       font-size: 0.4rem;
    //       font-family: PingFang SC;
    //       font-weight: 500;
    //       color: rgba(153, 138, 247, 1);
    //     }
    //   }

      &.btn-floor {
        margin: {
          top: auto;
        }
        > .enter-btn {
          position: relative;
          width: 1.84rem;
          height: 1.84rem;
          border-radius: 50%;
          margin: {
            left: auto;
            right: auto;
          }
          > .bg-img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
}
