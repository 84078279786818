@import 'styles/common/flex.scss';

.stop-pay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.stop-pay-content {
  $padding: 0.84rem;
  width: calc(12.92rem - #{$padding} * 2);
  height: calc(6.16rem - #{$padding} * 2);
  padding: $padding;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.3rem;
  margin: auto;
  position: fixed;
  top: 10.46rem;
  left: 0;
  right: 0;
  z-index: 100;
  & > div:nth-child(1) {
    font-size: 0.6rem;
    font-weight: 800;
    color: rgba(24, 24, 24, 1);
    line-height: 0.6rem;
    @include flex-horizontal-center;
  }
  & > div:nth-child(2) {
    width: 11.02rem;
    font-size: 0.52rem;
    font-weight: bold;
    color: rgba(24, 24, 24, 1);
    line-height: 0.72rem;
    @include flex-horizontal-start;

    margin-top: 0.68rem;
  }
  & > div:nth-child(3) {
    @include flex-horizontal-center;

    margin-top: 0.82rem;
    & > div {
      width: 3.8rem;
      height: 1.16rem;
      border-radius: 1rem;
      font-size: 0.48rem;
      @include flex-horizontal-center;
    }
    & > div:nth-child(1) {
      border: 1px solid rgba(249, 65, 22, 1);
      color: #f94116;
    }
    & > div:nth-child(2) {
      background: linear-gradient(
        90deg,
        rgba(249, 65, 22, 1),
        rgba(254, 55, 92, 1)
      );
      color: #ffffff;

      margin-left: 0.68rem;
    }
  }
}
