@import "styles/common/flex.scss";

.video-item {
  padding-top: 0.52rem;

  @include flex-vertical-center;

  &__player {
    width: 14.2rem;
    height: 7.2rem;
    background: rgba(0, 0, 0, 1);
    border-radius: 0.3rem;

    overflow: hidden;

    position: relative;

    .video-react .video-react-big-play-button {
      display: none;
    }
  }

  &__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    &--active {
      display: none;
    }

    > .title-block {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: {
        top: 0.4rem;
        left: 1rem;
        right: 1rem;
      }
      font-size: 0.48rem;
      font-family: PingFangSC;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      line-height: 1;
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.45);
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    width: 1.4rem;
    height: 1.4rem;
  }

  &__info {
    width: calc(100% - 1.68rem);
    height: 1.14rem;
    font-size: 0.4rem;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);

    i {
      margin-right: 0.16rem;
    }

    span {
      margin-right: 0.62rem;
    }

    @include flex-horizontal-start;
  }

  &__type {
  }
}
