.glx-add-address {
  min-height: 100vh;
  background-color: rgba(249, 249, 249, 1);

  > .form {
    margin: {
      top: .48rem;
    }
  }

  > .btn-floor {
    margin: {
      top: 2.36rem;
    }
    text-align: center;

    > .submit {
      width: 7rem;
      height: 1.72rem;
      background: linear-gradient(90deg, rgba(245, 4, 4, 1), rgba(248, 94, 53, 1));
      box-shadow: 0 .28rem .84rem .14rem rgba(235, 9, 8, 0.18);
      border-radius: .86rem;

      font-size: .56rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.is-default-btn {
  width: 2.22rem * 1.3;
  height: .84rem * 1.3;
  background: linear-gradient(45deg, rgba(103, 137, 224, 1), rgba(127, 207, 237, 1));
  box-shadow: 0 .16rem .44rem .04rem rgba(104, 140, 225, 0.16);
  border-radius: .42rem * 1.3;

  font-size: .24rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}