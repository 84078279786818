.index {
	margin-top: 25px;

	> .container {
		width: 686px;
		height: 262px;
		border-radius: 20px;

		margin-left: auto;
		margin-right: auto;

		> .swiper {
			overflow-x: hidden;
			width: 100%;
			height: 100%;

			.swiperWrapper {

				.swiperSlide {
					.img {
						width: 100%;
						height: 100%;
						border-radius: 12px;
					}
				}
			}
		}
	}
}