.glx-flash-sale {
  background-color: rgba(246, 246, 246, 1);
  height: 100vh;
  > .top {
    height: 6.92rem;

    background-image: url(../images/flash-sale/top-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    padding: {
      top: 0.9rem;
    }

    > .head {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      > .back {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: {
          left: 0.4rem;
          right: 0.4rem;
          //   top: 0.4rem;
          //   bottom: 0.4rem;
        }
        > .img {
          width: 0.3rem;
          height: 0.5rem;
          display: block;
        }
      }
      > .title-img {
        display: block;
        width: 3.24rem;
        height: 0.76rem;
      }
    }

    > .banner {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.14rem;
      margin: auto;
      width: 14.2rem;
      height: 4.52rem;
      border-radius: 0.28rem;
      .img {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 0.28rem;
      }
    }
  }

  > .time-floor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: {
      top: 0.48rem;
    }
    height: 2.16rem;
    background: rgba(240, 240, 240, 1);

    position: relative;
    z-index: 10;
    padding: {
      left: 1.2rem;
      right: 0.4rem;
    }
    > .current-img {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 4.04rem;
      height: 2.16rem;

      > .bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: scale(1.2, 1.5);

        background-image: url('../images/flash-sale/current.png');
        background-size: 100% 100%;
      }
    }
    > .item {
      //   &:first-child {
      //     margin: {
      //       left: 1.2rem;
      //     }
      //   }
      &:nth-child(2) {
        margin: {
          left: 0.4rem;
        }
      }
      //   &:nth-child(n + 3) {
      //     margin: {
      //       left: 1.1rem;
      //     }
      //   }

      > .time {
        font-size: 0.64rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(49, 49, 49, 1);
        line-height: 1;
      }

      > .text {
        margin: {
          top: 0.24rem;
        }
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(49, 49, 49, 1);
        line-height: 0.64rem;
      }

      &.current {
        > .time {
          color: rgba(255, 255, 255, 1);
        }
        > .text {
          padding: 0.12rem 0.3rem;
          height: 0.6rem;
          background: rgba(255, 255, 255, 1);
          border-radius: 0.3rem;

          color: rgba(228, 38, 38, 1);
        }
      }
    }
  }

  > .left-time-floor {
    margin: {
      top: 0.24rem;
    }
    display: flex;
    align-items: center;
    height: 0.88rem;
    background: rgba(240, 240, 240, 1);
    z-index: 10;
    position: relative;
    padding: {
      left: 1.2rem;
    }
    > .bg {
      position: absolute;
      z-index: -10;
      left: 0;
      height: 100%;
      width: 9.6rem;
      background: linear-gradient(
        90deg,
        rgba(218, 18, 70, 1),
        rgba(240, 69, 36, 1)
      );
      border-radius: 0 0.44rem 0.44rem 0;
    }

    > .lock-img {
      display: block;
      width: 0.52rem;
      height: 0.52rem;
    }
    > .title {
      margin: {
        left: 0.24rem;
      }
      font-size: 0.44rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 0.64rem;
    }
    > .time {
      margin: {
        left: 1.2rem;
      }
      font-size: 0.48rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 0.64rem;
    }
  }

  .goods-list-container {
    padding: 0.4rem;
    > .item {
      display: flex;
      align-items: center;
      padding: {
        left: 0.6rem;
        right: 0.46rem;
        top: 0.34rem;
        bottom: 0.34rem;
      }
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0.1rem 0.14rem 0 rgba(0, 0, 0, 0.04);
      border-radius: 0.28rem;
      &:not(:first-child) {
        margin: {
          top: 0.4rem;
        }
      }
      > .img-block {
        flex-shrink: 0;
        // width: 2.64rem;
        // height: 3.14rem;
        // border-radius: 0.28rem 0 0 0.28rem;
        display: flex;
        justify-content: center;
        overflow: hidden;

        width: 3.84rem;
        height: 3.84rem;
        border-radius: 0.28rem 0 0 0.28rem;
        > .img {
          display: block;
          height: 100%;
        }
      }
      > .info-block {
        flex-grow: 1;
        padding: {
          top: 0.28rem;
          bottom: 0.28rem;
        }
        margin: {
          left: 1rem;
        }
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .name-floor {
          font-size: 0.52rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(22, 22, 22, 1);
          line-height: 1;
          //   单行省略
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > .price-floor {
          margin: {
            top: 1rem;
          }
          display: flex;
          align-items: flex-end;

          font-family: PingFang SC;
          color: rgba(231, 22, 49, 1);
          line-height: 1;
          > .label {
            font-size: 0.4rem;
            font-weight: 500;
          }
          > .value {
            font-size: 0.44rem;
            font-weight: 400;
          }
        }
        > .handle-floor {
          display: flex;
          align-items: flex-end;
          > .current-price {
            font-size: 0.44rem;
            font-family: PingFang SC;
            font-weight: 400;
            text-decoration: line-through;
            color: rgba(134, 134, 134, 1);
            line-height: 0.64rem;
          }
          > .to-info-btn {
            margin: {
              left: auto;
            }

            padding: 0.16rem 0.32rem;
            background: linear-gradient(
              45deg,
              rgba(218, 18, 70, 1),
              rgba(240, 69, 36, 1)
            );
            box-shadow: 0 0.06rem 0.64rem 0.06rem rgba(226, 37, 57, 0.1);
            border-radius: 18px;

            font-size: 0.4rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            line-height: 0.72rem;
          }
        }
      }
    }
  }
}

.glx-flash-sale-goods {
  height: calc(100% - 6.92rem - 0.9rem - 2.16rem - 0.48rem - 0.24rem - 0.88rem);
}
