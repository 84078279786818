@import 'styles/common/flex.scss';

.orders-item {
  width: 15rem;
  background: rgba(255, 255, 255, 1);
  // box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.02);
  @include flex-vertical-start;
}

.orders-item-headline {
  width: calc(100% - 0.5rem * 2);
  box-sizing: border-box;
  padding: 0.5rem;
  height: 1.98rem;
  @include flex-horizontal-between;

  &__left {
    @include flex-horizontal-start;
  }

  &__shop-icon {
    width: 0.52rem;
    height: 0.5rem;
    margin-right: 0.32rem;
  }

  &__shop-name {
    font-size: 0.56rem;
    font-weight: bold;
    color: rgba(40, 40, 40, 1);
  }

  &__status {
    font-size: 0.48rem;

    font-weight: bold;
    color: rgba(234, 83, 42, 1);

    &--finished {
      color: #909090;
    }
  }
}

.order-product-list {
  padding: {
    left: .48rem;
    right: .48rem;
  }
  box-sizing: border-box;

  .single-wrapper {
    display: flex;
    align-items: center;

    .img {
      width: 3rem;
      height: 3rem;
      border-radius: 0rem;
      margin-right: 0.46rem;
    }

    .name {
      width: 7.92rem;
      font-size: 0.48rem;
      font-weight: bold;
      color: rgba(27, 27, 27, 1);
      line-height: 0.64rem;
    }

    .price {
      margin-left: 0.5rem;
      font-size: 0.38rem;

      strong {
        font-size: 0.6rem;
      }
    }
  }

  .multiple-wrapper {
    display: flex;
    border-radius: .2rem 0 0 .2rem;
    position: relative;
    overflow: hidden;

    .sku-product {
      flex-basis: calc(100% / 4);

      &:not(:first-child) {
        margin: {
          left: .16rem;
        }
      }

      > .main-img {
        width: 100%;
      }
    }

    .extra {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      right: 0;
      height: 100%;
      background-color: #fff;

      .price {
        font-size: .36rem;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: rgba(36, 36, 36, 1);
      }

      .count {
        font-size: .36rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(134, 134, 134, 1);
      }
    }
  }
}

.orders-item-operate {
  height: 2.72rem;
  width: 100%;
  @include flex-horizontal-end;

  & > a {
    @include flex-horizontal-center;
    width: 3.2rem;
    height: 1.2rem;
    border-radius: 1rem;
    font-size: 0.5rem;
    font-weight: bold;
    margin-right: 0.48rem;
  }

  &__buy,
  &__pay,
  &__confirm {
    color: rgba(246, 70, 22, 1);
    background-color: rgba(250, 246, 245, 1);
  }

  &__refund-progress,
  &__refund-detail,
  &__modify,
  &__deliver {
    color: rgba(31, 31, 31, 1);

    border: 1px solid rgba(212, 212, 212, 1);
  }
}

.orders-item-deliver {
  margin: {
    left: auto;
    right: auto;
  }
  height: 3.28rem;

  position: relative;
  width: calc(100% - 2 * .26rem);
  display: flex;
  box-sizing: border-box;
  padding: {
    top: .7rem;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
  }
  z-index: 1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;

    background-image: url('./image/deliver-info-bg.png');
    background-size: 100% 100%;
    background-position: 0 0;
  }

  &__icon {
    width: 0.6rem;
    height: 0.4rem;
  }

  &-info {
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    margin: {
      left: .3rem;
    }

    &__desc {
      font-size: 0.48rem;

      width: 9.34rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1;
    }

    &__time {
      font-size: 0.36rem;
      line-height: 1;
      margin: {
        top: .4rem;
      }
    }
  }

  &__enter {
    width: 0.12rem;
    height: 0.28rem;
    display: block;
    margin: {
      left: auto;
    }
    align-self: center;
  }
}
