.glx-merchant-add-category-panel {
  background: rgba(255, 255, 255, 1);
  border-radius: 0.2rem;

  box-sizing: border-box;
  padding: {
    top: 1.74rem;
    left: 0.94rem;
    right: 0.88rem;
    bottom: 1.3rem;
  }

  @mixin title {
    font-size: 0.48rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(24, 23, 23, 1);
    line-height: 1;
  }

  @mixin radio {
    &.am-radio-wrapper {
      opacity: 1;
      > .am-radio {
        padding: 2.5px;
        border: 1px solid #ccc;
        border-radius: 50%;
        margin-right: 5px;

        > .am-radio-inner {
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }

  display: flex;
  flex-direction: column;

  > .category-floor-1 {
    flex-shrink: 0;
    > .title-floor {
      > .title {
        @include title();
      }
    }
  }

  > .category-floor-2 {
    flex-grow: 1;
    overflow-y: scroll;
    > .title-floor {
      display: flex;
      align-items: center;
      > .title {
        @include title();
      }

      > .radio {
        @include radio();
        margin: {
          left: 0.4rem;
        }
      }
    }
    > .list-floor {
      margin: {
        top: 1.48rem;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > .item {
        //width: calc(100% / 3);
        justify-content: space-between;
        @include radio();

        margin: {
          bottom: 0.8rem;
        }
      }
    }
  }

  > .button-container {
    flex-shrink: 0;
    margin: {
      top: 3rem;
    }

    > .button-floor {
      display: flex;
      justify-content: center;

      &:not(:first-child) {
        margin: {
          top: 0.4rem;
        }
      }

      @mixin button {
        width: 6.4rem;
        height: 1.6rem;

        font-size: 0.54rem;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 0.72rem;
      }

      > .confirm-btn {
        @include button();

        background: linear-gradient(
          90deg,
          rgba(13, 117, 223, 1),
          rgba(5, 224, 216, 1)
        );
        box-shadow: 0 0.18rem 0.3rem 0.02rem rgba(9, 141, 192, 0.2);
        border-radius: 0.8rem;
        text-shadow: 0 0.18rem 0.38rem rgba(18, 76, 155, 0.22);

        color: rgba(255, 255, 255, 1);
      }

      > .cancel-btn {
        color: rgba(165, 165, 165, 1);
      }
    }
  }
}
