.account-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  height: 5.94rem;

  padding-top: 1.88rem;
  z-index: 1;

  > .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    &::before {
      content: '';
      display: block;
      width: 100%;
      transform: scaleX(1.5);
      height: 200%;
      border-radius: 50%;

      background: linear-gradient(
        0deg,
        rgba(245, 72, 87, 1),
        rgba(255, 132, 142, 1)
      );
      box-shadow: 0rem 0.02rem 0.16rem 0rem rgba(21, 21, 21, 0.08);
    }
  }

  > .back {
    position: absolute;
    top: 0;
    left: 0;

    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 0.4rem;
    padding-right: 0.8rem;
    > .img {
      width: 0.28rem;
      height: 0.5rem;
    }
  }

  > .info-floor {
    display: flex;
    align-items: center;

    > .avatar-wrapper {
      margin-left: 1.12rem;
      width: 2.4rem;
      height: 2.4rem;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;

      padding: 0.14rem;

      > .avatar {
        border-radius: 50%;
        display: block;
        height: 100%;
        width: 100%;
      }
    }

    > .info {
      margin-left: 0.4rem;

      > .nickname {
        font-size: 0.56rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 1;
        text-shadow: 0rem 0.02rem 0rem rgba(21, 21, 21, 0.45);
      }

      > .username {
        margin-top: 0.2rem;
        font-size: 0.44rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        line-height: 1;
      }
    }
  }
}
